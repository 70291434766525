import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ApiDetailsType,
  CreateSubscriptionType,
  CreatePaymentMethodType,
} from "../../types/subscriptions";
import api from "../../api";

export const GetApiDetails = createAsyncThunk(
  "subscriptions/get-api-details",
  async () => {
    try {
      const {
        data,
      }: { data: ApiDetailsType } = await api.subscriptions.getApiDetails();
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const CancelSubscription = createAsyncThunk(
  "subscriptions/canael-subscription",
  async () => {
    try {
      const {
        data,
      }: {
        data: boolean;
      } = await api.subscriptions.cancelSubscription();
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const CreateSubscription = createAsyncThunk(
  "subscriptions/create-subscriptions",
  async (value: CreateSubscriptionType, thunkAPI) => {
    try {
      const {
        data,
      }: { data: boolean } = await api.subscriptions.createSubscription(value);
      return { data };
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const CreatePaymentMethod = createAsyncThunk(
  "subscriptions/create-payment-method",
  async (value: CreatePaymentMethodType, thunkAPI) => {
    try {
      const {
        data,
      }: { data: boolean } = await api.subscriptions.createPaymentMethod(value);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getTokenForWordpress = createAsyncThunk(
  "subscriptions/get-token-for-wordpress",
  async () => {
    try {
      const {
        data,
      }: { data: any } = await api.subscriptions.getTokenForWordpress();
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);
