import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { UpdateProfileType } from "../../types/user";

export const getProfile = createAsyncThunk("profile/getProfile", async () => {
  try {
    const { data } = await api.user.getProfile();
    return data;
  } catch (err) {
    if(
      err?.message.includes('Token has expired')
      || err?.message.includes('Could not decode token')
      || err?.message.includes('User not found')
      ){
      localStorage.removeItem("token");
      localStorage.removeItem("id");
    }
  }
});

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (values: UpdateProfileType | {}, thunkAPI) => {
    try {
      const { data } = await api.user.updateProfile(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const signOut = createAsyncThunk("profile/logout", async () => {
  try {
    const { data } = await api.user.signOut();
    return data;
  } catch (err) {
    return err.response.data;
  }
});

export const sendLeadDyno = createAsyncThunk(
  "user/sendLeadDyno",
  async (email: string, thunkAPI) => {
    try {
      console.log("Action - sendLeadDyno - UserCreated: " + email);
      window.LeadDyno.recordLead(email);
      return true;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);