//libraries
import React, { useMemo, FormEvent, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styles from "./styles.module.scss";

import { StateType } from "../../../../redux/store";
import businessIcon from "../../../../assets/images/businessMembershipsPage/business.jpg";
import businessIcon2 from "../../../../assets/images/businessMembershipsPage/business2.jpg";
import Button from "../../../common/Button/Button";
import {Helmet} from "react-helmet";

type PropsType = {};

const BusinessMembershipPage: React.FC<PropsType> = () => {
  const { profile } = useSelector((state: StateType) => state.user);
  const history = useHistory();

  const doDiamondOnClick = () => {
    window.scrollTo(0, 0);
    history.push(profile ? "/user/subscription" : "auth/sign-up");
  };
  return (
    <div className={styles.subscriptions}>
      <Helmet>
        <title>For Businesses - Anything Academic</title>
      </Helmet>
      <div className={styles.purpleTopBlock}>
        <h1>Shine Bright with a Diamond Membership!</h1>
        <h2>Perfect for members looking to stand out.</h2>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.contentBlock}>
          <div>
            <img src={businessIcon} alt="image will be here" />
          </div>
          <div>
            <h2 className={styles.contentTitle}>Diamond Banner Listing</h2>
            <p>
              As a Diamond member you’ll get customizations that make
              you stand out from the crowd:
            </p>
            <ul className={styles.noMarginUl}>
              <li>
                Custom listing banner image
              </li>
              <li>
                  Ability to list as a company name, class name, or brand name.{" "}
                Premium members cannot use their company name in their profile.
              </li>
            </ul>
            <p>
              Diamond Membership is perfect for:
            </p>
            <ul className={styles.noMarginUl}>
              <li>
                A teacher or tutor wanting to draw extra attention to
                connect with students faster.
              </li>
              <li>
                An instructor looking to get their class listing noticed.
              </li>
              <li>
                A tutoring company (with less than 20 tutors) trying to
                stand out. If you have more than 20 tutors contact us for more
                information and pricing.
              </li>
            </ul>
            <p>
              We’re just getting started! Keep scrolling for more Diamond perks.
            </p>
            <div className={styles.buttonWrapper}>
              <Button customColor="yellow" onClick={doDiamondOnClick}>
                {profile ? " Go Diamond" : "Join Us"}
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.contentBlock}>
          <div>
            <h2 className={styles.contentTitle}>
              Customize your listing details to promote your passion
            </h2>
            <p>
              Normal member profile descriptions do not allow web links or
              promotional offers. Those perks are reserved for our Diamond
              members.
            </p>
            <p>
              As a Diamond member you can:
            </p>
            <ul>
              <li>
                Add a link to your website, instagram, facebook, or
                other social media platform.
              </li>
              <li>
                Offer promotions and discounts that could be included in
                our newsletter and marketing campaigns.
              </li>
            </ul>
            <p>
              Your profile detail page will feature a prominent gold button that
              links to your site. Clicking your banner image will also open the
              link.
            </p>
            <p>
              Update your description as often as you like, it’s up to you!
            </p>
            <div className={styles.buttonWrapper}>
              <Button customColor="yellow" onClick={doDiamondOnClick}>
                {profile ? " Go Diamond" : "Join Us"}
              </Button>
            </div>
          </div>
          <div>
            <img
              src={businessIcon2}
              alt="image will be here"
              className={styles.businessIcon}
            />
          </div>
        </div>

        {/* <div className={styles.plantTutorial}>
          <a
            href={getUrlWordpress(profile?.plan) + "/become-a-partner/"}
            target="_blank"
          >
            Membership plans are for businesses with no more than 20 tutors
            only. For larger companies please see Anything Partners.
          </a>
        </div> */}
      </div>
      <div className={styles.bottomPurpleBlock}>
        <div>
          <div>
            <h2>Ready to experience the perks of Diamond Membership?</h2>
          </div>
          <div>
            <h2>Let’s Get Started!</h2>
          </div>
          <Button customColor="yellow" onClick={doDiamondOnClick}>
            {profile ? " Go Diamond" : "Join Us"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BusinessMembershipPage;
