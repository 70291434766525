import { AxiosResponse } from "axios";
import { instance, SERVER_HOST, headers } from "./index";
import { SignUpType } from "../types/auth";
import { TeacherType } from "../types/teachers";

export default {
  getTeachers: async () => {
    const {
      data,
    }: AxiosResponse<{ data: Array<TeacherType> }> = await instance.get(
      `${SERVER_HOST}/teachers`,
      headers()
    );
    return data;
  },

  getTeacherById: async (id: number) => {
    const { data }: AxiosResponse<{ data: TeacherType }> = await instance.get(
      `${SERVER_HOST}/teachers/${id}`,
      headers()
    );
    return data;
  },

  createTeacher: async (value: SignUpType) => {
    const { data }: AxiosResponse<{ data: TeacherType }> = await instance.post(
      `${SERVER_HOST}/teachers`,
      value,
      headers()
    );
    return data;
  },

  updateTeacher: async ({ id, value }: { id: number; value: TeacherType }) => {
    const { data }: AxiosResponse<{ data: TeacherType }> = await instance.put(
      `${SERVER_HOST}/teachers/${id}`,
      value,
      headers()
    );
    return data;
  },

  deleteTeacher: async (id: number) => {
    const { data }: AxiosResponse<{ data: string }> = await instance.delete(
      `${SERVER_HOST}/teachers/${id}`,
      headers()
    );
    return data;
  },
};
