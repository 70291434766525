import React, { MouseEvent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button, Fade, Paper, ClickAwayListener } from "@material-ui/core";
import Popper, { PopperPlacementType } from "@material-ui/core/Popper";
import clsx from "clsx";
import BottomButton from "../../common/Button/Button";

import styles from "./styles.module.scss";

type PropsType = {
  button?: React.ReactNode | string;
  type?: string;
  color?: string;
  withHide?: boolean;
  haveBottomButton?: boolean | null;
  actionButtons?: any;
  onSuccess?: () => void;
  onFail?: () => void;
  activeChecked?: boolean;
  isStopPropagation?: boolean;
  closeByOnClick?: boolean;
  fromSidebar?: boolean;
  fromHome?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 50,
    },
    typography: {
      padding: theme.spacing(2),
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      zIndex: 3,
    },
    paperRounded: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 27,
      zIndex: 3,
    },
  })
);

const PositionedPopper: React.FC<PropsType> = ({
  button,
  children,
  type,
  color,
  withHide,
  haveBottomButton,
  actionButtons,
  onSuccess,
  onFail,
  activeChecked,
  isStopPropagation,
  closeByOnClick,
  fromSidebar,
  fromHome,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const classes = useStyles();

  const handleClick = (newPlacement: PopperPlacementType) => (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    isStopPropagation && event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const submitButtons = () => (
    <div className={styles.popupButtons}>
      <div
        className={styles.submitButton}
        onClick={() => {
          onSuccess && onSuccess();
          setOpen(false);
        }}
      >
        <BottomButton customColor="blue">OK</BottomButton>
      </div>

      <div
        className={styles.clearButton}
        onClick={() => {
          onFail && onFail();
          setOpen(false);
        }}
      >
        <p>Clear Selection</p>
      </div>
    </div>
  );

  const rootOnClickHandler = () => {
    if (open && closeByOnClick) {
      setOpen(false);
    }
  };
  return (
    <>
      {withHide ? (
        <div
          className={clsx(classes.root, {
            [styles.fromHome]: fromHome,
          })}
        >
          <Popper
            className={styles.popupContainer}
            open={open}
            anchorEl={anchorEl}
            placement={placement}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper
                  className={
                    type === "rounded" ? classes.paperRounded : classes.paper
                  }
                >
                  {children}
                  {haveBottomButton && submitButtons()}
                  {actionButtons && actionButtons(setOpen)}
                </Paper>
              </Fade>
            )}
          </Popper>

          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div
              className={clsx({
                [styles.activeChecked]: activeChecked,
                [styles.noPadding]: fromSidebar,
              })}
            >
              <Button onClick={handleClick("bottom-end")}>{button}</Button>
            </div>
          </ClickAwayListener>
        </div>
      ) : (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div
            className={clsx(classes.root, {
              [styles.fromHome]: fromHome,
            })}
            onClick={rootOnClickHandler}
          >
            <Popper
              className={styles.popupContainer}
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper
                    className={
                      type === "rounded" ? classes.paperRounded : classes.paper
                    }
                  >
                    {children}
                    {haveBottomButton && submitButtons()}
                    {actionButtons && actionButtons(setOpen)}
                  </Paper>
                </Fade>
              )}
            </Popper>

            <div className={activeChecked ? styles.activeChecked : ""}>
              <Button onClick={handleClick("bottom-end")}>{button}</Button>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};

export default PositionedPopper;
