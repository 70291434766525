import React from "react";

import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";

import styles from "./styles.module.scss";

const MainLayout: React.FC = ({ children }) => (
  <div className={styles.mainLayout}>
    <Header />
    <Content>{children}</Content>
    <Footer />
  </div>
);

export default MainLayout;
