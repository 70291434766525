import { createSlice } from "@reduxjs/toolkit";

import { signOut, getProfile, updateProfile } from "../actions/user";
import { InitialUserType } from "../../types/user";
import { Cookies } from "react-cookie-consent";

const initialState: InitialUserType = {
  profile: null,
  teacherProfile: null,
  isLoading: false,
  errors: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setStateValue(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
    setUserAvatar: (state, { payload }) => {
      if (state.profile) {
        return (state.profile.image = payload);
      }
      return state;
    },
  },
  extraReducers: {
    [getProfile.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getProfile.fulfilled.toString()]: (state, { payload }) => {
      state.profile = payload;
      state.isLoading = false;
    },
    [getProfile.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [updateProfile.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [updateProfile.fulfilled.toString()]: (state, { payload }) => {
      state.profile = payload;
      state.isLoading = false;
      state.errors = null;
    },
    [updateProfile.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [signOut.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [signOut.fulfilled.toString()]: (state, { payload }) => {
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      localStorage.removeItem("filtersList");
      localStorage.removeItem("redirect_url");
      document.cookie =
        "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      state.profile = null;
      state.isLoading = false;
      Cookies.set("logoutAction", true);
    },
    [signOut.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
  },
});

export const { setStateValue } = userSlice.actions;

export default userSlice.reducer;
