import { AxiosResponse } from "axios";
import { instance, SERVER_HOST, headers } from "./index";
import { CurriculumType, CreateCurriculumType } from "../types/curriculums";

export default {
  getCurriculums: async () => {
    const {
      data,
    }: AxiosResponse<{ data: Array<CurriculumType> }> = await instance.get(
      `${SERVER_HOST}/curriculums`,
      headers()
    );
    return data;
  },

  getCurriculumById: async (id: number) => {
    const {
      data,
    }: AxiosResponse<{ data: CurriculumType }> = await instance.get(
      `${SERVER_HOST}/learning_resources/${id}`,
      headers()
    );
    return data;
  },

  createCurriculum: async (value: CreateCurriculumType) => {
    const {
      data,
    }: AxiosResponse<{ data: CurriculumType }> = await instance.post(
      `${SERVER_HOST}/curriculums`,
      value,
      headers()
    );
    return data;
  },

  updateCurriculum: async ({
    id,
    value,
  }: {
    id: number;
    value: CurriculumType;
  }) => {
    const {
      data,
    }: AxiosResponse<{ data: CurriculumType }> = await instance.put(
      `${SERVER_HOST}/curriculums/${id}`,
      value,
      headers()
    );
    return data;
  },

  deleteCurriculum: async (id: number) => {
    const { data }: AxiosResponse<{ data: string }> = await instance.delete(
      `${SERVER_HOST}/curriculums/${id}`,
      headers()
    );
    return data;
  },
};
