import React, { useEffect } from "react";
import { FormControlLabel, Checkbox as CheckboxMain } from "@material-ui/core";
import {
  WrappedFieldInputProps,
  WrappedFieldMetaProps,
} from "redux-form/lib/Field";
import clsx from "clsx";

import Tooltip from "../../components/Toopltip/Toopltip";
import info from "../../../assets/images/info.png";

import styles from "./styles.module.scss";

type CustomInputTypes = {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  defaultValue?: string | number;
  required?: boolean;
  initialValue?: string | number;
  label: string;
  disabled?: boolean;
};

type PropsType = {
  label?: string;
  name: string;
  handleChange?: () => void;
  tooltip?: string;
  labelIcon?: any;
  inForm?: boolean;
  hidden?: boolean;
};

const Checkbox: React.FC<
  PropsType & CustomInputTypes & WrappedFieldInputProps
> = ({
  name,
  label,
  handleChange,
  input,
  meta,
  initialValue,
  disabled,
  tooltip,
  labelIcon,
  inForm,
  required,
  hidden,
}) => {
  useEffect(() => {
    if (initialValue && input) {
      if (initialValue === "1") {
        input.onChange(true);
      } else if (initialValue === "0") {
        input.onChange(false);
      } else {
        input.onChange(initialValue);
      }
    }
  }, [initialValue]);

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.inForm]: inForm,
      })}
      style={hidden ? { display: "none" } : {}}
    >
      <div className={styles.row_checkbox}>
        <FormControlLabel
          control={
            <CheckboxMain
              name={input?.name}
              checked={input && input.value ? true : false}
              {...input}
              onChange={(e) => {
                input?.onChange(e);
                handleChange && handleChange();
              }}
              color="primary"
              disabled={disabled}
            />
          }
          label={
            labelIcon ? (
              <>
                {label} <img src={labelIcon} />
              </>
            ) : (
              <div className="row">
                {label} {required ? <p className={styles.red}>*</p> : null}
              </div>
            )
          }
        />

        {tooltip && (
          <Tooltip interactive title={tooltip}>
            <img src={info} alt="" />
          </Tooltip>
        )}
      </div>
      {meta && meta.touched && meta.error && (
        <div className="error">{meta.error}</div>
      )}
    </div>
  );
};

export default Checkbox;
