import { AxiosResponse } from "axios";
import { instance, SERVER_HOST, headers } from "./index";
import { RequestType, CreateRequest } from "../types/requests";

export default {
  getRequests: async () => {
    const {
      data,
    }: AxiosResponse<{ data: Array<RequestType> }> = await instance.get(
      `${SERVER_HOST}/requests/my`,
      headers()
    );
    return data;
  },

  getMyRequestById: async (id: number) => {
    const { data }: AxiosResponse<{ data: RequestType }> = await instance.get(
      `${SERVER_HOST}/requests/my/${id}`,
      headers()
    );
    return data;
  },

  getRequestById: async (id: string, type: string) => {
    const { data }: AxiosResponse<{ data: RequestType }> = await instance.get(
      `${SERVER_HOST}/requests/${type}/${id}`,
      headers()
    );
    return data;
  },

  createRequest: async (value: CreateRequest) => {
    const { data }: AxiosResponse<{ data: RequestType }> = await instance.post(
      `${SERVER_HOST}/requests/${value.type}`,
      value.data,
      headers()
    );
    return data;
  },

  updateRequest: async ({ id, value }: { id: number; value: RequestType }) => {
    const { data }: AxiosResponse<{ data: RequestType }> = await instance.put(
      `${SERVER_HOST}/requests/${id}`,
      value,
      headers()
    );
    return data;
  },

  deleteRequest: async (id: number) => {
    const { data }: AxiosResponse<{ data: string }> = await instance.delete(
      `${SERVER_HOST}/requests/${id}`,
      headers()
    );
    return data;
  },
};
