import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FavoriteType,
  FavoriteAddRemoveResponseType,
} from "../../types/favorites";
import api from "../../api";

export const getResourceFavorites = createAsyncThunk(
  "favorites/resources",
  async () => {
    try {
      const {
        data,
      }: { data: Array<FavoriteType> } = await api.favorites.getFavorites(
        "resources"
      );
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getTeacherFavorites = createAsyncThunk(
  "favorites/teachers",
  async () => {
    try {
      const {
        data,
      }: { data: Array<FavoriteType> } = await api.favorites.getFavorites(
        "teachers"
      );
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getStudentsFavorites = createAsyncThunk(
  "favorites/users",
  async () => {
    try {
      const {
        data,
      }: { data: Array<FavoriteType> } = await api.favorites.getFavorites(
        "users"
      );
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const addFavorite = createAsyncThunk(
  "favorites/add",
  async (id: number, thunkAPI) => {
    try {
      const {
        data,
      }: {
        data: FavoriteAddRemoveResponseType;
      } = await api.favorites.addFavorite(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const removeFavorite = createAsyncThunk(
  "favorites/remove",
  async (id: number, thunkAPI) => {
    try {
      const {
        data,
      }: {
        data: FavoriteAddRemoveResponseType;
      } = await api.favorites.removeFavorite(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
