import { createSlice } from "@reduxjs/toolkit";
import { message } from "../../helpers/notifications";
import { InitialMessageType, ChatType } from "../../types/messages";
import {
  getChats,
  deleteChat,
  getMessages,
  getMessageById,
  createMessage,
  allChatMessagesRead,
} from "../actions/messages";

const initialState: InitialMessageType = {
  message: null,
  messages: [],
  chat: null,
  chats: null,
  chatsTeacher: [],
  chatsRequest: [],
  unreadMessagesCount: 0,
  isLoading: false,
  errors: null,
  errorsMessages: null,
  errorsChats: null,
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    ressetMessages(state) {
      return {
        ...state,
        message: null,
        messages: [],
      };
    },
    setStateValue(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
    setMessages(state, { payload }) {
      if (state.chat?.id === payload.data?.chat_id && !state.message) {
        if(state.messages?.length === 1 && state.messages[0].id === payload.data.id){
          return state;
        }
        return {
           ...state,
           messages: state.messages ? [...state.messages, payload.data] : [payload.data],
        }
      }
    },
    setChats(state, { payload }) {
      const chats =
        payload.type === "teacher" ? "chatsTeacher" : "chatsRequest";

      return {
        ...state,
        [chats]: payload.data,
      };
    },
    setUnreadMessages(state, { payload }) {
      let count = 0;

      const chatsTeacher = state.chatsTeacher?.map((chat: any) => {
        if (chat.id === payload.data?.message?.chat_id) {
          count =
            state.chat?.id !== chat.id
              ? count + payload.data?.unread_messages_count
              : count;
          return {
            ...chat,
            unread_messages_count: payload.data?.unread_messages_count,
          };
        } else {
          count += chat.unread_messages_count;
          return chat;
        }
      });

      const chatsRequest = state.chatsRequest?.map((chat: any) => {
        if (chat.id === payload.data?.message?.chat_id) {
          count =
            state.chat?.id !== chat.id
              ? count + payload.data?.unread_messages_count
              : count;
          return {
            ...chat,
            unread_messages_count: payload.data?.unread_messages_count,
          };
        } else {
          count += chat.unread_messages_count;
          return chat;
        }
      });

      if (state.chat?.id !== payload.data?.message?.chat_id) {
        return {
          ...state,
          chatsTeacher: chatsTeacher,
          chatsRequest: chatsRequest,
          unreadMessagesCount: count,
        };
      } else {
        return state;
      }
    },
    setReadMessages(state, { payload }) {
      const chats =
        payload.type === "teacher" ? "chatsTeacher" : "chatsRequest";

      const newArray = state[chats]?.map((chat: any) => {
        if (chat.id === payload.data?.message?.chat_id) {
          return {
            ...chat,
            unread_messages_count: 0,
          };
        } else {
          return chat;
        }
      });
      return {
        ...state,
        [chats]: newArray,
      };
    },
    setReadHeaderMessages(state, { payload }) {
      return {
        ...state,
        unreadMessagesCount: state.unreadMessagesCount
          ? state.unreadMessagesCount - payload.data
          : 0,
      };
    },
  },
  extraReducers: {
    [getChats.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errorsChats = null;
    },
    [getChats.fulfilled.toString()]: (state, { payload }) => {
      if (payload.type === "teacher") {
        state.chatsTeacher = payload.data;
      }
      if (payload.type === "teacher_request") {
        state.chatsRequest = payload.data;
      }
      state.isLoading = false;
    },
    [getChats.rejected.toString()]: (state, { payload }) => {
      state.errorsChats = payload.errors;
      state.isLoading = false;
    },
    [deleteChat.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errorsChats = null;
    },
    [deleteChat.fulfilled.toString()]: (state, { payload }) => {
      state.messages = null;
      state.isLoading = false;
    },
    [deleteChat.rejected.toString()]: (state, { payload }) => {
      state.errorsChats = payload.errors;
      state.isLoading = false;
    },
    [getMessages.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errorsMessages = null;
    },
    [getMessages.fulfilled.toString()]: (state, { payload }) => {
      state.messages = payload;
      state.isLoading = false;
    },
    [getMessages.rejected.toString()]: (state, { payload }) => {
      state.errorsMessages = payload.errors;
      state.isLoading = false;
    },
    [getMessageById.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getMessageById.fulfilled.toString()]: (state, { payload }) => {
      state.message = payload;
      state.isLoading = false;
    },
    [getMessageById.rejected.toString()]: (state, { payload }) => {
      state.errors = payload.errors;
      state.isLoading = false;
    },
    [createMessage.pending.toString()]: (state) => {
      state.errors = null;
    },
    [createMessage.fulfilled.toString()]: (state, { payload }) => {
      if (!state.chat) {
        state.message = payload;
        state.messages?.push(payload);
      } else {
        state.message = null;
      }
    },
    [createMessage.rejected.toString()]: (state, { payload }) => {
      state.errors = payload.errors;
    },
    [allChatMessagesRead.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errorsMessages = null;
    },
    [allChatMessagesRead.fulfilled.toString()]: (state, { payload }) => {
      state.isLoading = false;
    },
    [allChatMessagesRead.rejected.toString()]: (state, { payload }) => {
      state.errorsMessages = payload.errors;
      state.isLoading = false;
    },
  },
});

export const {
  ressetMessages,
  setStateValue,
  setMessages,
  setChats,
  setUnreadMessages,
  setReadMessages,
  setReadHeaderMessages,
} = messagesSlice.actions;

export default messagesSlice.reducer;
