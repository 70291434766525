import { createSlice } from "@reduxjs/toolkit";
import { getParents, getParentById } from "../actions/parents";

import { InitialParentType } from "../../types/parents";

const initialState: InitialParentType = {
  parent: null,
  parents: null,
  isLoading: false,
  errors: null,
};

const parentsSlice = createSlice({
  name: "parents",
  initialState,
  reducers: {},
  extraReducers: {
    [getParents.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getParents.fulfilled.toString()]: (state, { payload }) => {
      state.parents = payload;
      state.isLoading = false;
    },
    [getParents.rejected.toString()]: (state, { payload }) => {
      state.errors = payload;
      state.isLoading = false;
    },

    [getParentById.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getParentById.fulfilled.toString()]: (state, { payload }) => {
      state.parent = payload;
      state.isLoading = false;
    },
    [getParentById.rejected.toString()]: (state, { payload }) => {
      state.errors = payload;
      state.isLoading = false;
    },
  },
});

export default parentsSlice.reducer;
