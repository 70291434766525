import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import MainLoader from "../../view/common/MainLoader/MainLoader";
import { StateType } from "../../redux/store";
import { Cookies } from "react-cookie-consent";

type GuestRouteProps = {
  exact: boolean;
  path: string;
  component: React.ComponentType<any>;
};

const GlobalSearchRoute: React.FC<GuestRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const redirectLinkAfterRegister = localStorage.getItem("redirectTo");

  return (
    <Route
      {...rest}
      render={(props) => {
        if (redirectLinkAfterRegister) {
          return <Redirect to={`${redirectLinkAfterRegister}`} />;
        }

        return (
          <Suspense fallback={<MainLoader loading={true} />}>
            <Component {...props} />
          </Suspense>
        );
      }}
    />
  );
};

export default GlobalSearchRoute;
