import React, { MouseEvent } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Avatar, ClickAwayListener } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import clsx from "clsx";

import Button from "../../../common/Button/Button";
import Popup from "../../Popup/Popup";

import expirienceIcon from "../../../../assets/images/PersonDetailPage/Exp.png";
import priceIcon from "../../../../assets/images/PersonDetailPage/Price.png";
import markerIcon from "../../../../assets/images/PersonDetailPage/Location.png";
import calendarIcon from "../../../../assets/images/PersonDetailPage/workingTime.png";
import favoriteIcon from "../../../../assets/images/cardIcons/favorite.png";
import favoriteActiveIcon from "../../../../assets/images/cardIcons/favoriteActive.png";
import avatarPlaceholder from "../../../../assets/images/avatarPlaceholder.png";

import { cutEmploymentTypes, createText } from "../../../../helpers/functions";
import { shortLastName } from "../../../../helpers/strings";
import { PLAN_SUBSCRIPTION } from "../../../../helpers/constants";
import { StateType } from "../../../../redux/store";

import styles from "../styles.module.scss";
import localStyles from "./styles.module.scss";
import FavoriteButton from "../../../pages/CommonPages/Favorites/FavoriteButton/FavoriteButton";

type PropsType = {
  data: any;
  fromProfile?: boolean;
  setIsOpenAuthModal?: (a: boolean) => void;
  isFromGlobal?: boolean;
  setTargetUrl?: (a: string) => void;
};

const CardSearchTeacter: React.FC<PropsType> = ({
  data,
  isFromGlobal,
  fromProfile,
  setIsOpenAuthModal,
  setTargetUrl,
}) => {
  const history = useHistory();
  const { profile } = useSelector((state: StateType) => state.user);

  const onClick = () => {
    if (isFromGlobal) {
      setTargetUrl &&
        setTargetUrl(`/details/teachers/${data?.searchable_id}/global`);
    }
    if (fromProfile) {
      setIsOpenAuthModal && setIsOpenAuthModal(true);
      return;
    }
    history.push(
      `/details/teachers/${data?.searchable_id}${
        isFromGlobal ? "/global" : ""
      }`,
      {
        pageYOffset: window.pageYOffset,
      }
    );
  };

  const onSendMessage = (e: MouseEvent) => {
    e.stopPropagation();
    if (fromProfile) {
      setIsOpenAuthModal && setIsOpenAuthModal(true);
      return;
    }
    const url =
      profile?.plan !== PLAN_SUBSCRIPTION.FREE
        ? `/user/messages?teacher_id=${data?.searchable_id}`
        : `/user/upgrade-your-plan/${data?.searchable_id}`;

    history.push(url);
  };

  const filteredArrayEmploymentTypes = data?.employment_types?.length
    ? data?.employment_types.filter(
        (item: any) => item.title !== "Hourly Tutor"
      )
    : [];

  const upgradeClickHandler = (e: MouseEvent) => {
    e.stopPropagation();
    history.push("/user/subscription");
  };
  return (
    <div className={localStyles.wrapper}>
      {data?.searchable?.user?.plan === PLAN_SUBSCRIPTION.BUSINESS &&
      data?.searchable?.full_banner ? (
        <div
          onClick={onClick}
          className={localStyles.banner}
          style={{
            backgroundImage: `url(${data?.searchable?.full_banner})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
      ) : null}

      <div className={styles.cardWrapper} onClick={onClick}>
        <div className={localStyles.avatarResourceWrapper}>
          <Avatar
            aria-label="rounded"
            className={styles.avatar}
            src={data?.searchable?.full_image || avatarPlaceholder}
          />
          <div className={localStyles.mobileSection}>
            <div className={localStyles.mobileTopSection}>
              {data?.searchable?.user?.plan === PLAN_SUBSCRIPTION.BUSINESS &&
              data?.search_title ? (
                <h5 className={styles.cardTitle}>{data?.search_title}</h5>
              ) : (
                <h5 className={styles.cardTitle}>
                  {shortLastName(
                    data?.searchable?.user?.first_name,
                    data?.searchable?.user?.last_name
                  )}
                </h5>
              )}
              <div className={localStyles.mobileTopRightSection}>
                <FavoriteButton
                  profileId={data.id}
                  isFavorite={data.is_favorite}
                  disabled={
                    profile?.id === data?.searchable?.user_id || fromProfile
                  }
                />

                {profile?.plan === PLAN_SUBSCRIPTION.FREE ? (
                  <Popup
                    isStopPropagation={true}
                    button={
                      <Button customColor="yellow">
                        <span className={styles.msgBtn}>Message</span>
                      </Button>
                    }
                  >
                    <div className={styles.popup}>
                      <p> Messaging is a Premium Feature.</p>
                      <p> Would you like to upgrade?</p>
                      <span className="link" onClick={upgradeClickHandler}>
                        Click here to upgrade.
                      </span>
                    </div>
                  </Popup>
                ) : (
                  <Button
                    customColor="yellow"
                    onClick={onSendMessage}
                    disabled={profile?.id === data?.searchable?.user_id}
                  >
                    <span className={styles.msgBtn}>Message</span>
                  </Button>
                )}
              </div>
            </div>
            <div className={localStyles.mobileBottomSection}>
              {data?.searchable?.reviews_info?.total_reviews ? (
                <div className={styles.ratingWrapper}>
                  <StarIcon className={styles.starIcon} />
                  <span className={styles.currentRating}>
                    {(
                      data?.searchable?.reviews_info?.average_rating /
                      data?.searchable?.reviews_info?.total_reviews
                    ).toFixed(1) || 0}
                  </span>
                  &nbsp;
                  <span className={styles.reviewsCount}>
                    {`( ${data.searchable?.reviews_info.total_reviews} Review${
                      data.searchable?.reviews_info.total_reviews === 1
                        ? ""
                        : "s"
                    } )`}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={styles.cardContent}>
          <div className={styles.cardContent_rowTop}>
            {data?.searchable?.user?.plan === PLAN_SUBSCRIPTION.BUSINESS &&
            data?.search_title ? (
              <h5 className={styles.cardTitle}>{data?.search_title}</h5>
            ) : (
              <h5 className={styles.cardTitle}>
                {shortLastName(
                  data?.searchable?.user?.first_name,
                  data?.searchable?.user?.last_name
                )}
              </h5>
            )}

            {/* Exclude these blocks from Global Search Highlight Cards for cleaner look */}
            {!isFromGlobal ? (
              <div className={styles.buttonWrapper}>
                <FavoriteButton
                  profileId={data.id}
                  isFavorite={data.is_favorite}
                  disabled={
                    profile?.id === data?.searchable?.user_id || fromProfile
                  }
                  setIsOpenAuthModal={setIsOpenAuthModal}
                />
                {profile?.plan === PLAN_SUBSCRIPTION.FREE ? (
                  <Popup
                    isStopPropagation={true}
                    button={
                      <Button customColor="yellow">
                        <span className={styles.msgBtn}>Message</span>
                      </Button>
                    }
                  >
                    <div className={styles.popup}>
                      <p> Messaging is a Premium Feature.</p>
                      <p> Would you like to upgrade?</p>
                      <span className="link" onClick={upgradeClickHandler}>
                        Click here to upgrade.
                      </span>
                    </div>
                  </Popup>
                ) : (
                  <Button
                    customColor="yellow"
                    onClick={onSendMessage}
                    disabled={profile?.id === data?.searchable?.user_id}
                  >
                    <span className={styles.msgBtn}>Message</span>
                  </Button>
                )}
              </div>
            ) : null}
          </div>

          <div className={styles.cardContent_row}>
            {/* Exclude these blocks from Global Search Highlight Cards for cleaner look */}
            {true
              ? [
                  (data?.location ||
                    (data?.country &&
                      ["USA", "US"].indexOf(data?.country) === -1)) && (
                    <span className={styles.metaItem}>
                      <img src={markerIcon} alt="" />
                      <p className={styles.metaItemText}>
                        {data?.location &&
                          `${data?.location?.city}, ${data?.location?.state_id}`}{" "}
                        {data?.country &&
                          ["USA", "US"].indexOf(data?.country) === -1 &&
                          `${data?.country}`}
                      </p>
                    </span>
                  ),
                  filteredArrayEmploymentTypes?.length ? (
                    <span className={styles.metaItem}>
                      <img src={calendarIcon} alt="" />
                      <p className={styles.metaItemText}>
                        {filteredArrayEmploymentTypes.map(
                          (
                            item: { id: number; title: string },
                            index: number
                          ) => {
                            return index !==
                              filteredArrayEmploymentTypes.length - 1 ? (
                              <span key={index}>
                                {cutEmploymentTypes(item?.title)},{" "}
                              </span>
                            ) : (
                              <span key={index}>
                                {cutEmploymentTypes(item?.title)}
                              </span>
                            );
                          }
                        )}
                      </p>
                    </span>
                  ) : null,
                  data?.teacher_experience ? (
                    <span className={styles.metaItem}>
                      <img src={expirienceIcon} alt="" />{" "}
                      <p className={styles.metaItemText}>
                        {data?.teacher_experience}/years
                      </p>
                    </span>
                  ) : null,
                  (data?.full_time_max && data?.full_time_min) ||
                  (data?.part_time_max && data?.part_time_min) ? (
                    <span className={styles.metaItem}>
                      <img src={priceIcon} alt="" />
                      <p className={styles.metaItemText}>
                        {!!data?.full_time_max ? (
                          <>
                            Full-time:$
                            {`${data.full_time_min || 0}-${data.full_time_max}`}
                          </>
                        ) : data?.full_time_max === 0 &&
                          data?.full_time_min === 0 ? (
                          <>
                            Full-time:
                            {`Free`}
                          </>
                        ) : null}{" "}
                        {!!data?.part_time_max ? (
                          <>
                            Part-time:$
                            {`${data.part_time_min}-${data.part_time_max}`}
                          </>
                        ) : data?.part_time_max === 0 &&
                          data?.part_time_max === 0 ? (
                          <>
                            Part-time:
                            {`Free`}
                          </>
                        ) : null}
                      </p>
                    </span>
                  ) : null,
                ]
              : null}

            {data?.searchable?.reviews_info?.total_reviews ? (
              <div className={styles.ratingWrapper}>
                <StarIcon className={styles.starIcon} />
                <span className={styles.currentRating}>
                  {(
                    data?.searchable?.reviews_info?.average_rating /
                    data?.searchable?.reviews_info?.total_reviews
                  ).toFixed(1) || 0}
                </span>
                &nbsp;
                <span className={styles.reviewsCount}>
                  {`( ${data.searchable?.reviews_info.total_reviews} Review${
                    data.searchable?.reviews_info.total_reviews === 1 ? "" : "s"
                  } )`}
                </span>
              </div>
            ) : null}
          </div>
          {data?.searchable?.description ? (
            <div className={styles.cardContent_row}>
              <span
                className={clsx(styles.cardContent_description_teacher, {
                  [styles.description_teacher]: isFromGlobal,
                })}
              >
                <p>{data?.searchable?.short_description}</p>
              </span>
            </div>
          ) : null}

          {data?.searchable?.premier_description &&
          data?.searchable?.user?.plan === PLAN_SUBSCRIPTION.BUSINESS ? (
            <div className={styles.cardContent_row_no_Margin}>
              <p className={styles.cardContent_description_teacher}>
                {data?.searchable?.premier_description}
              </p>
            </div>
          ) : null}
          {/* <div className={localStyles.wrap_button}> */}
          {/* {profile?.plan === PLAN_SUBSCRIPTION.FREE ? (
              <Popup
                isStopPropagation={true}
                button={
                  <Button customColor="yellow">
                    <span className={styles.msgBtn}>Message</span>
                  </Button>
                }
              >
                <div className={styles.popup}>
                  <p> Messaging is a Premium Feature.</p>
                  <p> Would you like to upgrade?</p>
                  <span className="link" onClick={upgradeClickHandler}>
                    click here to upgrade
                  </span>
                </div>
              </Popup>
            ) : (
              <Button customColor="yellow" onClick={onSendMessage}>
                <span className={styles.msgBtn}>Message</span>
              </Button>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CardSearchTeacter;
