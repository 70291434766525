import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";

import DefaultRoute from "./default";
import GuestRoute from "./hoc/GuestRoute";
import PrivateRoute from "./hoc/PrivateRoute";
import GlobalSearchRoute from "./hoc/GlobalSearchRoute";
import MainLayout from "../view/layout/MainLayout";
import MainLoader from "../view/common/MainLoader/MainLoader";

import GetStartedRoute from './hoc/GetStartedRoute'
import SearchProfileFormRoute from "./hoc/SearchProfileFormRoute";
import AboutUsPage from "../view/pages/StaticPages/AboutUs";
import CarrersPage from "../view/pages/StaticPages/Carrers";
import TermsOfUsePage from "../view/pages/StaticPages/TermsOfUse";
import PrivacyPolicyPage from "../view/pages/StaticPages/PrivacyPolicy";
import Soon from "../view/pages/StaticPages/Soon";
import BusinessMembership from "../view/pages/StaticPages/BusinessMembershipPage/BusinessmembershipWrapper";
import PageNotAvailable from "../view/pages/PageNotAvailable/PageNotAvailable";
import { getProfile } from "../redux/actions/user";
import { StateType } from "../redux/store";
import { Helmet } from "react-helmet";
import styles from "./styles.module.scss";
import HomePage from "../view/pages/StaticPages/HomePage/HomePage";

const MainPages = React.lazy(() => import("../view/pages/UserPages"));
const AuthPages = React.lazy(() => import("../view/pages/AuthPages"));
const DetailsPages = React.lazy(() => import("../view/pages/DetailsPages"));
const GlobalPage = React.lazy(
  () => import("../view/pages/CommonPages/GlobalSearchPage/GlobalSearchPage")
);
const SearchProfilePage = React.lazy(
  () => import("../view/pages/UserPages/SearchProfile/SearchProfilePage")
);

const GetStartedPage = React.lazy(
  () => import("../view/pages/UserPages/GetStartedPage/GetStartedPage")
)

const App: React.FC = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const { profile } = useSelector((state: StateType) => state.user);
  const { user } = useSelector((state: StateType) => state.auth);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const getMe = async () => {
    token && (await dispatch(getProfile()));
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    if (
      !token &&
      !profile &&
      (location.pathname.includes("/details") || location.pathname.includes("/user/"))
    ) {
      localStorage.setItem("pathnameToRedirectAfterLogin", location.pathname);
    } 
  }, []);

  useEffect(() => {
    if (token && !profile && user) {
      setLoading(true);
      // window.location.reload();
      // setTimeout(() => {
      //   getMe();
      // }, 200);
      getMe();
    }
  }, [token, user, dispatch]);

  return (
    <>
      <Helmet>
        <title>Anything Academic - Anything Academic</title>
        <meta
          name="description"
          content="Welcome to Anything Academic! We are committed to being the only place you need to go to access Anything Academic."
        />
      </Helmet>
      <MainLoader loading={loading} />

      {!loading && (
        <MainLayout>
          <div className={styles.main}>
            <Switch>
              <Route path="/" exact component={DefaultRoute} />
              <Route path="/home" exact component={HomePage} />
              <Route
                path="/business-memberships"
                exact
                render={() => <BusinessMembership />}
              />
              <Route path="/soon" render={() => <Soon />} />
              <SearchProfileFormRoute
                path="/search-profile-form"
                component={SearchProfilePage}
              />
              <GetStartedRoute path="/get-started" component={GetStartedPage} />
              <Route path="/about-us" exact component={AboutUsPage} />
              <Route path="/careers" exact component={CarrersPage} />
              <Route path="/terms-of-use" exact component={TermsOfUsePage} />
              <Route path="/page-not-available" exact component={PageNotAvailable} />
              <Route
                path="/privacy-policy"
                exact
                component={PrivacyPolicyPage}
              />

              <GuestRoute path="/auth/:type" exact component={AuthPages} />

              <PrivateRoute
                path="/user/:category?/:id?/:type?"
                exact
                component={MainPages}
              />

              <PrivateRoute
                path="/details/:category?/:id?/:type?/:owner?"
                exact
                component={DetailsPages}
              />
              <GlobalSearchRoute
                path="/global-search"
                exact
                component={GlobalPage}
              />
            </Switch>
          </div>
        </MainLayout>
      )}
    </>
  );
};

export default App;
