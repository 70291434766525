import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { List, Avatar } from "@material-ui/core";
import clsx from "clsx";
import SidebarMainButton from "../../common/SidebarMainButton/SidebarMainButton";
import Tooltip from "../../components/Toopltip/Toopltip";
import Popup from "../../components/Popup/Popup";
import avatarPlaceholder from "../../../assets/images/avatarPlaceholder.png";
import campIcon from "../../../assets/images/buttons/blueButtons/camps.png";
import globalIcon from "../../../assets/images/buttons/global-icon-darkblue.png";
import {
  navMenuInSidebarList,
  librariesSidebarList,
} from "../../../helpers/values";
import {
  iconsPremiumMatches,
  hiddenResourceTypes,
} from "../../../helpers/values";
import { PLAN_SUBSCRIPTION } from "../../../helpers/constants";

import { StateType } from "../../../redux/store";
import {
  getSearchProfiles,
  updateDefaultSearchProfile,
  getSearchProfileById,
} from "../../../redux/actions/searchProfiles";
import { setCurrentPage } from "../../../redux/reducers/filters";
import { getResourceTypes } from "../../../redux/actions/resources";
import { getProfile } from "../../../redux/actions/user";
import { setFilters } from "../../../redux/reducers/filters";
import { shortLastName } from "../../../helpers/strings";
import { ressetSearchProfile } from "../../../redux/reducers/searchProfiles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faCaretSquareDown, faEdit } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";

type PropsType = {};
type ParamTypes = {
  id: string;
};
const LeftSidebar: React.FC<PropsType> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation<any>();
  const params = useParams<ParamTypes>();
  const searchParams = new URLSearchParams(location?.search);


  //by go back update profile: params.id - sometimes undefined
  const idFromUrl = location.pathname.split("/")[
    location.pathname.split("/")?.length - 1
  ];
  const id = params.id || typeof +idFromUrl === "number" ? +idFromUrl : null;
  const { profile } = useSelector((state: StateType) => state.user);
  const { searchProfiles, searchProfile } = useSelector(
    (state: StateType) => state.searchProfiles
  );
  const { resourcesTypes } = useSelector((state: StateType) => state.resources);
  const current_profile_id = searchParams.get("current_profile_id") || id;

  const onSwitchSearchProfile = async (id: number, searchableType: string) => {
    dispatch(ressetSearchProfile());
    localStorage.removeItem("filtersList");

    if (searchableType?.includes("Teacher")) {
      return history.push(`/user/teacher-requests/${id}`);
    } else {
      history.push(`/user/teachers/${id}`);
    }
  };

  useEffect(() => {
    id && dispatch(getSearchProfileById(+id));
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getSearchProfileById(+id));

      dispatch(
        setFilters({
          type: "filters",
          data: [],
        })
      );

      localStorage.removeItem("filtersList");

      if (location.pathname.includes("/user/teachers")) {
        dispatch(updateDefaultSearchProfile(+id));
        dispatch(getProfile());
        dispatch(getSearchProfiles());
      }
    }
    dispatch(getResourceTypes());
  }, [id]);

  useEffect(() => {
    !searchProfiles?.length && dispatch(getSearchProfiles());
  }, [searchProfiles?.length]);

  const profileDataOnClickHandler = () => {
    searchProfile?.searchable_type?.includes("Teacher")
      ? history.push(`/user/teacher-profile/${searchProfile?.id}`)
      : history.push(`/user/search-profile/${searchProfile?.id}`);
  };

  const title = () => {
    const title = current_profile_id
      ? searchProfiles?.find((item) => item.id === +current_profile_id)
      : searchProfile;

    return title?.search_title
      ? title?.search_title
      : profile?.first_name && profile?.last_name
        ? shortLastName(profile?.first_name, profile?.last_name)
        : "";
  };
  const camps = resourcesTypes?.find((item) => item.title === "Camps");

  const avatar = () => {
    return (
      searchProfile?.searchable?.full_image ||
      searchProfile?.full_image ||
      profile?.full_image ||
      avatarPlaceholder
    );
  };

  return (
    <div
      className={clsx(styles.mainSidebarWrapper, {
        [styles.noMargin]: profile?.plan === PLAN_SUBSCRIPTION.FREE,
      })}
    >
      {profile?.plan === PLAN_SUBSCRIPTION.FREE ? (
        <div
          className={styles.goldLabel}
          onClick={() => history.push("/user/subscription")}
        >
          <p>UPGRADE TODAY!</p>
        </div>
      ) : null}
      <div className={styles.sidebarWrapper}>
        <div className={styles.globalSearchButton}>
          <SidebarMainButton
            img={globalIcon}
            name="Global Search"
            url={`/global-search`}
            tooltip="Search all Categories by Keyword"
            active={
              location.pathname.includes("global-search")
            }
          />
        </div>

        <div className={styles.spacerBar}></div>

        <div className={styles.topSidebarBlock}>
          <div className={`row ai-center ${styles.userDataWrapper}`} >
            <Tooltip interactive title="Search by category with personalized profile filters" placement="right" >
              <div className={styles.avatarLink}>
                <Avatar src={avatar()} className={styles.avatar}>{title()}</Avatar>
                <span>Profile Search</span>
              </div>
            </Tooltip>

            <div className={styles.profileSelect} >
              <div className={styles.profileTitle}>{title()}</div>

              <Tooltip interactive title="Edit Search Profile" placement="right" >
                <FontAwesomeIcon icon={faEdit} className={styles.faEditIcon} onClick={profileDataOnClickHandler}></FontAwesomeIcon>
              </Tooltip>

              <Tooltip interactive title="Change Active Search Profile" placement="right" >
                <Popup
                  button={
                    <FontAwesomeIcon icon={faCaretSquareDown} fixedWidth={true} className={styles.faHeaderIcon}></FontAwesomeIcon>
                    // <div className={styles.switchButton}>
                    //   {/* <img src={ButtonIcon}  alt=""/> */}
                    //   {/* <p className={styles.switchButtonText}>Switch Search Profile</p> */}
                    // </div>
                  }
                  withHide
                  fromSidebar
                >
                  {/* <div className={styles.switchProfileModal}> */}
                  <List>
                    {searchProfiles &&
                      (searchProfiles as Array<any>).map(
                        (item: any, index: number) => (
                          <div
                            className={styles.listItem}
                            key={index}
                            onClick={() =>
                              onSwitchSearchProfile(item.id, item.searchable_type)
                            }
                          >
                            {item.search_title
                              ? item.search_title
                              : profile?.first_name && profile?.last_name
                                ? shortLastName(profile?.first_name, profile?.last_name)
                                : "No name Profile"}
                          </div>
                        )
                      )}
                  </List>
                  {/* </div> */}
                </Popup>
              </Tooltip>
            </div>

          </div>
        </div>

        {/* {currentSearchProfile && (
          <>
            {currentSearchProfile?.searchable_type?.includes("Teacher") ? (
              <SidebarMainButton
                key={0}
                img={navMenuInSidebarList[0].img}
                name={"Publish my teacher profile"}
                url={`/user/teacher-profile/${currentSearchProfile?.id}?step=3`}
              />
            ) : (
              <SidebarMainButton
                key={1}
                img={navMenuInSidebarList[1].img}
                name={"Publish my teacher request"}
                url={`/user/search-profile/${currentSearchProfile?.id}?step=2`}
              />
            )}
          </>
        )} */}

        <div className={styles.filterButtonsListWrapper}>
          {searchProfile?.searchable_type?.includes("Teacher") ? (
            <SidebarMainButton
              key={0}
              img={navMenuInSidebarList[0].img}
              name={navMenuInSidebarList[0].name}
              url={
                searchProfile
                  ? `${navMenuInSidebarList[0].url}/${searchProfile.id}`
                  : `${navMenuInSidebarList[0].url}`
              }
              active={location.pathname.includes(navMenuInSidebarList[0].url)}
            />
          ) : (
            <SidebarMainButton
              key={1}
              img={navMenuInSidebarList[1].img}
              name={navMenuInSidebarList[1].name}
              url={
                searchProfile
                  ? `${navMenuInSidebarList[1].url}/${searchProfile.id}`
                  : `${navMenuInSidebarList[1].url}`
              }
              active={location.pathname.includes(navMenuInSidebarList[1].url)}
            />
          )}
          {camps && (
            <SidebarMainButton
              img={campIcon}
              name={camps.title}
              url={`/user/resource/${camps.id}/${camps.type.replace(
                "/",
                "-"
              )}?current_profile_id=${current_profile_id || searchProfile?.id}`}
              tooltip={camps.tooltip}
              active={
                location.pathname.includes(camps.title) ||
                location.pathname.replace("-", "/").includes(camps.title)
              }
            />
          )}
        </div>

        <div className={styles.premiumButtonsBlock}>
          {resourcesTypes?.map(
            (
              item: {
                id: number;
                title: string;
                type: string;
                tooltip: string;
              },
              index: number
            ) =>
              !hiddenResourceTypes.includes(item.type) &&
              item.title !== "Camps" && (
                <SidebarMainButton
                  key={index}
                  img={iconsPremiumMatches[item.id]?.blueIcon}
                  name={item.title}
                  url={`/user/resource/${item.id}/${item.type.replace(
                    "/",
                    "-"
                  )}?current_profile_id=${current_profile_id || searchProfile?.id
                    }`}
                  tooltip={item.tooltip}
                  active={
                    location.pathname.includes(item.title) ||
                    location.pathname.replace("-", "/").includes(item.title)
                  }
                  onClick={
                    location.pathname.includes(item.title)
                      ? undefined
                      : () => dispatch(setCurrentPage({ data: 1 }))
                  }
                />
              )
          )}
        </div>

        <div className={styles.spacerBar}></div>

        <p className={styles.sidebarTitleWithMargin}>Learn More</p>

        <div
          className={styles.filterButtonsListWrapper}
          style={{ marginTop: 20 }}
        >
          {librariesSidebarList.map((item, index) => {
            return (
              <SidebarMainButton
                img={profile?.plan === "free" ? item.img : item.premiumIcon}
                key={index}
                name={item.name}
                url={
                  item.name == "Teacher Business Tools" &&
                    profile?.plan === PLAN_SUBSCRIPTION.FREE
                    ? `/user/upgrade-your-plan/${searchProfile?.id || id || ""}`
                    : item.name == "Your State Laws and Teacher Credential Verifications"
                      ? `/user/resource/19/Credential Verification?current_profile_id=${current_profile_id || searchProfile?.id
                      }`
                      : item.name == "Teacher Business Tools"
                        ? process.env.REACT_APP_WP_HOST_MEMBER + item.url
                        : searchProfile?.location?.state_url
                          ? process.env.REACT_APP_WP_HOST +
                          item.url.replace(
                            "{state}",
                            searchProfile?.location?.state_url
                          )
                          : `${process.env.REACT_APP_WP_HOST}/state-laws/`
                }
                target={
                  item.name == "Your State Laws and Teacher Credential Verifications" ||
                    (item.name == "Teacher Business Tools" &&
                      profile?.plan === PLAN_SUBSCRIPTION.FREE)
                    ? "_self"
                    : "_blank"
                }
                active={location.pathname.includes(item.url)}
              />
            );
          })}
          {/*Hardcoded as per Holly's requirements */}
        </div>
      </div>
    </div>
  );
};

export default LeftSidebar;
