import { createAsyncThunk } from "@reduxjs/toolkit";
import { SubjectType, CreateSubjectType } from "../../types/subjects";
import api from "../../api";

export const getSubjects = createAsyncThunk("subjects/get-list", async () => {
  try {
    const {
      data,
    }: { data: Array<SubjectType> } = await api.subjects.getSubjects();
    return data;
  } catch (err) {
    return err.response.data;
  }
});

export const getSubjectById = createAsyncThunk(
  "subjects/get-by-id",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: SubjectType } = await api.subjects.getSubjectById(
        id
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createSubject = createAsyncThunk(
  "subjects/create",
  async (value: CreateSubjectType, thunkAPI) => {
    try {
      const { data }: { data: SubjectType } = await api.subjects.createSubject(
        value
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const uodateSubject = createAsyncThunk(
  "subjects/update",
  async (values: { id: number; value: SubjectType }, thunkAPI) => {
    try {
      const { data }: { data: SubjectType } = await api.subjects.updateSubject(
        values
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteSubject = createAsyncThunk(
  "subjects/delete",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: string } = await api.subjects.deleteSubject(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
