import { createSlice } from "@reduxjs/toolkit";
import { InitialResourceType } from "../../types/resource";
import {
  getLearningResourceById,
  getResourceTypes,
} from "../actions/resources";

const initialState: InitialResourceType = {
  resource: null,
  resourcesTypes: null,
  isLoading: false,
  errors: null,
};

const resourceSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {},
  extraReducers: {
    [getLearningResourceById.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getLearningResourceById.fulfilled.toString()]: (state, { payload }) => {
      state.resource = payload;
      state.isLoading = false;
    },
    [getLearningResourceById.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [getResourceTypes.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getResourceTypes.fulfilled.toString()]: (state, { payload }) => {
      state.resourcesTypes = payload;
      state.isLoading = false;
    },
    [getResourceTypes.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload?.errors;
    },
  },
});

export default resourceSlice.reducer;
