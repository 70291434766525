import { PLAN_SUBSCRIPTION } from "./constants";
import { RefObject, useEffect } from "react";

const multiselectTypesList = [
  "languages",
  "independence_levels",
  "curriculum_models",
  "curriculum_types",
  "educational_levels",
  "learning_services",
  "other_services",
  "learning_styles",
  "additional_requirements",
  "credential_states",
  "religions",
  "class_times",
  "week_days",
  "employment_types",
  "subjects",
  "teaching_types",
  "schooling_types",
  "school_forms",
  "camp_categories",
  "camp_types",
  "gender_restrictions",
];

export const setArrayForCarusel = (
  array: Array<any> = [],
  size: number = 4
): Array<any> => {
  let subarray = [];

  for (let i = 0; i < Math.ceil(array.length / size); i++) {
    subarray[i] = array.slice(i * size, i * size + size);
  }
  return subarray;
};

export const getTimeFromMins = (mins: number | string) => {
  let hours: number | string = Math.trunc(+mins / 60),
    minutes: number | string = +mins % 60;

  hours = hours < 10 ? (hours = "0" + hours) : hours;
  minutes = minutes < 10 ? (minutes = "0" + minutes) : minutes;

  return `${hours}:${minutes}`;
};

export const createOptions = (array: Array<string | null>) =>
  array.map((item) => ({
    value: item,
    text: item,
  }));

export const str2bool = (value: any) => {
  if (value && typeof value === "string") {
    if (value.toLowerCase() === "true") return true;
    if (value.toLowerCase() === "false") return false;
  }
  return value;
};

export const arrayBoolean2arrayNumber = (array: Array<boolean | null>) => {
  let newArray: Array<number> = [];
  array.forEach((item, index) => item === true && newArray.push(index));

  return newArray;
};

export const arrayObject2arrayNumber = (array: Array<any> | null) => {
  let newArray: Array<number> = [];
  array?.forEach((item: any) => newArray.push(item.id));

  return newArray;
};

export const arrayObject2arrayBoolean = (array: Array<any> | null) => {
  let newArray: Array<any> = [];
  array?.forEach((item: any) => (newArray[item.id] = true));

  return newArray;
};

export const initFormValues = (data: any) => {
  let newData: any = {};

  multiselectTypesList.forEach((element) => {
    if (data && data[element] && Array.isArray(data[element])) {
      newData[element] = arrayObject2arrayBoolean(data[element]);
    }
  });

  return newData;
};

export const setSubjects = (
  array:
    | Array<{
        value: number;
        title: string;
        category: string;
        order: number;
      }>
    | any
) => {
  let newArray: any = {};
  array.forEach((item: any) => {
    if (!newArray.hasOwnProperty(item.category)) {
      newArray[item.category] = [];
    }
  });
  array.map((item: any) => {
    newArray[item.category].push(item.title);
  });
  return newArray;
};

export const checkPlanForSearchProfile = (
  plan: string,
  searchProfiles: any
) => {
  let countPlans = Array.isArray(searchProfiles)
    ? searchProfiles?.filter((item: any) =>
        item.searchable_type?.includes("User")
      ).length
    : 0;

  switch (plan) {
    case PLAN_SUBSCRIPTION.PREMIUM:
      return countPlans < 5;
    case PLAN_SUBSCRIPTION.FAMILY:
    case PLAN_SUBSCRIPTION.BUSINESS:
      return countPlans < 10;
    default:
      return countPlans < 1;
  }
};

export const checkPlanForShowUpgradeMessage = (
  plan: string,
  searchProfiles: any
) => {
  let countPlans = Array.isArray(searchProfiles)
    ? searchProfiles?.filter((item: any) =>
        item.searchable_type?.includes("User")
      ).length
    : 0;

  switch (plan) {
    case PLAN_SUBSCRIPTION.FREE:
      return countPlans === 1;
    case PLAN_SUBSCRIPTION.PREMIUM:
      return countPlans === 5;
    default:
      return false;
  }
};

export const countUnreadMessages = (array: Array<any>) => {
  let count = 0;

  array.forEach((element) => {
    if (element.unread_messages_count) {
      count = count + element.unread_messages_count;
    }
  });

  return count;
};

export const cutString = (str: string, n: number) => {
  if (str.length < (n || 200) && !str.includes("<")) {
    return str;
  }

  let openTags = [];
  let inTag = false;
  let tagName = "";
  let tagNameComplete = false;
  let tagClosing = false;
  let lastSpace = 0;
  for (let p = 0; p < str.length && p < n; p++) {
    let c = str.charAt(p);
    switch (c) {
      case "<":
        lastSpace = !inTag && p + 3 < n ? p : lastSpace;
        inTag = true;
        tagName = "";
        tagNameComplete = false;
        break;
      case ">":
        if (inTag && !tagNameComplete) {
          openTags.push({
            tag: tagName,
            p: p,
          });
          tagNameComplete = true;
        }
        inTag = false;
        if (tagClosing) openTags.pop();
        tagClosing = false;
        tagName = ""; //may be redundant
        break;
      case "/":
        tagClosing = inTag;
        break;
      case " ":
        lastSpace = !inTag && p + 3 < n ? p : lastSpace;
        if (inTag && !tagNameComplete) {
          openTags.push({
            tag: tagName,
            p: p,
          });
          tagNameComplete = true;
        }
        break;
    }
    if (!tagNameComplete && c !== "<" && c !== ">") tagName += c;
  }

  let small = str.substring(0, lastSpace) + "...";
  for (let i = openTags.length - 1; i >= 0; i--)
    if (openTags[i].p <= lastSpace) small += "</" + openTags[i].tag + ">";
  return small;
};

export const checkRender = (item?: any) => (item ? item : null);

export const useFocus = (ref: RefObject<HTMLElement>) => {
  useEffect(() => {
    if (ref && ref.current) {
      return ref.current.focus();
    }
  }, [ref]);
};

export const cutEmploymentTypes = (string: string) => {
  //The availability should be FT, PT... Hourly not spelled out.

  if (string === "Full-time") {
    return "FT";
  }
  if (string === "Half-time") {
    return "HT";
  }
  if (string === "Part-time") {
    return "PT";
  }
};

export const contains = (where: Array<any>, what: Array<any>) => {
  for (var i = 0; i < what.length; i++) {
    if (where.indexOf(what[i]) !== -1) return true;
  }
  return false;
};

export const getValidDateIOS = (value: string) => {
  const array = value.split("-");
  if (+array[1] < 9) {
    array[1] = `0${array[1]}`;
  }
  if (+array[2] < 9) {
    array[2] = `0${array[2]}`;
  }
  const date = array.join("-");
  return date;
};

export const categoryName = (pathname: string) => {
  if (pathname.includes("/user/teachers")) {
    return "Professional";
  } else if (pathname.includes("/user/teacher-requests")) {
    return "Job";
  } else if (pathname.includes("global-search")) {
    return "Global Search";
  } else {
    const arrayPathname = pathname.split("/");
    return arrayPathname?.length && arrayPathname[arrayPathname.length - 1];
  }
};

export const createText = (text: string = "") => {
  return {
    __html: text,
  };
};

export const costFormatter = (cost: number) => {
  if (cost == 0) return "Free";
  if (cost <= 25) return "$1-$25";
  if (cost <= 50) return "$26-$50";
  if (cost <= 100) return "$51-$100";
  if (cost <= 250) return "$101-$250";
  return "$250+";
};

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function getRandomElements(arr: any[], n: number) {
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    var x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}
