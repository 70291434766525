import { AxiosResponse } from "axios";
import { instance, SERVER_HOST, headers } from "./index";
import { SignUpType } from "../types/auth";
import { ParentType } from "../types/parents";

export default {
  getParents: async () => {
    const {
      data,
    }: AxiosResponse<{ data: Array<ParentType> }> = await instance.get(
      `${SERVER_HOST}/parents`,
      headers()
    );
    return data;
  },

  getParentById: async (id: number) => {
    const { data }: AxiosResponse<{ data: ParentType }> = await instance.get(
      `${SERVER_HOST}/parents/${id}`,
      headers()
    );
    return data;
  },

  createParent: async (value: SignUpType) => {
    const { data }: AxiosResponse<{ data: ParentType }> = await instance.post(
      `${SERVER_HOST}/parents`,
      value
    );
    return data;
  },

  updateParent: async ({ id, value }: { id: number; value: ParentType }) => {
    const { data }: AxiosResponse<{ data: ParentType }> = await instance.put(
      `${SERVER_HOST}/parents/${id}`,
      value,
      headers()
    );
    return data;
  },

  deleteParent: async (id: number) => {
    const { data }: AxiosResponse<{ data: string }> = await instance.delete(
      `${SERVER_HOST}/parents/${id}`,
      headers()
    );
    return data;
  },
};
