import { createAsyncThunk } from "@reduxjs/toolkit";
import { SignUpType } from "../../types/auth";
import { ParentType } from "../../types/parents";
import api from "../../api";

export const getParents = createAsyncThunk("parents/get-list", async () => {
  try {
    const {
      data,
    }: { data: Array<ParentType> } = await api.parents.getParents();
    return data;
  } catch (err) {
    return err.response.data;
  }
});

export const getParentById = createAsyncThunk(
  "parents/get-by-id",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: ParentType } = await api.parents.getParentById(
        id
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createParent = createAsyncThunk(
  "parents/create",
  async (value: SignUpType, thunkAPI) => {
    try {
      const { data }: { data: ParentType } = await api.parents.createParent(
        value
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateParent = createAsyncThunk(
  "parents/update",
  async (values: { id: number; value: ParentType }, thunkAPI) => {
    try {
      const { data }: { data: ParentType } = await api.parents.updateParent(
        values
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteParent = createAsyncThunk(
  "parents/delete",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: string } = await api.parents.deleteParent(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
