import React, { MouseEvent } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";
import StarIcon from "@material-ui/icons/Star";
import clsx from "clsx";
import Button from "../../../common/Button/Button";
import favoriteIcon from "../../../../assets/images/cardIcons/favorite.png";
import favoriteActiveIcon from "../../../../assets/images/cardIcons/favoriteActive.png";
import bookPlaceholder from "../../../../assets/images/bookPlaceholder.png";
import curriculumIcon from "../../../../assets/images/curriculum.png";
import levelIcon from "../../../../assets/images/level.png";
import campIcon from "../../../../assets/images/bluecheck.png";
import { costFormatter, cutString } from "../../../../helpers/functions";
import { StateType } from "../../../../redux/store";
import styles from "../styles.module.scss";
import localStyles from "./styles.module.scss";
import FavoriteButton from "../../../pages/CommonPages/Favorites/FavoriteButton/FavoriteButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBirthdayCake,
  faCalendar,
  faCalendarAlt,
  faCampground,
  faChild,
  faDollarSign,
  faGraduationCap,
  faSignal,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

type PropsType = {
  data: any;
  currentPage?: number;
  type?: string;
  showSignUpMenu?: boolean;
  setIsOpenAuthModal?: (a: boolean) => void;
  setTargetUrl?: (a: string) => void;
  isFromGlobal?: boolean;
};

type ParamTypes = {
  resourceTypeId: string;
  id: string;
};

const CardCurriculum: React.FC<PropsType> = ({
  data: {
    searchable,
    location,
    is_country_level,
    country,
    educational_levels,
    is_favorite,
    id,
    camp_types,
    camp_categories,
    student_age,
    age_max,
    age_min,
    // gender_restriction,
    gender_restrictions,
    cost_per_session,
  },
  type,
  currentPage,
  showSignUpMenu,
  setIsOpenAuthModal,
  setTargetUrl,
  isFromGlobal,
}) => {
  const history = useHistory();
  const params = useParams<ParamTypes>();
  const resourceTypeId = params.id;

  const onClick = (e: MouseEvent) => {
    if (showSignUpMenu) {
      setTargetUrl &&
        setTargetUrl(`/details/curriculums/${searchable?.id}/0/global`);
      setIsOpenAuthModal && setIsOpenAuthModal(true);
      return;
    }
    e.stopPropagation();
    history.push(
      isFromGlobal
        ? `/details/curriculums/${searchable?.id}/0/global`
        : `/details/curriculums/${searchable?.id}/${resourceTypeId}/${type}`,
      {
        pageYOffset: window.pageYOffset,
      }
    );
    window.scrollTo(0, 0);
  };

  const onVisitWebsite = (e: MouseEvent) => {
    e.stopPropagation();
    if (showSignUpMenu) {
      return;
    }
    window.location.href = searchable?.publisher_website;
  };

  return (
    <div className={clsx(localStyles.wrapper)} id={`restore_id-${id}`}>
      {searchable?.full_banner && (
        // <div
        //   className={localStyles.banner}
        //   style={{
        //     backgroundImage: `url(${searchable?.full_banner})`,
        //     backgroundRepeat: "no-repeat",
        //     backgroundPosition: "center",
        //     backgroundSize: "cover",
        //   }}
        // ></div>
        <img
          src={searchable?.full_banner}
          className={localStyles.banner}
          onClick={onClick}
        />
      )}

      <div
        className={styles.cardWrapper}
        style={{ marginBottom: 0 }}
        onClick={onClick}
      >
        <div className={localStyles.avatarResourceWrapper}>
          <Avatar
            aria-label="squared"
            className={styles.avatarResource}
            src={searchable?.full_image || bookPlaceholder}
          />
          <div className={styles.cardTitleMobile}>{searchable?.title}</div>
          <div className={styles.buttonCurriculumWrapperMobile}>
            <FavoriteButton profileId={id} isFavorite={is_favorite} />
          </div>
        </div>

        <div className={styles.cardContent}>
          <div
            className={
              (localStyles.cardContent_row,
              styles.cardContent_row,
              styles.cardTitleRow)
            }
          >
            <h5 className={styles.cardTitle}>{searchable?.title}</h5>
          </div>

          <div className={clsx(localStyles.cardContent_row)}>
            {/* <div
              className={localStyles.cardContent_item}
            >
              <FontAwesomeIcon icon={faGraduationCap} className={localStyles.faHeaderIcon}></FontAwesomeIcon>
            </div> */}

            {/* Cost Field Implementated, but held for now pending data validation
            {cost_per_session && cost_per_session !== null && type !== "Camps" ? ( // Cost if not a Camp
              <div
                className={localStyles.cardContent_item}
              >
                <FontAwesomeIcon icon={faDollarSign} className={localStyles.faHeaderIcon}></FontAwesomeIcon>
                {cost_per_session == 0 ? "Free" : costFormatter(cost_per_session)}
              </div>
            ) : null} */}

            {educational_levels?.length && type !== "Camps" ? ( //Camps
              <div className={localStyles.cardContent_item}>
                <FontAwesomeIcon
                  icon={faSignal}
                  className={localStyles.faHeaderIconSmall}
                ></FontAwesomeIcon>
                {educational_levels?.map(
                  (level: { id: number; abbr: string }, index: number) => (
                    <span key={index}>
                      {level.abbr}
                      {index !== educational_levels?.length - 1 && ", "}{" "}
                    </span>
                  )
                )}
              </div>
            ) : null}

            {camp_types?.length && type === "Camps" ? ( //Camps
              <div className={localStyles.cardContent_item}>
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className={localStyles.faHeaderIcon}
                ></FontAwesomeIcon>
                {camp_types?.map(
                  (level: { id: number; title: string }, index: number) => (
                    <span key={index}>
                      {level.title}
                      {index !== camp_types?.length - 1 && ", "}{" "}
                    </span>
                  )
                )}
              </div>
            ) : null}
            {age_max && type === "Camps" ? ( //Camps
              <div className={localStyles.cardContent_item}>
                <FontAwesomeIcon
                  icon={faBirthdayCake}
                  className={localStyles.faHeaderIcon}
                ></FontAwesomeIcon>
                <span>{`Ages ${age_min || 0}-${age_max}`}</span>
              </div>
            ) : null}
            {/* {gender_restriction && type === "Camps" ? ( //Camps */}
            {gender_restrictions?.length && type === "Camps" ? ( //Camps
              <div className={localStyles.cardContent_item}>
                <FontAwesomeIcon
                  icon={faChild}
                  className={localStyles.faHeaderIcon}
                ></FontAwesomeIcon>
                {/* <span>{gender_restriction}</span> */}
                {gender_restrictions?.map(
                  (level: { id: number; title: string }, index: number) => (
                    <span key={index}>
                      {level.title}
                      {index !== gender_restrictions?.length - 1 && ", "}{" "}
                    </span>
                  )
                )}
              </div>
            ) : null}

            {searchable?.reviews_info?.average_rating &&
            searchable?.reviews_info?.average_rating &&
            type !== "Camps" ? (
              <div
                className={
                  searchable?.reviews_info.average_rating !== 0
                    ? styles.ratingWrapperNewStyle
                    : styles.hided
                }
              >
                <FontAwesomeIcon
                  icon={faStar}
                  className={localStyles.faStarIcon}
                ></FontAwesomeIcon>
                <span className={styles.currentRating}>
                  {(
                    searchable?.reviews_info?.average_rating /
                    searchable?.reviews_info?.total_reviews
                  ).toFixed(1) || 0}
                </span>
                &nbsp;
                <span className={styles.reviewsCount}>
                  {`( ${searchable?.reviews_info.total_reviews} Review${
                    searchable?.reviews_info.total_reviews === 1 ? "" : "s"
                  } )`}
                </span>
              </div>
            ) : null}
          </div>
          {camp_categories?.length && type === "Camps" ? ( //
            <div
              className={(styles.cardContent_row, localStyles.cardContent_row)}
            >
              <div className={localStyles.cardContent_item}>
                <FontAwesomeIcon
                  icon={faCampground}
                  className={localStyles.faHeaderIcon}
                ></FontAwesomeIcon>
                {camp_categories?.map(
                  (level: { id: number; title: string }, index: number) => (
                    <span key={index}>
                      {level.title}
                      {index !== camp_categories?.length - 1 && ", "}{" "}
                    </span>
                  )
                )}
              </div>
              {searchable?.reviews_info?.average_rating &&
              searchable?.reviews_info?.average_rating &&
              type === "Camps" ? (
                <div
                  className={
                    searchable?.reviews_info.average_rating !== 0
                      ? styles.ratingWrapperNewStyle
                      : styles.hided
                  }
                >
                  <FontAwesomeIcon
                    icon={faStar}
                    className={localStyles.faStarIcon}
                  ></FontAwesomeIcon>
                  <span className={styles.currentRating}>
                    {(
                      searchable?.reviews_info?.average_rating /
                      searchable?.reviews_info?.total_reviews
                    ).toFixed(1) || 0}
                  </span>
                  &nbsp;
                  <span className={styles.reviewsCount}>
                    {`( ${searchable?.reviews_info.total_reviews} Review${
                      searchable?.reviews_info.total_reviews === 1 ? "" : "s"
                    } )`}
                  </span>
                </div>
              ) : null}
            </div>
          ) : null}
          {location ||
          is_country_level ||
          (country && ["USA", "US"].indexOf(country) === -1) ? (
            <div className={localStyles.cardContent_row}>
              {location?.city ? `${location?.city}, ` : null}
              {location?.state_id ? `${location?.state_id} - ` : null}
              {location?.country_name
                ? `${location?.country_name} County - `
                : null}
              {country ? country : null}
              {is_country_level && type?.toLowerCase() === "online schools" ? (
                <span className={localStyles.bluenote}>
                  Accepts students from all states.
                </span>
              ) : null}
            </div>
          ) : null}
          {searchable?.description && (
            <div className={styles.cardContent_row}>
              <p
                className={`
                  ${styles.cardContent_description}
                  ${localStyles.description}
                  ${isFromGlobal ? localStyles.globalSearchDescription : null}
                `}
              >
                {searchable.short_description}
              </p>
            </div>
          )}
        </div>

        {!isFromGlobal || id ? (
          <div
            className={clsx(styles.buttonWrapper, localStyles.favoriteButton)}
          >
            <FavoriteButton
              profileId={id}
              isFavorite={is_favorite}
              aligned={"top"}
              disabled={showSignUpMenu}
              setIsOpenAuthModal={setIsOpenAuthModal}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CardCurriculum;
