import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import * as reducers from "./reducers";
import { reducer as formReducer } from "redux-form";

const store = configureStore({
  reducer: combineReducers({ ...reducers, form: formReducer }),
});

export type AppDispatchType = typeof store.dispatch;
export type StateType = ReturnType<typeof store.getState>;

export default store;
