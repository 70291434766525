import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Tooltip, ClickAwayListener } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import info from "../../../assets/images/info.png";

import styles from "./styles.module.scss";

const CustomTooltipLeftSidebar = withStyles({
  tooltip: {
    color: "#000",
    fontSize: "0.9rem",
    backgroundColor: "#fdc121",
    right: 30,
  },
  popper: {
    maxWidth: "280px",
  },
  arrow: {
    fontSize: 20,
    color: "#fdc121",
    "&::before": {
      backgroundColor: "#fdc121",
    },
  },
})(Tooltip);

const CustomTooltip = withStyles({
  tooltip: {
    color: "#000",
    fontSize: "0.9rem",
    backgroundColor: "#fdc121",
    right: 0,
  },
  popper: {
    maxWidth: "280px",
  },
  arrow: {
    fontSize: 20,
    color: "#fdc121",
    "&::before": {
      backgroundColor: "#fdc121",
    },
  },
})(Tooltip);

const CustomCovidTooltip = withStyles({
  tooltip: {
    color: "#000",
    fontSize: "0.9rem",
    backgroundColor: "#fdc121",
    width: "100%",
    maxWidth: "99vw",
    padding: 5,
    margin: 0,
    bottom: "50px !important",
  },
  popper: {
    padding: 0,
  },
})(Tooltip);

const CustomCovidDetailsTooltip = withStyles({
  tooltip: {
    color: "#000",
    fontSize: "0.9rem",
    backgroundColor: "#fdc121",
    width: "100%",
    maxWidth: "none",
    padding: 5,
    margin: 0,
    bottom: "0px !important",
    right: -10,
  },
  popper: {
    padding: 0,
  },
})(Tooltip);

type PropsType = {
  isLeftSidebar?: boolean;
  title: any;
  interactive?: boolean;
  withIcon?: boolean;
  isPicture?: boolean;
  isCovidInDetails?: boolean;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
};

const TooltipUI: React.FC<PropsType> = ({
  title,
  interactive = true,
  withIcon,
  children,
  placement,
  isPicture,
  isLeftSidebar,
  isCovidInDetails,
}) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const customTitle = <span dangerouslySetInnerHTML={{ __html: title }}></span>;

  return (
    <div className={styles.wrapper}>
      <ClickAwayListener onClickAway={() => setTooltipIsOpen(false)}>
        {isCovidInDetails ? (
          <CustomCovidDetailsTooltip
            title={title}
            interactive={interactive}
            open={tooltipIsOpen}
            placement={placement || "bottom"}
            onOpen={() => setTooltipIsOpen(true)}
            onClose={() => setTooltipIsOpen(false)}
            disableHoverListener={window.innerWidth <= 1024 ? true : false}
            disableFocusListener={window.innerWidth <= 1024 ? true : false}
            disableTouchListener={window.innerWidth <= 1024 ? true : false}
            arrow
          >
            {withIcon ? (
              <InfoIcon onClick={() => setTooltipIsOpen(!tooltipIsOpen)} />
            ) : (
              <div onClick={() => setTooltipIsOpen(!tooltipIsOpen)}>
                {children}
              </div>
            )}
          </CustomCovidDetailsTooltip>
        ) : isPicture ? (
          <CustomCovidTooltip
            title={title}
            interactive={interactive}
            open={tooltipIsOpen}
            placement={(window.innerWidth > 1024 && placement) || "bottom"}
            onOpen={() => setTooltipIsOpen(true)}
            onClose={() => setTooltipIsOpen(false)}
            disableHoverListener={window.innerWidth <= 1024 ? true : false}
            disableFocusListener={window.innerWidth <= 1024 ? true : false}
            disableTouchListener={window.innerWidth <= 1024 ? true : false}
            arrow
          >
            {withIcon ? (
              <InfoIcon onClick={() => setTooltipIsOpen(!tooltipIsOpen)} />
            ) : (
              <div onClick={() => setTooltipIsOpen(!tooltipIsOpen)}>
                {children}
              </div>
            )}
          </CustomCovidTooltip>
        ) : isLeftSidebar ? (
          <CustomTooltipLeftSidebar
            title={customTitle}
            interactive={interactive}
            open={tooltipIsOpen}
            placement={placement || "right"}
            onOpen={() => setTooltipIsOpen(true)}
            onClose={() => setTooltipIsOpen(false)}
            arrow
          >
            {withIcon ? (
              <InfoIcon onClick={() => setTooltipIsOpen(!tooltipIsOpen)} />
            ) : (
              <div>{children}</div>
            )}
          </CustomTooltipLeftSidebar>
        ) : (
          <CustomTooltip
            title={customTitle}
            interactive={interactive}
            open={tooltipIsOpen}
            placement={placement || "right"}
            onOpen={() => setTooltipIsOpen(true)}
            onClose={() => setTooltipIsOpen(false)}
            disableHoverListener={window.innerWidth <= 1024 ? true : false}
            disableFocusListener={window.innerWidth <= 1024 ? true : false}
            disableTouchListener={window.innerWidth <= 1024 ? true : false}
            arrow
          >
            {withIcon ? (
              <InfoIcon onClick={() => setTooltipIsOpen(!tooltipIsOpen)} />
            ) : (
              <div onClick={() => setTooltipIsOpen(!tooltipIsOpen)}>
                {children}
              </div>
            )}
          </CustomTooltip>
        )}
      </ClickAwayListener>
    </div>
  );
};

export default TooltipUI;
