import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const getLearningResourceById = createAsyncThunk(
  "resources/get-by-id",
  async (id: string) => {
    try {
      const {
        data,
      }: {
        data: Object;
      } = await api.resources.getLearningResourceById(id);
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getResourceTypes = createAsyncThunk(
  "resources/get-by-id",
  async () => {
    try {
      const {
        data,
      }: {
        data: Array<{ id: number; title: string; type: string }>;
      } = await api.resources.getResourceTypes();
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);
