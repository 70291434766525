import { AxiosResponse } from "axios";
import { instance, SERVER_HOST, headers } from "./index";
import {
  ApiDetailsType,
  CreatePaymentMethodType,
  CreateSubscriptionType,
} from "../types/subscriptions";

export default {
  getApiDetails: async () => {
    const {
      data,
    }: AxiosResponse<{ data: ApiDetailsType }> = await instance.get(
      `${SERVER_HOST}/subscriptions/api_details`,
      headers()
    );
    return data;
  },

  cancelSubscription: async () => {
    const { data }: AxiosResponse<{ data: boolean }> = await instance.get(
      `${SERVER_HOST}/subscriptions/cancel`,
      headers()
    );
    return data;
  },

  createSubscription: async (value: CreateSubscriptionType) => {
    const { data }: AxiosResponse<{ data: boolean }> = await instance.post(
      `${SERVER_HOST}/subscriptions/create`,
      value,
      headers()
    );
    return data;
  },

  createPaymentMethod: async (value: CreatePaymentMethodType) => {
    const { data }: AxiosResponse<{ data: boolean }> = await instance.post(
      `${SERVER_HOST}/subscriptions/payment_method`,
      value,
      headers()
    );
    return data;
  },

  getTokenForWordpress: async () => {
    const { data }: AxiosResponse<{ data: any }> = await instance.get(
      `${SERVER_HOST}/users/blog/auth`,
      headers()
    );

    return data;
  },
};
