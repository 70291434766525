import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import MainLoader from "../../view/common/MainLoader/MainLoader";
import { StateType } from "../../redux/store";
import { Cookies } from "react-cookie-consent";

type PrivateRouteProps = {
  exact: boolean;
  path: string;
  component: any;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { profile } = useSelector((state: StateType) => state.user);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (profile) {
          return (
            <Suspense fallback={<MainLoader loading={true} />}>
              <Component {...props} />
            </Suspense>
          );
        }

        if (!Cookies.get("logoutAction")) {
          Cookies.set("redirectAfterLogin", window.location.pathname, {
            expires: 1,
          });
        } else {
          Cookies.remove("logoutAction");
        }

        return <Redirect to="/" />;
      }}
    />
  );
};

export default PrivateRoute;
