import { createAsyncThunk } from "@reduxjs/toolkit";
import { searchProfileType } from "../../types/searchProfiles";
import { ressetSearchProfile } from "../reducers/searchProfiles";
import api from "../../api";

export const getSearchProfiles = createAsyncThunk(
  "search_profiles/get-list",
  async () => {
    try {
      const {
        data,
      }: {
        data: Array<searchProfileType>;
      } = await api.searchProfiles.getSearchProfiles();
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getSearchProfileById = createAsyncThunk(
  "search_profiles/get-by-id",
  async (id: number, thunkAPI) => {
    try {
      const {
        data,
      }: {
        data: searchProfileType;
      } = await api.searchProfiles.getSearchProfileById(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createSearchProfile = createAsyncThunk(
  "search_profiles/create",
  async (value: any, thunkAPI) => {
    try {
      const {
        data,
      }: { data: any } = await api.searchProfiles.createSearchProfile(value);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateSearchProfile = createAsyncThunk(
  "search_profiles/update",
  async (values: { id: number; value: any }, thunkAPI) => {
    try {
      const {
        data,
      }: { data: any } = await api.searchProfiles.updateSearchProfile(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateDefaultSearchProfile = createAsyncThunk(
  "search_profiles/update-default",
  async (id: number, thunkAPI) => {
    try {
      const {
        data,
      }: { data: any } = await api.searchProfiles.updateDefaultSearchProfile(
        id
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteSearchProfile = createAsyncThunk(
  "search_profiles/delete",
  async (id: number, thunkAPI) => {
    try {
      const {
        data,
      }: { data: string } = await api.searchProfiles.deleteSearchProfile(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getMatchProfilesByTypeId = createAsyncThunk(
  "search_profiles/get-list-by-type",
  async ({
    type,
    id,
    params,
    values,
  }: {
    type: string;
    id: number;
    params?: { limit?: number; pageNumber?: number };
    values?: any;
  }) => {
    try {
      const {
        data,
      }: {
        data: Array<searchProfileType>;
      } = await api.searchProfiles.getMatchProfilesByTypeId({
        type,
        id,
        params,
        values,
      });
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getMatchResourceByTypeId = createAsyncThunk(
  "search_profiles/get-list-resources-by-type-id",
  async ({
    type,
    id,
    resource_id,
    params,
    values,
  }: {
    type: string;
    id: number;
    resource_id: number;
    params?: { limit?: number; pageNumber?: number };
    values?: any;
  }) => {
    try {
      const {
        data,
      }: {
        data: Array<searchProfileType>;
      } = await api.searchProfiles.getMatchResourceByTypeId({
        type,
        id,
        resource_id,
        params,
        values,
      });
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getHighlights = createAsyncThunk(
  "search_profiles/Highlights",
  async ({
    stopLoading = false,
    type,
  }: {
    stopLoading?: boolean;
    type: string;
  }) => {
    try {
      const {
        data,
      }: {
        data: Array<any>;
      } = await api.searchProfiles.getHighlightsAction();
      return { ...data, stopLoading, type };
    } catch (err) {
      return err.response;
    }
  }
);

export const getHighlightsSearch = createAsyncThunk(
  "search_profiles/Highlights_Search",
  async ({ search = "", type }: { search?: string; type: string }) => {
    try {
      const {
        data,
      }: {
        data: Array<any>;
      } = await api.searchProfiles.getHighlightsSearchAction({ search });
      return { ...data, type };
    } catch (err) {
      console.log(err);
      console.log("response", err.response);

      return err.response;
    }
  }
);
