import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { footerMenuList, footerMenuListMember } from "../../helpers/values";
import { StateType } from "../../redux/store";
import styles from "./styles.module.scss";
import { PLAN_SUBSCRIPTION } from "../../helpers/constants";
import { ZendeskAPI } from "react-zendesk";
import fbIcon from "../../assets/images/socialIcons/Facebook3.png";
import inIcon from "../../assets/images/socialIcons/Instagram.png";
import twIcon from "../../assets/images/socialIcons/twitter.png";
import pinIcon from "../../assets/images/socialIcons/iPinterest.png";

// const showAffiliateLinks = [
//   "/user/resource/",
//   "/user/upgrade-your-plan/",
//   "/user/teachers/",
//   "/user/teacher-requests/",
//   "/details/request/",
//   "/details/curriculums/",
//   "/details/teachers/",
// ];

const Footer: React.FC = () => {
  const { profile } = useSelector((state: StateType) => state.user);
  const location = useLocation();
  const footerContent =
    profile && profile.plan !== PLAN_SUBSCRIPTION.FREE
      ? footerMenuListMember
      : footerMenuList;

  const initialZen = (): any => {
    //      ZendeskAPI("webWidget", "hide");
    ZendeskAPI("webWidget", "setLocale", "en");
    ZendeskAPI("webWidget:on", "userEvent", function (event: any) {
      if (event.action === "Contact Form Submitted") {
        //              ZendeskAPI("webWidget", "hide");
      }
    });
  };

  const today = new Date();

  useEffect(() => {
    initialZen();
  }, []);

  const showAffiliateLinks =
    location.pathname.includes("/user/teacher-requests/") ||
    location.pathname.includes("/user/teachers/") ||
    location.pathname.includes("/user/upgrade-your-plan/") ||
    location.pathname.includes("/user/resource/") ||
    location.pathname.includes("/details/request/") ||
    location.pathname.includes("/details/curriculums/") ||
    location.pathname.includes("/details/teachers/");

  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.menu}>
          {footerContent?.map((menuItem, index) => (
            <div className={styles.menu_item} key={index}>
              <h4 className={styles.title}>{menuItem.title}</h4>
              <ul>
                {!profile && menuItem.title === "AnythingAcademic.com"
                  ? menuItem?.menuNotLogged?.map((link, linkIndex) => {
                      return (
                        <li className={styles.link} key={linkIndex}>
                          <Link to={link.url}>{link.name}</Link>
                        </li>
                      );
                    })
                  : (menuItem.menu as any[]).map((link, linkIndex) => {
                      if (
                        link.name !== "Submit a Ticket" &&
                        link.name !== "Teacher Business Resources"
                      ) {
                        return (
                          <li className={styles.link} key={linkIndex}>
                            <Link
                              onClick={() =>
                                link.url.includes("https://")
                                  ? null
                                  : window.scrollTo(0, 0)
                              }
                              to={{ pathname: link.url }}
                              target={
                                link.url.includes("https://")
                                  ? "_blank"
                                  : "_self"
                              }
                            >
                              {link.name}
                            </Link>
                          </li>
                        );
                      } else if (link.name === "Teacher Business Resources") {
                        return (
                          <li className={styles.link} key={linkIndex}>
                            <Link
                              onClick={() =>
                                link.url.includes("https://")
                                  ? null
                                  : window.scrollTo(0, 0)
                              }
                              to={
                                profile?.plan === PLAN_SUBSCRIPTION.FREE
                                  ? `/user/upgrade-your-plan/${
                                      (profile as any)?.default_search_id
                                    }`
                                  : {
                                      pathname: link.url,
                                    }
                              }
                              target={
                                profile?.plan === PLAN_SUBSCRIPTION.FREE
                                  ? "_self"
                                  : "_blank"
                              }
                            >
                              {link.name}
                            </Link>
                          </li>
                        );
                      } else {
                        return (
                          <li className={styles.link} key={linkIndex}>
                            <Link
                              to={link.url}
                              onClick={() => {
                                ZendeskAPI("webWidget", "show");
                                ZendeskAPI("webWidget", "open");
                              }}
                            >
                              {link.name}
                            </Link>
                          </li>
                        );
                      }
                    })}
              </ul>
            </div>
          ))}
        </div>

        <div className={styles.copy}>
          <div>
            &copy; {today.getFullYear()} Anything Academic Inc.
            {showAffiliateLinks ? (
              <div className={styles.affiliateText}>
                FTC: Affiliate links used on this site.
              </div>
            ) : null}
          </div>
          <ul>
            <li>
              <a
                href={"https://twitter.com/anythingacademic"}
                target="_blank"
                className={styles.twitter}
              >
                <img src={twIcon} alt="twitter" />
              </a>
            </li>
            <li>
              <a
                href={"https://www.pinterest.com/AnythingAcademic"}
                target="_blank"
                className={styles.pinterest}
              >
                <img src={pinIcon} alt="pinterest" />
              </a>
            </li>
            <li>
              <a
                href={"https://www.facebook.com/AnythingAcademic"}
                target="_blank"
                className={styles.facebook}
              >
                <img src={fbIcon} alt="facebook" />
              </a>
            </li>
            <li>
              <a
                href={"https://www.instagram.com/anything.academic/"}
                target="_blank"
                className={styles.instagram}
              >
                <img src={inIcon} alt="instagram" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
