import { createSlice } from "@reduxjs/toolkit";
import { InitialSubjectCheckType } from "../../types/subjects";

const initialState: InitialSubjectCheckType = {
  subjects: {},
};

const subjectsCheckSlice = createSlice({
  name: "subjectsCheck",
  initialState,
  reducers: {
    setStateSubjects(state, { payload }) {
      return {
        ...state,
        subjects: { ...state.subjects, ...payload.data },
      };
    },
    resetStateSubjects(state) {
      return {
        ...state,
        subjects: {},
      };
    },
  },
});

export const {
  setStateSubjects,
  resetStateSubjects,
} = subjectsCheckSlice.actions;

export default subjectsCheckSlice.reducer;
