export const shortLastName = (firstName?: string, lastName?: string) => {
  return `${firstName || ""} ${lastName ? lastName[0] + "." : ""}`;
};

export const planSubscription = (firstWord?: string, secondWord?: string) => {
  const newFirstWord = firstWord
    ? firstWord[0].toUpperCase() + firstWord.slice(1)
    : "";
  const newLastWord = secondWord
    ? secondWord[0].toUpperCase() + secondWord.slice(1)
    : "";
  return `${newFirstWord} ${newLastWord}`;
};
