import { AxiosResponse } from "axios";
import { instance, SERVER_HOST, headers } from "./index";

export default {
  getLearningResourceById: async (id: string) => {
    const { data }: AxiosResponse<{ data: Object }> = await instance.get(
      `${SERVER_HOST}/learning_resources/${id}`,
      headers()
    );
    return data;
  },

  getResourceTypes: async () => {
    const {
      data,
    }: AxiosResponse<{
      data: Array<{ id: number; title: string; type: string }>;
    }> = await instance.get(`${SERVER_HOST}/resource_types`, headers());
    return data;
  },
};
