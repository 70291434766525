import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  WrappedFieldInputProps,
  WrappedFieldMetaProps,
} from "redux-form/lib/Field";
import MenuItem from "@material-ui/core/MenuItem";
import {
  InputLabel,
  Select,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Arrow from "../../../assets/images/dropdownArrow.png";
import clsx from "clsx";

import styles from "./styles.module.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: { margin: 0, width: "100%", minWidth: 150, maxWidth: 500 },
    root: {
      zIndex: 1303,
      backgroundColor: "#fff",
      root: {
        "& .MuiTextField-root": {
          margin: theme.spacing(1),
          width: "25ch",
        },
      },
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

type PropsType = {
  input?: WrappedFieldInputProps;
  meta?: WrappedFieldMetaProps;
  defaultValue?: string | number;
  initialValue?: string | number;
  name?: string;
  label?: string;
  backendError?: string;
  onChange?: (value: string) => void;
  options: Array<{ value: string | number; text: string }>;
  helperText?: string;
  error?: boolean;
  size?: string;
  handleBlur?: (value: string) => void;
  inForm?: boolean;
  mobile?: boolean;
  noMargin?: boolean;
  rightMargin?: boolean;
  nullIsAny?: boolean;
};

const SimpleSelect: React.FC<PropsType> = ({
  label,
  name,
  defaultValue,
  options,
  onChange,
  input,
  meta,
  backendError,
  helperText,
  error = false,
  size,
  handleBlur,
  inForm,
  mobile,
  noMargin,
  rightMargin = false,
  nullIsAny,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState("Please Select...");
  const location = useLocation();
  const handleChangeHandler = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    handleBlur && handleBlur(event.target.value as string);
    setValue(event.target.value as string);
    input && input.onChange(event.target.value as string);
    onChange && onChange(event.target.value as string);
  };
  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue as string);
      onChange && onChange(defaultValue as string);
      input && input.onChange(defaultValue.toString());
    }
  }, [defaultValue]);

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.small]: size === "small",
        [styles.inForm]: size === "inForm",
        [styles.inForm]: inForm,
        [styles.mobile]: mobile,
        [styles.noMargin]: noMargin
      })}
    >
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel id="demo-simple-select-filled-label">
          {input && !input.value && "Please Select..."}
        </InputLabel>
        <Select
          name={name || (input && input.name)}
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={(input && input.value) || value}
          onChange={handleChangeHandler}
          defaultValue={defaultValue}
          className={styles.select}
          IconComponent={() => (
            <img className={styles.array} src={Arrow} alt="" />
          )}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {options?.map((option, index) => {
            if (option.text === null && nullIsAny) {
              return (
                <MenuItem key={index} value={option.value}>
                  Any
                </MenuItem>
              );
            }
            return (
              <MenuItem key={index} value={option.value}>
                {option.text}
              </MenuItem>
            );
          })}
        </Select>
        {false && <FormHelperText>Required</FormHelperText>}
        {error && (
          <>
            <div className={styles.errorWrapper}>
              <p className={styles.error}>
                {meta && meta.touched && (meta.error || backendError)}
              </p>
            </div>
          </>
        )}
      </FormControl>
      {error && (
        <div className={styles.errorLabelWrapper}>
          <p className={styles.errorLabel}>!</p>
        </div>
      )}
    </div>
  );
};

export default SimpleSelect;
