import { createAsyncThunk } from "@reduxjs/toolkit";
import { SchoolType, CreateSchoolType } from "../../types/schools";
import api from "../../api";

export const getSchools = createAsyncThunk("schools/get-list", async () => {
  try {
    const {
      data,
    }: { data: Array<SchoolType> } = await api.schools.getSchools();
    return data;
  } catch (err) {
    return err.response.data;
  }
});

export const getSchoolById = createAsyncThunk(
  "schools/get-by-id",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: SchoolType } = await api.schools.getSchoolById(
        id
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createSchool = createAsyncThunk(
  "schools/create",
  async (value: CreateSchoolType, thunkAPI) => {
    try {
      const { data }: { data: SchoolType } = await api.schools.createSchool(
        value
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const uodateSchool = createAsyncThunk(
  "schools/update",
  async (values: { id: number; value: SchoolType }, thunkAPI) => {
    try {
      const { data }: { data: SchoolType } = await api.schools.updateSchool(
        values
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteSchool = createAsyncThunk(
  "schools/delete",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: string } = await api.schools.deleteSchool(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
