import React from "react";
import {Helmet} from "react-helmet";

const MainPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>About Anything Academic</title>
      </Helmet>
      <h1>About us</h1>
    </>
  );
};

export default MainPage;
