import { AxiosResponse } from "axios";
import { instance, SERVER_HOST } from "./index";
import { SchoolType, CreateSchoolType } from "../types/schools";

export default {
  getSchools: async () => {
    const {
      data,
    }: AxiosResponse<{ data: Array<SchoolType> }> = await instance.get(
      `${SERVER_HOST}/schools`
    );
    return data;
  },

  getSchoolById: async (id: number) => {
    const { data }: AxiosResponse<{ data: SchoolType }> = await instance.get(
      `${SERVER_HOST}/schools/${id}`
    );
    return data;
  },

  createSchool: async (value: CreateSchoolType) => {
    const { data }: AxiosResponse<{ data: SchoolType }> = await instance.post(
      `${SERVER_HOST}/schools`,
      value
    );
    return data;
  },

  updateSchool: async ({ id, value }: { id: number; value: SchoolType }) => {
    const { data }: AxiosResponse<{ data: SchoolType }> = await instance.put(
      `${SERVER_HOST}/schools/${id}`,
      value
    );
    return data;
  },

  deleteSchool: async (id: number) => {
    const { data }: AxiosResponse<{ data: string }> = await instance.delete(
      `${SERVER_HOST}/schools/${id}`
    );
    return data;
  },
};
