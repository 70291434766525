import { createSlice } from "@reduxjs/toolkit";
import {
  getSearchProfiles,
  getSearchProfileById,
  createSearchProfile,
  updateSearchProfile,
  updateDefaultSearchProfile,
  deleteSearchProfile,
  getMatchProfilesByTypeId,
  getMatchResourceByTypeId,
  getHighlights,
  getHighlightsSearch,
} from "../actions/searchProfiles";

import { InitialsearchProfileType } from "../../types/searchProfiles";
import { getRandomElements } from "../../helpers/functions";

const initialState: InitialsearchProfileType = {
  searchProfile: null,
  searchProfiles: null,
  searchProfilesAll: null,
  matchProfiles: null,
  memberSwitch: false,
  activeTabReducer: -1,
  isLoading: false,
  errors: null,
  highlightsMatchProfiles: {
    resourcesMatches: [],
    usersMatches: [],
    resourcesHighlights: [],
    usersHighlights: [],
    is_search: false
  }
};

const searchProfilesSlice = createSlice({
  name: "searchProfiles",
  initialState,
  reducers: {
    ressetSearchProfile() {
      return initialState;
    },
    setStateValue(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
    cleanMatches(state) {
      return {
        ...state,
        matchProfiles: null,
        isLoading: true,
      };
    },
  },
  extraReducers: {
    [getSearchProfiles.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getSearchProfiles.fulfilled.toString()]: (state, { payload }) => {
      // Active and inactive searchProfiles
      state.searchProfilesAll = payload;

      state.searchProfiles = payload;
      // Only active and Teacher searchProfiles
      state.searchProfiles = Array.isArray(state.searchProfiles)
        ? state.searchProfiles?.filter(
            (item) =>
              item.is_active === 1 || item.searchable_type?.includes("Teacher")
          )
        : null;

      state.isLoading = false;
    },
    [getSearchProfiles.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [getSearchProfileById.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getSearchProfileById.fulfilled.toString()]: (state, { payload }) => {
      state.searchProfile = payload;
      state.isLoading = false;
    },
    [getSearchProfileById.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [createSearchProfile.pending.toString()]: (state) => {
      state.isLoading = true;
      // state.errors = null;
    },
    [createSearchProfile.fulfilled.toString()]: (state, { payload }) => {
      payload.access_token &&
        localStorage.setItem("token", payload.access_token);
      payload.id && localStorage.setItem("id", payload.id);
      state.searchProfile = payload;
      state.isLoading = false;
      state.errors = null;
    },
    [createSearchProfile.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [updateSearchProfile.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [updateSearchProfile.fulfilled.toString()]: (state, { payload }) => {
      state.searchProfile = payload;
      state.isLoading = false;
    },
    [updateSearchProfile.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [updateDefaultSearchProfile.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [updateDefaultSearchProfile.fulfilled.toString()]: (state, { payload }) => {
      state.searchProfile = payload;
      state.isLoading = false;
    },
    [updateDefaultSearchProfile.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [deleteSearchProfile.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [deleteSearchProfile.fulfilled.toString()]: (state, { payload }) => {
      state.searchProfile = null;
      state.isLoading = false;
    },
    [deleteSearchProfile.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [getMatchProfilesByTypeId.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getMatchProfilesByTypeId.fulfilled.toString()]: (state, { payload }) => {
      state.matchProfiles = payload;
      state.isLoading = false;
    },
    [getMatchProfilesByTypeId.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [getMatchResourceByTypeId.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getMatchResourceByTypeId.fulfilled.toString()]: (state, { payload }) => {
      state.matchProfiles = payload;
      state.isLoading = false;
    },
    [getMatchResourceByTypeId.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [getHighlights.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getHighlights.fulfilled.toString()]: (state, { payload }) => {

      // Sort the highlights by descending weight
      let lrHighlights: any[] = [];
      if (payload.learning_resources && payload.learning_resources.length > 0) {
        lrHighlights = payload.learning_resources.sort((lr1: any, lr2: any) => lr1.highlight_weight >= lr2.highlight_weight ? -1 : 1);
      }

      let proHighlights: any[] = [];
      if (payload.teachers && payload.teachers.length > 0) {
        proHighlights = payload.teachers.sort((o1: any, o2: any) => o1.highlight_weight >= o2.highlight_weight ? -1 : 1);
      }

      // Pull a random selection of 4 highlights, starting with the case where each type has more than 2 highlights
      let proCount = proHighlights.length < 2 ? proHighlights.length : 2;
      let lrCount = lrHighlights.length < 2 ? lrHighlights.length : 2;

      // Handle cases where one has less than 2 and the other has more
      if (proCount < 2 && lrHighlights.length > 2)
        lrCount = lrHighlights.length + proCount > 4 ? 4 - proCount : lrHighlights.length;
      if (lrCount < 2 && proHighlights.length > 2)
        proCount = proHighlights.length + lrCount > 4 ? 4 - lrCount : proHighlights.length;
      

      proHighlights = getRandomElements(proHighlights, proCount);
      lrHighlights = getRandomElements(lrHighlights, lrCount);

      state.highlightsMatchProfiles.resourcesHighlights = lrHighlights;
      state.highlightsMatchProfiles.usersHighlights = proHighlights;

      state.highlightsMatchProfiles.is_search = false;
      
      if(payload.stopLoading){
        state.isLoading = false;
      }
    },
    [getHighlights.rejected.toString()]: (state, { payload }) => {
      state.errors = payload?.errors;
      if(payload.stopLoading){
        state.isLoading = false;
      }
    },

    [getHighlightsSearch.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getHighlightsSearch.fulfilled.toString()]: (state, { payload }) => {
      state.highlightsMatchProfiles.resourcesHighlights = [];
      state.highlightsMatchProfiles.usersHighlights = []

      if(payload?.type === 'resource'){
         state.highlightsMatchProfiles.resourcesMatches = payload.learning_resources;
         state.highlightsMatchProfiles.usersMatches = [];
      }
      if(payload?.type === 'teacher'){
        state.highlightsMatchProfiles.resourcesMatches = [];
        state.highlightsMatchProfiles.usersMatches = payload.teachers;
      }
      if(payload?.type === 'all'){
        state.highlightsMatchProfiles.resourcesMatches = payload.learning_resources;
        state.highlightsMatchProfiles.usersMatches = payload.teachers;
      }

      state.highlightsMatchProfiles.resourcesMatches = state.highlightsMatchProfiles.resourcesMatches.filter(o => o.score >= 1.5);
      state.highlightsMatchProfiles.usersMatches =  state.highlightsMatchProfiles.usersMatches.filter(o => o.score >= 1.5);

      state.highlightsMatchProfiles.is_search = true;
      state.isLoading = false;
    },
    [getHighlightsSearch.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
  },
});

export const {
  ressetSearchProfile,
  setStateValue,
  cleanMatches,
} = searchProfilesSlice.actions;

export default searchProfilesSlice.reducer;
