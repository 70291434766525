import { AxiosResponse } from "axios";
import { instance, SERVER_HOST, headers } from "./index";
import { MessageType, ChatType, CreateMessageType } from "../types/messages";

export default {
  getChats: async (type: string) => {
    const {
      data,
    }: AxiosResponse<{ data: Array<ChatType> }> = await instance.get(
      `${SERVER_HOST}/messages/chats/${type}`,
      headers()
    );
    return data;
  },

  deleteChat: async (chat_id: number) => {
    const { data }: AxiosResponse<{ data: string }> = await instance.delete(
      `${SERVER_HOST}/messages/chat/${chat_id}`,
      headers()
    );
    return data;
  },

  getMessages: async (chat_id: number) => {
    const {
      data,
    }: AxiosResponse<{ data: Array<MessageType> }> = await instance.get(
      `${SERVER_HOST}/messages/chat/${chat_id}`,
      headers()
    );
    return data;
  },

  createMessage: async (values: CreateMessageType) => {
    const { data }: AxiosResponse<{ data: MessageType }> = await instance.post(
      `${SERVER_HOST}/messages`,
      values,
      headers()
    );
    return data;
  },

  getMessageById: async (id: number) => {
    const { data }: AxiosResponse<{ data: MessageType }> = await instance.get(
      `${SERVER_HOST}/messages/${id}`,
      headers()
    );
    return data;
  },

  updateMessage: async ({ id, value }: { id: number; value: MessageType }) => {
    const { data }: AxiosResponse<{ data: MessageType }> = await instance.put(
      `${SERVER_HOST}/messages/${id}`,
      value,
      headers()
    );
    return data;
  },

  deleteMessage: async (id: number) => {
    const { data }: AxiosResponse<{ data: string }> = await instance.delete(
      `${SERVER_HOST}/messages/${id}`,
      headers()
    );
    return data;
  },

  allChatMessagesRead: async (id: number) => {
    const { data }: AxiosResponse<{ data: string }> = await instance.put(
      `${SERVER_HOST}/messages/chat/${id}`,
      {},
      headers()
    );
    return data;
  },
};
