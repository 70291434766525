import { AxiosResponse } from "axios";
import { instance, SERVER_HOST } from "./index";
import { SubjectType, CreateSubjectType } from "../types/subjects";

export default {
  getSubjects: async () => {
    const {
      data,
    }: AxiosResponse<{ data: Array<SubjectType> }> = await instance.get(
      `${SERVER_HOST}/subjects`
    );
    return data;
  },

  getSubjectById: async (id: number) => {
    const { data }: AxiosResponse<{ data: SubjectType }> = await instance.get(
      `${SERVER_HOST}/subjects/${id}`
    );
    return data;
  },

  createSubject: async (value: CreateSubjectType) => {
    const { data }: AxiosResponse<{ data: SubjectType }> = await instance.post(
      `${SERVER_HOST}/subjects`,
      value
    );
    return data;
  },

  updateSubject: async ({ id, value }: { id: number; value: SubjectType }) => {
    const { data }: AxiosResponse<{ data: SubjectType }> = await instance.put(
      `${SERVER_HOST}/subjects/${id}`,
      value
    );
    return data;
  },

  deleteSubject: async (id: number) => {
    const { data }: AxiosResponse<{ data: string }> = await instance.delete(
      `${SERVER_HOST}/subjects/${id}`
    );
    return data;
  },
};
