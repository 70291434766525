import { createAsyncThunk } from "@reduxjs/toolkit";
import { SignUpType } from "../../types/auth";
import { TeacherType } from "../../types/teachers";
import api from "../../api";

export const getTeachers = createAsyncThunk("teachers/get-list", async () => {
  try {
    const {
      data,
    }: { data: Array<TeacherType> } = await api.teachers.getTeachers();
    return data;
  } catch (err) {
    return err.response.data;
  }
});

export const getTeacherById = createAsyncThunk(
  "teachers/get-by-id",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: TeacherType } = await api.teachers.getTeacherById(
        id
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createTeacher = createAsyncThunk(
  "teachers/create",
  async (value: any, thunkAPI) => {
    try {
      const { data }: { data: any } = await api.teachers.createTeacher(
        value
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateTeacher = createAsyncThunk(
  "teachers/update",
  async (values: { id: number; value: any }, thunkAPI) => {
    try {
      const { data }: { data: any } = await api.teachers.updateTeacher(
        values
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteTeacher = createAsyncThunk(
  "teachers/delete",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: string } = await api.teachers.deleteTeacher(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
