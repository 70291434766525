import { createSlice } from "@reduxjs/toolkit";
import { InitianlZipsType } from "../../types/zips";
import { getZips } from "../actions/zips";

const initialState: InitianlZipsType = {
  zips: null,
  isLoading: false,
  errors: null,
};

const zipsSlice = createSlice({
  name: "zips",
  initialState,
  reducers: {},
  extraReducers: {
    [getZips.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getZips.fulfilled.toString()]: (state, { payload }) => {
      state.zips = payload.data;
      state.isLoading = false;
      state.errors = payload.data?.length === 0;
    },
    [getZips.rejected.toString()]: (state, { payload }) => {
      state.errors = payload.errors;
      state.isLoading = false;
    },
  },
});

export default zipsSlice.reducer;
