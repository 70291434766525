import axios from "axios";
import auth from "./auth";
import user from "./user";
import parents from "./parents";
import teachers from "./teachers";
import users from "./users";
import messages from "./messages";
import curriculums from "./curriculums";
import requests from "./requests";
import schools from "./schools";
import subjects from "./subjects";
import subscriptions from "./subscriptions";
import plans from "./plans";
import zips from "./zips";
import multiselects from "./multiselects";
import reviews from "./reviews";
import searchProfiles from "./searchProfiles";
import resources from "./resources";
import timezones from "./timezones";
import favorites from "./favorites";
import { message } from "../helpers/notifications";

export const SERVER_HOST = `${process.env.REACT_APP_SERVER_HOST}/api/v1`;

export const setHeader = () => {
  let token;

  if (typeof window !== "undefined") {
    token = localStorage.getItem("token");
  }

  return {
    Accept: "application/json",
    Authorization: token && `Bearer ${token}`,
  };
};

export const instance = axios.create({
  baseURL: "",
  headers: setHeader(),
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.request.status === 401 &&
      !window.location.href.includes("/auth") &&
      !window.location.href.includes("/get-started") &&
      window.location.pathname !== "/search-profile-form"
    ) {
      window.location.href = `${window.location.origin}/auth/login`;
      localStorage.removeItem("filtersList");
    }

    if (error.request.status === 500 || error.request.status === 429) {
      message.error();

      if (!localStorage.getItem("token")) {
        setTimeout(() => {
          window.location.href = `${window.location.origin}`;
        }, 2000);
      }
    }

    if (error.request.status === 404) {
      const respData = JSON.parse(error.request.response);
      if (respData.message === "Search Profile not found") {
        window.location.href = "/page-not-available";
      }
    }

    if (!navigator.onLine) {
      message.error("No network access");
      window.location.href = `${window.location.origin}`;
    }

    return Promise.reject(error);
  }
);

export const headers = () => ({
  headers: setHeader(),
});

export default {
  auth,
  user,
  parents,
  teachers,
  users,
  messages,
  curriculums,
  requests,
  schools,
  subjects,
  subscriptions,
  plans,
  zips,
  multiselects,
  reviews,
  searchProfiles,
  resources,
  timezones,
  favorites,
};
