import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import "normalize.css";
import { ThemeProvider } from "@material-ui/core/styles";

import App from "./routes";
import store from "./redux/store";
import { theme } from "./assets/styles/theme";

import "./index.scss";
import * as serviceWorker from "./serviceWorker";

declare global {
  interface Window {
      LeadDyno:any;
  }
}

const createHistory = require("history").createBrowserHistory;
export const history = createHistory();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <App />
        </Router>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
