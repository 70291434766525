import { instance, SERVER_HOST, headers } from "./index";
import { ProfileType, UpdateProfileType } from "../types/user";

export default {
  getProfile: async () => {
    const { data } = await instance.get(
      `${SERVER_HOST}/users/profile`,
      headers()
    );
    return data;
  },

  updateProfile: async (value: UpdateProfileType | {}) => {
    const { data } = await instance.put(
      `${SERVER_HOST}/users/profile`,
      value,
      headers()
    );
    return data;
  },

  signOut: async () => {
    const { data } = await instance.get(
      `${SERVER_HOST}/users/logout`,
      headers()
    );
    return data;
  },
};
