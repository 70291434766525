import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

import LeftSidebar from "../components/LeftSidebar/LeftSidebar";
import { StateType } from "../../redux/store";

import styles from "./styles.module.scss";

const Content: React.FC = ({ children }) => {
  const { profile } = useSelector((state: StateType) => state.user);
  const location = useLocation();

  const showLeftSidebar =
    (profile && location.pathname.includes("/user/teacher-requests")) ||
    (profile && location.pathname.includes("/user/teachers")) ||
    (profile && location.pathname.includes("/user/upgrade-your-plan")) ||
    (profile && location.pathname.includes("/global-search")) ||
    (profile && location.pathname.includes("/user/resource"));

  const isFullWidthScreen: boolean = 
    location.pathname === "/home" ||
    location.pathname.includes("/user/search-profile") ||
    location.pathname.includes("/user/teacher-profile") ||
    location.pathname == "/business-memberships" ||
    location.pathname.includes("/user/subscription") ||
    location.pathname.includes("/user/teachers") ||
    location.pathname.includes("/user/resource") ||
    location.pathname.includes("/global-search") ||
    location.pathname.includes("/search-profile-form");

  return (
    <div
      className={clsx(styles.wrap_content, {
        [styles.content_signup]: location.pathname.includes("/auth/sign-up"),
      })}
    >
      <main
        className={clsx(styles.content, {
          [styles.fullWidth]: isFullWidthScreen,
        })}
      >
        {showLeftSidebar && (
          <div className={styles.leftSidebarWrapper}>
            <LeftSidebar />
          </div>
        )}

        <div className={styles.rightSidebarWrapper}>{children}</div>
      </main>
    </div>
  );
};

export default Content;
