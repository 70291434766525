import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const getTimezones = createAsyncThunk("timezones/get-list", async () => {
  try {
    const {
      data,
    }: { data: Array<string> } = await api.timezones.getTimezones();
    return data;
  } catch (err) {
    return err.response.data;
  }
});
