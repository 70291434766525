import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Form,
  reduxForm,
  InjectedFormProps,
  reset,
  Field,
  FormSection,
  getFormValues,
} from "redux-form";

import clsx from "clsx";


import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaSitekey } from "../../../helpers/constants";


import Input from "../../common/Input/Input";
import Checkbox from "../../common/Checkbox/Checkbox";
import Selecter from "../../common/Selecter/Selecter";
import Button from "../../common/Button/Button";
import RadioButton from "../../common/Radiobutton/Radiobutton";

import styles from "./styles.module.scss";
import { StateType } from "../../../redux/store";

import { setError } from "../../../redux/reducers/auth";
import { signUp } from "../../../redux/actions/auth";


import {
  required,
  email,
  password,
  zip,
  maxLength30,
  maxLength255,
  valid_zip,
} from "../../../helpers/validate";
import { getZips } from "../../../redux/actions/zips";
import SelecterSearch from "../../common/SelecterSearch/SelecterSearch";
import { countries } from "../../../helpers/countries";
import { createSearchProfile } from "../../../redux/actions/searchProfiles";
import { getProfile, sendLeadDyno } from "../../../redux/actions/user";


interface StateProps {
  formValues: any;
}

interface Props extends StateProps {

}

const HomeSignUpForm: React.FC<Props & InjectedFormProps<{}, Props, string>> = ({
  handleSubmit,
  formValues,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { zips, errors } = useSelector((state: StateType) => state.zips);
  const form = useSelector((state: StateType) => state.form);
  const auth = useSelector((state: StateType) => state.auth);
  const token = localStorage.getItem("token");

  const searchProfiles = useSelector((state: StateType) => state.searchProfiles);
  const errorsSearchProfiles = searchProfiles?.errors;

  const [formSubmitting, setFormSubmitting] = useState(true);

  const onChangeZip = async (value: string) => {
    value && value.length === 5 && dispatch(getZips(value));
  };
  const errorValidZip =
    ((zips && !zips.length && formValues && !!formValues.zip) || errors) &&
    "Zip Code Required for USA";

  const isZipCountryValid = () => {
    return formValues?.country === "USA"
      ? !!form?.getStartedPage?.submitFailed && !formValues?.zip
      : false;
  };

  const onRegisterAndNext = () => {
    handleSubmit(async (values: any) => {
      setFormSubmitting(true);
      dispatch(
        createSearchProfile({ ...values })
        //@ts-ignore
      ).then((e) => {
        if (e?.payload?.errors?.email && !token) {
          return;
        }
        let newEmail = (values as any).email;
        if (newEmail) {
          dispatch(sendLeadDyno(newEmail));
        }
        //@ts-ignore
        dispatch(getProfile()).then((profileData) => {
          let redirectTo = localStorage.getItem("redirectTo");
          if (redirectTo) {
            history.push(redirectTo);
            localStorage.removeItem("redirectTo");
          } else {
            history.push(
              `/user/search-profile/${profileData?.payload?.default_search_id}?step=1`
            );
          }
        });
      });
    })();
  };

  return (
    <div className={styles.wrapper}>

      <div className={styles.title}>
        Take a Test Drive and Start Searching For Free Now
      </div>

      <div className={styles.section}>
        <div className={styles.signUpWrapper}>
          <div
            className={clsx(styles.stateZipCountryTimeZoneWrapper, {
              [styles.errorBorder]: isZipCountryValid(),
            })}
          >
            <div className={styles.countryWrapper}>
              <Field
                component={SelecterSearch}
                name="country"
                placeholder="Country"
                defaultValue="USA"
                options={countries.map((country: string) => ({
                  value: country,
                  text: country,
                }))}
                validate={[required]}
              />
            </div>
            <span className={styles.separator} />
            <div className={styles.zipWrapper}>
              <Field
                variant="filled"
                component={Input}
                name="zip"
                placeholder={"Enter ZIP Code"}
                validate={
                  formValues?.country === "USA" || !formValues?.country
                    ? [zip, valid_zip, required]
                    : []
                }
                backendError={
                  formValues?.country === "USA" || !formValues?.country
                    ? errorValidZip
                    : undefined
                }
                handleChange={onChangeZip}
                disabled={
                  formValues?.country !== "USA" && formValues?.country !== null
                }
                defaultValue={
                  formValues?.country === "USA" || !formValues?.country
                    ? undefined
                    : "-"
                }
              />
              <Field
                component={Checkbox}
                name="valid_zip"
                validate={
                  formValues?.country === "USA" || !formValues?.country
                    ? [required]
                    : []
                }
                initialValue={errors ? "0" : "1"}
                hidden
              />
            </div>
          </div>
          <div className={styles.formLine}>
            <Field
              variant="filled"
              component={Input}
              name="first_name"
              placeholder="First Name"
              validate={[required, maxLength30]}
              backendError={auth?.errors?.first_name?.toString()}
            />

            <Field
              variant="filled"
              component={Input}
              name="last_name"
              placeholder="Last Name"
              validate={[required, maxLength30]}
              backendError={auth?.errors?.last_name?.toString()}
            />
          </div>
          <div className={styles.formLine}>
            <Field
              variant="filled"
              component={Input}
              name="email"
              placeholder="Email address"
              type="email"
              validate={[required, email, maxLength255]}
              backendError={auth?.errors?.email?.toString()}
            />
            <Field
              variant="filled"
              component={Input}
              name="password"
              placeholder="Password"
              passwordInSafariAutofocusFix
              validate={[required, password, maxLength255]}
              backendError={auth?.errors?.password?.toString()}
            />
          </div>
          <div className={styles.checkboxesWrapper}>
            <Field
              component={Checkbox}
              name="termsAndCon"
              label={
                <p className={styles.termsAndPrivacy}>
                  I agree to the{" "}
                  <a href={"/terms-of-use"} target="_blank">terms of use</a>
                  {" "} and {" "}
                  <a href={"/privacy-policy"} target="_blank">privacy policy</a>
                  {" "}of Anything Academic.
                </p>
              }
              validate={[required]}
              required
              onChange={() => setFormSubmitting(false)}
            />

            <Field
              component={Checkbox}
              name="is_newsletter"
              label={
                <p className={styles.termsAndPrivacy}>
                  I'd like to receive great updates like discounts, contests,
                  and really helpful information!
                </p>
              }
            />
          </div>
        </div>
      </div>
      <div>
        <div className={styles.yellowbutton}>
          <Button
            customColor="yellow"
            customSize="medium"
            onClick={onRegisterAndNext}
            disabled={formSubmitting}
          >
            Create Free Profile
          </Button>
        </div>
        {errorsSearchProfiles && (
          <span className="error">
            {errorsSearchProfiles.email?.toString()}
          </span>
        )}
      </div>
    </div>

  );
};

const mapStateToProps = (state: StateType): StateProps => ({
  formValues: getFormValues("homeSignUpForm")(state),
});

export default connect(mapStateToProps)(
  reduxForm<{}, Props>({
    form: "homeSignUpForm",
    destroyOnUnmount: false,
  })(HomeSignUpForm)
);
