import { AxiosResponse } from "axios";
import { instance, SERVER_HOST, headers } from "./index";
import { searchProfileType } from "../types/searchProfiles";

export default {
  getSearchProfiles: async () => {
    const {
      data,
    }: AxiosResponse<{ data: Array<searchProfileType> }> = await instance.get(
      `${SERVER_HOST}/search_profiles`,
      headers()
    );
    return data;
  },

  getSearchProfileById: async (id: number) => {
    const {
      data,
    }: AxiosResponse<{ data: searchProfileType }> = await instance.get(
      `${SERVER_HOST}/search_profiles/${id}`,
      headers()
    );
    return data;
  },

  createSearchProfile: async (value: any) => {
    const {
      data,
    }: AxiosResponse<{ data: searchProfileType }> = await instance.post(
      `${SERVER_HOST}/search_profiles`,
      value,
      headers()
    );
    return data;
  },

  updateSearchProfile: async ({ id, value }: { id: number; value: any }) => {
    const {
      data,
    }: AxiosResponse<{ data: searchProfileType }> = await instance.put(
      `${SERVER_HOST}/search_profiles/${id}`,
      value,
      headers()
    );
    return data;
  },

  updateDefaultSearchProfile: async (id: number) => {
    const {
      data,
    }: AxiosResponse<{ data: searchProfileType }> = await instance.put(
      `${SERVER_HOST}/search_profiles/default/${id}`,
      [],
      headers()
    );
    return data;
  },

  deleteSearchProfile: async (id: number) => {
    const { data }: AxiosResponse<{ data: string }> = await instance.delete(
      `${SERVER_HOST}/search_profiles/${id}`,
      headers()
    );
    return data;
  },

  getMatchProfilesByTypeId: async ({
    type,
    id,
    params,
    values,
  }: {
    type: string;
    id: number;
    params?: { limit?: number; pageNumber?: number };
    values?: any;
  }) => {
    const { data }: AxiosResponse<{ data: Array<any> }> = await instance.post(
      `${SERVER_HOST}/search_profiles/match/${type}/${id}?skip=${
        (params?.pageNumber ? params.pageNumber - 1 : 0) * (params?.limit || 5)
      }&limit=${params?.limit || 5}`,
      values,
      headers()
    );
    return data;
  },

  getMatchResourceByTypeId: async ({
    type,
    id,
    resource_id,
    params,
    values,
  }: {
    type: string;
    id: number;
    resource_id: number;
    params?: { limit?: number; pageNumber?: number };
    values?: any;
  }) => {
    const { data }: AxiosResponse<{ data: Array<any> }> = await instance.post(
      `${SERVER_HOST}/search_profiles/match/${type}/${id}/${resource_id}?skip=${
        (params?.pageNumber ? params.pageNumber - 1 : 0) * (params?.limit || 5)
      }&limit=${params?.limit || 5}`,
      values,
      headers()
    );
    return data;
  },

  getHighlightsAction: async () => {
    const { data }: AxiosResponse<{ data: Array<any> }> = await instance.get(`${SERVER_HOST}/search/highlights`,
      headers()
    );
    return data;
  },

  getHighlightsSearchAction: async (
    {
      search,
    }: {
      search: string,
    }
    ) => {
    const { data }: AxiosResponse<{ data: Array<any> }> = await instance.get(`${SERVER_HOST}/search?search=${search}`,
      headers()
    );
    return data;
  },
  
};
