import { createSlice } from "@reduxjs/toolkit";

import { MultiSelectItemsInitialType } from "../../types/multiselects";

const initialState: MultiSelectItemsInitialType = {
  isLoading: false,
  errors: null,
};

const multiselectsSlice = createSlice({
  name: "multiselects",
  initialState,
  reducers: {
    setStateValue(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
  },
});

export const { setStateValue } = multiselectsSlice.actions;

export default multiselectsSlice.reducer;
