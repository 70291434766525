import { createSlice } from "@reduxjs/toolkit";
import { InitialTimezonesType } from "../../types/timezones";
import { getTimezones } from "../actions/timezones";

const initialState: InitialTimezonesType = {
  timezones: null,
  isLoading: false,
  errors: null,
};

const timezones = createSlice({
  name: "timezones",
  initialState,
  reducers: {},
  extraReducers: {
    [getTimezones.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getTimezones.fulfilled.toString()]: (state, { payload }) => {
      const timezonesInCorrectFormat = payload.data?.map((item: string) => {
        return {
          value: item,
          text: item,
        };
      });
      state.timezones = timezonesInCorrectFormat;
      state.isLoading = false;
    },
    [getTimezones.rejected.toString()]: (state, { payload }) => {
      state.errors = payload;
      state.isLoading = false;
    },
  },
});

export default timezones.reducer;
