import { createAsyncThunk } from "@reduxjs/toolkit";
import { CurriculumType, CreateCurriculumType } from "../../types/curriculums";
import api from "../../api";

export const getCurriculums = createAsyncThunk(
  "curriculums/get-list",
  async () => {
    try {
      const {
        data,
      }: {
        data: Array<CurriculumType>;
      } = await api.curriculums.getCurriculums();
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getCurriculumById = createAsyncThunk(
  "curriculums/get-by-id",
  async (id: number, thunkAPI) => {
    try {
      const {
        data,
      }: { data: CurriculumType } = await api.curriculums.getCurriculumById(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createCurriculum = createAsyncThunk(
  "curriculums/create",
  async (value: CreateCurriculumType, thunkAPI) => {
    try {
      const {
        data,
      }: { data: CurriculumType } = await api.curriculums.createCurriculum(
        value
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateCurriculum = createAsyncThunk(
  "curriculums/update",
  async (values: { id: number; value: CurriculumType }, thunkAPI) => {
    try {
      const {
        data,
      }: { data: CurriculumType } = await api.curriculums.updateCurriculum(
        values
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteCurriculum = createAsyncThunk(
  "curriculums/delete",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: string } = await api.curriculums.deleteCurriculum(
        id
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
