import { createSlice } from "@reduxjs/toolkit";

import { signUp, signIn, forgotPass, resetPass } from "../actions/auth";
import { InitialAuthUserType } from "../../types/auth";

const initialState: InitialAuthUserType = {
  user: null,
  token: null,
  isLoading: false,
  errors: null,
  forgotPassSuccess: false,
  resetPassSuccess: false,
  message: null,
  loginErrors: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setStateValue(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
    ressetAuth() {
      return initialState;
    },
    setError(state, { payload }) {
      return {
        ...state,
        errors: payload,
      };
    },
  },
  extraReducers: {
    [signUp.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [signUp.fulfilled.toString()]: (state, { payload }) => {
      localStorage.setItem("token", payload.data.access_token);
      localStorage.setItem("id", payload.data.id);
      localStorage.setItem(
        "redirectTo",
        payload.redirectTo === "/user/search-profile"
          ? `${payload.redirectTo}/${payload.data.default_search_id}`
          : payload.redirectTo
      );

      state.isLoading = false;
      state.token = payload.data.access_token;
      state.user = payload.data;
      window.location.replace(window.location.href);
      return false;
    },
    [signUp.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [signIn.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [signIn.fulfilled.toString()]: (state, { payload }) => {
      localStorage.setItem("token", payload.data.access_token);
      localStorage.setItem("id", payload.data.id);
      state.token = payload.data.access_token;
      state.user = payload.data;
      state.isLoading = false;
      if (payload.redirectTo) {
        localStorage.setItem("redirectTo", payload.redirectTo);
      }
      window.location.replace(window.location.href);
      return false;
    },
    [signIn.rejected.toString()]: (state, { payload }) => {
      state.loginErrors = payload?.message ? payload.message : "Server Error";
      state.isLoading = false;
    },

    [forgotPass.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [forgotPass.fulfilled.toString()]: (state, { payload }) => {
      state.forgotPassSuccess = true;
      state.isLoading = false;
      state.message = payload?.message;
    },
    [forgotPass.rejected.toString()]: (state, { payload }) => {
      state.forgotPassSuccess = false;
      state.errors = payload?.message;
      state.isLoading = false;
      state.message = null;
    },

    [resetPass.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [resetPass.fulfilled.toString()]: (state, { payload }) => {
      state.resetPassSuccess = true;
      state.isLoading = false;
    },
    [resetPass.rejected.toString()]: (state, { payload }) => {
      state.resetPassSuccess = false;
      state.errors =
        payload?.errors?.password[0] || payload.message || "Server Error";
      state.isLoading = false;
    },
  },
});

export const { setStateValue, ressetAuth, setError } = authSlice.actions;

export default authSlice.reducer;
