import { createAsyncThunk } from "@reduxjs/toolkit";
import { RequestType, CreateRequest } from "../../types/requests";
import api from "../../api";
import { AnyIfEmpty } from "react-redux";

type paramsInGetRequestById = {
  id: string;
  type: string;
};

export const getRequests = createAsyncThunk("requests/get-list", async () => {
  try {
    const {
      data,
    }: { data: Array<RequestType> } = await api.requests.getRequests();
    return data;
  } catch (err) {
    return err.response.data;
  }
});

export const getMyRequestById = createAsyncThunk(
  "requests/get-by-id",
  async (id: number, thunkAPI) => {
    try {
      const {
        data,
      }: { data: RequestType } = await api.requests.getMyRequestById(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getRequestById = createAsyncThunk(
  "requests/get-by-id",
  async (params: { id: string; type: string }, thunkAPI) => {
    try {
      const { data }: { data: RequestType } = await api.requests.getRequestById(
        params.id,
        params.type
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createRequest = createAsyncThunk(
  "requests/create",
  async (value: CreateRequest, thunkAPI) => {
    try {
      const { data }: { data: RequestType } = await api.requests.createRequest(
        value
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateRequest = createAsyncThunk(
  "requests/update",
  async (values: { id: number; value: RequestType }, thunkAPI) => {
    try {
      const { data }: { data: RequestType } = await api.requests.updateRequest(
        values
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteRequest = createAsyncThunk(
  "requests/delete",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: string } = await api.requests.deleteRequest(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
