import React, { useEffect, ChangeEvent, FocusEvent } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  WrappedFieldInputProps,
  WrappedFieldMetaProps,
} from "redux-form/lib/Field";
import clsx from "clsx";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

import styles from "./styles.module.scss";

type PropsType = {
  input?: WrappedFieldInputProps;
  meta?: WrappedFieldMetaProps;
  label?: string;
  options: Array<{ value: string | number; text: string }>;
  inForm?: boolean;
  placeholder?: string;
  defaultValue?: string;
  handleChange?: (value: string | null) => void;
  nullIsAny?: boolean;
};

const SelecterSearch: React.FC<PropsType> = ({
  input,
  meta,
  label,
  options,
  inForm,
  placeholder,
  defaultValue,
  handleChange,
  nullIsAny,
}) => {
  useEffect(() => {
    !input?.value && defaultValue && input && input.onChange(defaultValue);
  }, [defaultValue]);

  let error = meta && meta.touched && !!meta.error;

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.inForm]: inForm,
      })}
    >
      {label && <div className={styles.title}>{label}</div>}

      <Autocomplete
        id="selecter-search"
        freeSolo
        value={!input?.value && nullIsAny ? "Any" : input?.value}
        placeholder={placeholder}
        options={options.map((option) =>
          !option.value && nullIsAny ? "Any" : option.text
        )}
        renderInput={(params) => (
          <div ref={params.InputProps.ref} className={styles.wrapper_input}>
            <input
              type="text"
              {...params.inputProps}
              placeholder={placeholder}
            />
            <ExpandMoreRoundedIcon />
          </div>
        )}
        onChange={(e: ChangeEvent<{}>, value: string | null) => {
          if (value === "Any") {
            input?.onChange(null);
            handleChange && handleChange(null);
            return;
          }
          input?.onChange(value);
          handleChange && handleChange(value);
        }}
      />
      {error && (
        <>
          <div className={styles.errorWrapper}>
            <p className={styles.error}>{meta && meta.touched && meta.error}</p>
          </div>

          <div className={styles.errorLabelWrapper}>
            <p className={styles.errorLabel}>!</p>
          </div>
        </>
      )}
    </div>
  );
};

export default SelecterSearch;
