import React, {
  useState,
  useEffect,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
} from "react";
import {
  WrappedFieldInputProps,
  WrappedFieldMetaProps,
} from "redux-form/lib/Field";
import { TextField, TextFieldProps } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import styles from "./styles.module.scss";

type CustomInputTypes = {
  input?: WrappedFieldInputProps;
  meta?: WrappedFieldMetaProps;
  defaultValue?: string | number;
  initialValue?: string | number;
  label?: string;
  title?: string;
  backendError?: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  handleChange?: (value: string) => void;
  handleBlur?: (value: string) => void;
  handleKeyUp?: (value: string) => void;
  handleKeyOk?: (value: string) => void;
  variant?: string;
  hidden?: boolean;
  inForm?: boolean;
  inDatepicker?: boolean;
  ref?: any;
  autoFocus?: boolean;
  placeholder?: any;
  maxLength?: number;
  passwordInSafariAutofocusFix?: boolean;
  onEnterPress?: (e:any) => void;
  bigIndex?: boolean;
  noMargin?: boolean;
};

const customStyles = (theme: any) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabsIndicator: {
    backgroundColor: "#61dafb",
  },
  helperText: {
    color: "red",
  },
});

const Input: React.FC<TextFieldProps & CustomInputTypes> = ({
  defaultValue,
  type = "text",
  classes,
  input,
  meta,
  backendError,
  handleChange,
  min,
  max,
  label,
  title,
  hidden,
  inForm,
  inDatepicker,
  handleBlur,
  handleKeyUp,
  handleKeyOk,
  placeholder,
  autoFocus = false,
  maxLength,
  passwordInSafariAutofocusFix = false,
  onEnterPress,
  bigIndex,
  noMargin,
  ...props
}) => {
  const [stateType, setStateType] = useState(type);
  useEffect(() => {
    if (defaultValue && input) {
      defaultValue === "-" ? input.onChange("") : input.onChange(defaultValue);
    }
  }, [defaultValue]);

  let error = meta && meta.touched && (!!meta.error || !!backendError);
  let booleanError = error || ""; //need for className

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.inForm]: inForm,
        [styles.inDatepicker]: inDatepicker,
        [styles.bigIndex]: bigIndex,
        [styles.noMargin]: noMargin
      })}
      style={{ display: hidden ? "none" : "block" }}
    >
      {title && <div className={styles.title}>{title}</div>}

      <TextField
        type={passwordInSafariAutofocusFix ? stateType : type}
        value={input?.value}
        className={(styles.input, booleanError && "error")}
        classes={{ root: classes && classes.root }}
        inputProps={
          type === "number"
            ? { min: min || 0, max: max }
            : { maxLength: maxLength }
        }
        {...input}
        {...props}
        variant="filled"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange && handleChange(e.target.value);
          input?.onChange(e.target.value);
        }}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          e.target.value !== "" && handleBlur && handleBlur(e.target.value);
        }}
        onKeyPress={(e: any) => {
          if (e.key === "Enter") {
            e?.preventDefault();
            onEnterPress && onEnterPress(e)
          }
          if (type === "number" && (e.key === " " || isNaN(+e.key))) {
            e?.preventDefault();
          }
          if (handleBlur && e.key === "Enter") {
            e.target.value !== "" && handleBlur && handleBlur(e.target.value);
          }
        }}
        onKeyUp={(e: any) => {
          handleKeyUp && handleKeyUp(e.target.value);
        }}
        onFocus={() => passwordInSafariAutofocusFix && setStateType("password")}
        error={error}
        autoFocus={autoFocus}
        placeholder={placeholder || "Please Enter..."}
        // helperText={meta && meta.touched && (meta.error || backendError)}
      />

      {error && (
        <>
          <div className={styles.errorWrapper}>
            <p className={styles.error}>
              {meta && meta.touched && (meta.error || backendError)}
            </p>
          </div>

          <div className={styles.errorLabelWrapper}>
            <p className={styles.errorLabel}>!</p>
          </div>
        </>
      )}
    </div>
  );
};

export default withStyles(customStyles)(Input);
