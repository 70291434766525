import { createSlice } from "@reduxjs/toolkit";
import { InitialRequestType } from "../../types/requests";
import {
  getRequests,
  createRequest,
  getRequestById,
} from "../actions/requests";

const initialState: InitialRequestType = {
  request: null,
  requests: null,
  isLoading: false,
  errors: null,
};

const requestsSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    ressetRequests(state) {
      return {
        ...state,
        request: null,
        requests: null,
      };
    },
  },
  extraReducers: {
    [createRequest.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [createRequest.fulfilled.toString()]: (state, { payload }) => {
      state.request = payload;
      state.isLoading = false;
    },
    [createRequest.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [getRequests.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getRequests.fulfilled.toString()]: (state, { payload }) => {
      state.requests = payload;
      state.isLoading = false;
    },
    [getRequests.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [getRequestById.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getRequestById.fulfilled.toString()]: (state, { payload }) => {
      state.request = payload;
      state.isLoading = false;
    },
    [getRequestById.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
  },
});

export const { ressetRequests } = requestsSlice.actions;

export default requestsSlice.reducer;
