import { AxiosResponse } from "axios";
import { instance, SERVER_HOST, headers } from "./index";
import { ReviewType, RequestReviewType } from "../types/reviews";

export default {
  getReviews: async () => {
    const {
      data,
    }: AxiosResponse<{ data: Array<ReviewType> }> = await instance.get(
      `${SERVER_HOST}/reviews`,
      headers()
    );
    return data;
  },

  getReviewById: async (id: number) => {
    const { data }: AxiosResponse<{ data: ReviewType }> = await instance.get(
      `${SERVER_HOST}/reviews/${id}`,
      headers()
    );
    return data;
  },

  getReviewByTypeId: async ({ id, type }: { id: number; type: string }) => {
    const { data }: AxiosResponse<{ data: ReviewType }> = await instance.get(
      `${SERVER_HOST}/reviews/${type}/${id}`,
      headers()
    );
    return data;
  },

  createReview: async ({
    reviewable_id,
    type,
    values,
  }: {
    reviewable_id: number;
    type: string;
    values: RequestReviewType;
  }) => {
    const { data }: AxiosResponse<{ data: ReviewType }> = await instance.post(
      `${SERVER_HOST}/reviews/${type}/${reviewable_id}`,
      values,
      headers()
    );
    return data;
  },

  updateReview: async ({
    id,
    values,
  }: {
    id: number;
    values: RequestReviewType;
  }) => {
    const { data }: AxiosResponse<{ data: ReviewType }> = await instance.put(
      `${SERVER_HOST}/reviews/${id}`,
      values,
      headers()
    );
    return data;
  },

  deleteReview: async (id: number) => {
    const { data }: AxiosResponse<{ data: string }> = await instance.delete(
      `${SERVER_HOST}/reviews/${id}`,
      headers()
    );
    return data;
  },
};
