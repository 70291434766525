import { AxiosResponse } from "axios";
import { instance, SERVER_HOST } from "./index";
import { FavoriteType, FavoriteAddRemoveResponseType } from "../types/favorites";

export default {
  getFavorites: async (type: string) => {
    const {
      data,
    }: AxiosResponse<{ data: Array<FavoriteType> }> = await instance.get(
      `${SERVER_HOST}/favorites/${type}`
    );
    return data;
  },

  addFavorite: async (id: number) => {
    const { data }: AxiosResponse<{ data: FavoriteAddRemoveResponseType }> = await instance.put(
      `${SERVER_HOST}/favorites/add/${id}`
    );
    return data;
  },

  removeFavorite: async (id: number) => {
    const { data }: AxiosResponse<{ data: FavoriteAddRemoveResponseType }> = await instance.put(
      `${SERVER_HOST}/favorites/remove/${id}`
    );
    return data;
  },
};
