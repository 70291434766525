import { AxiosResponse } from "axios";
import { instance, SERVER_HOST } from "./index";
import { PlanType } from "../types/plans";

export default {
  getPlans: async () => {
    const {
      data,
    }: AxiosResponse<{ data: Array<PlanType> }> = await instance.get(
      `${SERVER_HOST}/plans`
    );
    return data;
  },
};
