import { createAsyncThunk } from "@reduxjs/toolkit";
import { MessageType, ChatType, CreateMessageType } from "../../types/messages";
import api from "../../api";

export const getChats = createAsyncThunk(
  "chats/get-list-chats",
  async (type: string) => {
    try {
      const { data }: { data: Array<ChatType> } = await api.messages.getChats(
        type
      );
      return { type, data };
    } catch (err) {
      return err.response.data;
    }
  }
);

export const deleteChat = createAsyncThunk(
  "chats/delete",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: string } = await api.messages.deleteChat(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getMessages = createAsyncThunk(
  "messages/get-list-messages",
  async (chat_id: number, thunkAPI) => {
    try {
      const {
        data,
      }: { data: Array<MessageType> } = await api.messages.getMessages(chat_id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getMessageById = createAsyncThunk(
  "messages/get-by-id",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: MessageType } = await api.messages.getMessageById(
        id
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createMessage = createAsyncThunk(
  "messages/create",
  async (value: CreateMessageType, thunkAPI) => {
    try {
      const { data }: { data: MessageType } = await api.messages.createMessage(
        value
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateMessage = createAsyncThunk(
  "messages/update",
  async (values: { id: number; value: MessageType }, thunkAPI) => {
    try {
      const { data }: { data: MessageType } = await api.messages.updateMessage(
        values
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteMessage = createAsyncThunk(
  "messages/delete",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: string } = await api.messages.deleteMessage(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const allChatMessagesRead = createAsyncThunk(
  "messages/all-messages-chat-read",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: string } = await api.messages.allChatMessagesRead(
        id
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
