import { createAsyncThunk } from "@reduxjs/toolkit";
import { ZipType } from "../../types/zips";
import api from "../../api";

export const getZips = createAsyncThunk(
  "zips/get-list",
  async (zip: string) => {
    try {
      const { data }: { data: Array<ZipType> } = await api.zips.getZips(zip);
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);
