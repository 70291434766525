import React from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { List, ListItem, ListItemText, Divider } from "@material-ui/core";
import Popup from "../Popup/Popup";

import { signOut } from "../../../redux/actions/user";
import { NavMenuList } from "../../../helpers/values";
import { ressetSearchProfile } from "../../../redux/reducers/searchProfiles";
import { ressetTeachers } from "../../../redux/reducers/teachers";
import { resetSubscriptionsStore } from "../../../redux/reducers/subscriptions";
import { StateType } from "../../../redux/store";

import styles from "./styles.module.scss";

type PropsType = {
  navContent: Array<{
    name: string;
    link: string;
  }>;
};

const NavMenu: React.FC<PropsType> = ({ navContent }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { profile } = useSelector((state: StateType) => state.user);

  const logout = async () => {
    await dispatch(ressetSearchProfile());
    await dispatch(ressetTeachers());
    await dispatch(resetSubscriptionsStore());
    await dispatch(signOut());
    history.push("/");
  };

  const button = (
    <div className={styles.wrapper}>
      <div className={styles.button}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );

  const subscriptionOnClickHandler = () => {
    window.scrollTo(0, 0);
    if (location.pathname.includes("/user/subscription")) {
      history.push(`/user/subscription`, { reload: true });
    } else {
      history.push(`/user/subscription`);
    }
  };

  const paymentOnClickHandler = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.wrapper}>
      <Popup button={button} closeByOnClick={true}>
        {profile ? (
          <>
            <List className={styles.wrapper_list}>
              <a
                href={`/global-search`}
                className={styles.list_item}
              >
                <ListItemText primary="Search" />
              </a>
              {NavMenuList.map((item, index) => (
                <a
                  key={index}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  href={item.url}
                  target={item.url.includes("http") ? "_blank" : "_self"}
                  className={styles.list_item}
                >
                  <ListItemText primary={item.name} />
                </a>
              ))}
              <div className={styles.nav_content}>
                {navContent?.map((item, index) => {
                  if (item.name !== "Home" && item.name !== "Messages") {
                    return (
                      <a
                        key={index}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                        href={item.link}
                        target={item.link.includes("http") ? "_blank" : "_self"}
                        className={styles.list_item}
                      >
                        <ListItemText primary={item.name} />
                      </a>
                    );
                  }
                })}
              </div>

              <Divider />

              <a
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                href={"/user/profile-step3"}
                className={styles.list_item}
              >
                <ListItemText primary="Account" />
              </a>

              <a
                href={"/user/payment-method/edit"}
                onClick={paymentOnClickHandler}
                className={styles.list_item}
              >
                <ListItemText primary="Payment Method" />
              </a>

              <a
                onClick={subscriptionOnClickHandler}
                className={styles.list_item}
              >
                <ListItemText primary="Subscription" />
              </a>

              <a
                href={`${process.env.REACT_APP_SUPPORT_HOST}/hc/en-us/`}
                target="_blank"
                className={styles.list_item}
              >
                <ListItemText primary="Support" />
              </a>
            </List>

            <Divider />

            <div className={styles.logout}>
              <List className={styles.wrapper_list}>
                <a onClick={logout} className={styles.list_item}>
                  <ListItemText primary="Logout" />
                </a>
              </List>
            </div>
          </>
        ) : (
          <List className={styles.wrapper_list}>
            <div className={styles.nav_content}>
              {navContent?.map((item, index) => (
                <a
                  key={index}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  href={item.link}
                  target={item.link.includes("http") ? "_blank" : "_self"}
                  className={styles.list_item}
                >
                  <ListItemText primary={item.name} />
                </a>
              ))}
            </div>
          </List>
        )}
      </Popup>
    </div>
  );
};

export default NavMenu;
