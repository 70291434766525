import { AxiosResponse } from "axios";
import { MultiSelectItemType } from "../types/multiselects";
import { instance, SERVER_HOST } from "./index";

export default {
  getMultiSelectValues: async (type: string) => {
    const {
      data,
    }: AxiosResponse<{ data: Array<MultiSelectItemType> }> = await instance.get(
      `${SERVER_HOST}/${type}`
    );
    return data;
  },
};
