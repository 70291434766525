import { AxiosResponse } from "axios";
import { instance, SERVER_HOST, headers } from "./index";
import { UserType } from "../types/users";

export default {
  getUsers: async () => {
    const {
      data,
    }: AxiosResponse<{ data: Array<UserType> }> = await instance.get(
      `${SERVER_HOST}/users`,
      headers()
    );
    return data;
  },

  getUserById: async (id: number) => {
    const { data }: AxiosResponse<{ data: UserType }> = await instance.get(
      `${SERVER_HOST}/users/${id}`,
      headers()
    );
    return data;
  },
};
