import React from "react";
import {Helmet} from "react-helmet";
import styles from "./styles.module.scss";
import { hostName } from "../../../helpers/constants";

const TermsOfUsePage: React.FC = () => {
  return (
    <div className={styles.entryContent}>
      <Helmet>
        <title>Terms of Use for Anything Academic</title>
      </Helmet>
      <h1>Terms of Use</h1>
      <h2>
        IMPORTANT NOTICE: THIS AGREEMENT IS SUBJECT TO BINDING ARBITRATION
        AGREEMENT AND A WAIVER OF CLASS ACTION RIGHTS AS DETAILED IN SECTION 13.
      </h2>
      <p>
        Your use of the Site and or Services is subject at all times to these
        Terms of Use (the "Terms" or "Agreement"). You may also be subject to
        additional policies, rules and terms and conditions,as a condition of
        using certain Services ("Additional Terms").
      </p>
      <p>
        Please read these Terms and any applicable Additional Terms before using
        the Site or the Services. By using the Site or the Services, you hereby
        represent, warrant, understand, agree to and accept these Terms and any
        applicable Additional Terms in their entirety whether or not you
        register as a user of the Site or Services ("Registered Users")
      </p>
      <p>
        This Agreement contains an Agreement to Arbitrate, which will, with
        limited exception, require you to submit claims you have against the
        Company to binding and final arbitration, unless you opt out of the
        Agreement to Arbitrate (see Section 13 "Agreement to Arbitrate") no
        later than 30 days after the date you first use the Company Site or
        Services. Unless you opt out: (1) you will only be permitted to pursue
        claims against the Company on an individual basis, not as a plaint if
        for class member in any class or representative action or proceeding,
        and (2) you will only be permitted to seek relief (including monetary,
        injunctive, and declaratory relief) on an individual basis
      </p>
      <p>
        These Terms include the Company privacy policy which is incorporated
        herein. If you object to anything in these Terms, the Privacy Policy or
        any applicable Additional Terms, do not use the Site or the Services
      </p>
      <p>
        These Terms are subject to change by the Company at any time, subject to
        the following notification process. We will notify you about material
        changes in these Terms by sending a notice to the email address
        registered in your account, or by placing a prominent notice on our
        Site, so that you can choose whether to continue using our Services. You
        should periodically check the Site for updates. Any use of the Site or
        the Services by you after the effective date of any changes will
        constitute your acceptance of such changes
      </p>
      <p>
        For purposes of these Terms, and except to the extent expressly excluded
        below, the "Site" shall mean {hostName} and any other websites, web
        pages, mobile applications and mobile websites operated by Rose & Lee,
        LLC ("the Company" or "we"), and the "Services" shall mean any of the
        various services that the Company provides through the Site or any other
        channels, including without limitation, over the telephone
      </p>
      <p>
        However, the terms "Site" and "Services" do not include or involve
        Third-party entities that may use the Site or Services to market their
        services and/or recruit employees or providers.
      </p>
      <h3>1. Description of Services</h3>
      <div>
        <h4>1.1.Services</h4>
        <p>
          The Company offers various Services to help its users find teachers,
          tutors, community and learning resources for academic purposes. The
          Services we offer include, among others
        </p>
        <span>
          We provide a platform for individuals seeking education services (such
          as education service providers) (each "Patrons") to post jobs on the
          Site, and to search for, narrow, find, and communicate with
          individuals and entities who provide education services ("Providers"),
          and for Providers to post profiles on the Site and browse, search, and
          apply to jobs. We also provide functionality that allows Patrons to
          search for and message other Patrons to help facilitate shared
          education services
        </span>
        <span>
          We provide an online community for Registered Users to communicate and
          share information with other Registered Users who share a common
          interest or bond.
        </span>
        <p>
          Our Services continue to grow and change. Please refer to our Site for
          further information about the Services we provide.
        </p>
        <h4>1.2.Limitations of Services</h4>
        <p>
          We offer Services to help our users find, coordinate, and maintain
          education services for their families. However, except where
          specifically provided in these Terms, in any applicable Additional
          Terms, or on the Site itself.
        </p>
        <span>
          <strong>
            We do not employ any Providers and are not responsible for the
            conduct, whether online or offline, of any Patron, Provider, or
            other user of the Site or Services.Patrons are responsible for
            compliance with all applicable employment and other laws in
            connection with any employment relationship they establish
          </strong>
        </span>
        <span>
          We do not control or vet user-generated content for accuracy, and we
          do not assume and expressly disclaim any responsibility for the
          accuracy or reliability of any information provided by Providers or
          Patrons on or off the Site. We do not assume nd expressly disclaim any
          liability that may result from the use of information provided on our
          Site
        </span>
        <span>
          We do not refer or recommend Patrons or Providers nor do we make any
          representations about the suitability, reliability, timeliness, or
          accuracy of the services provided by Providers or the integrity,
          responsibility or actions of Patrons or Providers whether in public,
          private or offline interactions.
        </span>
        <strong>
          Please review carefully the Release of Liability Form Third-Party
          Content and Conduct that appears in Section 9 below for important
          limitations on the Company's liability to which you are agreeing by
          using the Site and Services
        </strong>
        <h4>1.3.User Responsibilities</h4>
        <p>
          While we provide tools and information to help Patrons and Providers
          make more informed decisions, such as safety tips at [URL], Registered
          Users are solely responsible for interviewing,vetting, performing
          background and reference checks on, verifying information provided by,
          and selecting an appropriate Patron or Provider for themselves or
          their family
        </p>
        <p>
          Each Patron is responsible for complying with all applicable
          employment and other laws in connection with any employment
          relationship they establish, including verifying the age of the
          Provider they select as well as that Provider's eligibility to work in
          the US
        </p>
        <h4>2.Eligibility to Use the Site and Services</h4>
        <p>
          By requesting to use, registering to use and/or using the Site or the
          Services, you represent and warrant that you have the right, authority
          and capacity to enter into these Terms and you commit to abide by all
          of the terms and conditions hereof. You also represent and warrant
          that you meet the following eligibility criteria:
        </p>
        <span>
          You intend to use the Site and Services solely for the purpose of
          finding or managing education services or for finding or sharing
          information relating to education services
        </span>
        <span>
          Our Services are available only to individuals who are eighteen (18)
          years of age or older. If you do not meet this age requirement, do not
          register to use the Site or Services
        </span>
        <span>
          The Site and theServices are currently available only to individuals
          who reside legally in the United States. If you reside outside the
          United States, do not register to use the Site or Services.
        </span>
        <span>
          Providers must be eligible to legally work within the United States
        </span>
        <span>
          Neither you nor any member of your household may have ever been:
        </span>
        <span>
          the subject of a conviction, arrest, charge, complaint, restraining
          order or any other legal action involving:
        </span>
        <span>any felony;</span>
        <span>
          any other criminal offense involving violence, physical, sexual, or
          emotional abuse or neglect, or theft, drugs, or
        </span>
        <span>
          any offense, violation, or unlawful act that involves endangering the
          safety of others, fraud, dishonesty, or reckless or negligent conduct.
        </span>
        <span>
          registered, or currently required to register, as a sex offender with
          any government entity.
        </span>
      </div>
      <h3>3. Rules for User Conduct and Use of Services</h3>
      <div>
        <h4> 3.1.Registration, Posting, and Content Restrictions</h4>
        <p>
          The following rules pertain to "Content", defined as any
          communications, images, sounds, videos, and all the material, data,
          and information that you upload or transmit through the Site
          orServices, whether to the Company or to other Registered Users or
          Site visitors, or that other users upload or transmit, including
          without limitation any content, messages, photos, audios, videos,
          reviews or profiles that you publish or display (hereinafter, "post"):
        </p>
        <span>
          Any Content that you post, upload or transmit to or through the Site
          or Services is solely for the purpose of finding or managing education
          services, or for finding or sharing information relating to education
          services.
        </span>
        <span>
          You are responsible for providing accurate, current and complete
          information in connection with your registration for use of the Site
          and the Services.
        </span>
        <span>You will register your account in your own legal name</span>
        <span>
          You are solely responsible for any Content that you post on the Site
          or transmit to other users of the Site. You will not post on the Site,
          or transmit to other users, any defamatory, inaccurate, abusive,
          obscene, profane, offensive, sexually oriented, threatening,
          harassing, defamatory, racially offensive, or illegal material, or any
          material that infringes or violates another party's rights (including,
          but not limited to, intellectual property rights, and rights of
          privacy and publicity), or advocate, promote or assist any unlawful
          act such as (by way of example only) copyright infringement or
          computer misuse, or give the impression that any Content emanates from
          the Company where this is not the case. You will not provide
          inaccurate, misleading, defamatory or false information to the Company
          or to any other user of the Site, and all opinions stated as part of
          Content must be genuinely held. Without limiting the foregoing, you
          represent and warrant to us that you have the right and authority to
          post all information you post about yourself or others, including
          without limitation that you have authorization from a parent or
          guardian of any minor who is the subject of any Content you post to
          post such Content
        </span>
        <span>
          You understand and agree that the Company may, in its sole discretion,
          review, edit, and delete any Content, in each case in whole or in
          part, that in the sole judgment of the Company violates these Terms or
          which the Company determines in its sole discretion might be
          offensive, inappropriate, illegal, or that might violate the rights,
          harm, or threaten the safety of users of the Site or others.
        </span>
        <span>
          You have the right, and hereby grant, to the Company, its Affiliates,
          licensees and successors, an irrevocable, perpetual, non-exclusive,
          fully paid-up, transferable, worldwide license to use, copy, perform,
          display, reproduce, adapt, modify and distribute your Content and to
          prepare derivative works of, or incorporate into other works, such
          Content, and to grant and authorize sublicenses of the foregoing. You
          further represent and warrant that public posting and use of your
          Content by the Company will not infringe or violate the rights of any
          third party.
        </span>
        <span>
          Your use of the Services, including but not limited to the Content you
          post on the Site, must be in accordance with any and all applicable
          laws and regulations.
        </span>
        <span>
          You agree that any comments, ideas, messages, questions, suggestions,
          or other communications you send to us or share with us through any
          channel (including, without limitation, the Site, email, telephone,
          surveys, and our social media accounts)shall be and remain the
          exclusive property of the Company and we may use all such
          communications, all without notice to, consent from, or compensation
          to you.
        </span>
        <span>
          We do not control or vet Content for accuracy, and we do not assume
          and expressly disclaim any responsibility for the accuracy or
          reliability of any Content provided by Providers or Patrons on or off
          the Site, including any Content that may include misstatements or
          misrepresentations or that may be defamatory or disparaging. Users
          hereby represent, understand and agree to hold the Company harmless
          for any misstatements misrepresentations, or other disparaging or
          defamatory statements made by or on behalf of them on this Site or in
          any other venue.
        </span>
        <span>
          Opinions, advice, statements, offers, or other information or content
          made available on the Site or through the Site, but not directly by
          the Company, are those of their respective authors. Such authors are
          solely responsible for such content. the Company does not: (i)
          guarantee the accuracy, completeness, or usefulness of any information
          on the Site or available through the Service, or (ii) adopt, endorse
          or accept responsibility for the accuracy or reliability of any
          opinion, advice, or statement made by any party that appears on the
          Site or through the Service. Under no circumstances will the Company
          or its Affiliates be responsible for any loss or damage resulting
          from: (a) your reliance on information or other content posted on the
          Site or transmitted to or by any user of the Site or Service; or (b)
          reviews or comments made about you on the Site by other users.
        </span>
        <span>
          You agree that the Company has no obligation to remove any reviews or
          other information posted on the Site about you or any other person or
          entity. If you disagree with a review, you may post one rebuttal to
          the review, provided your rebuttal complies with these Terms. You may
          not terminate your registration and re-register in order to prevent a
          review from being associated with your account. The author of a review
          can always remove or request removal of a review they have written.
        </span>
        <h4>3.2.Exclusive Use</h4>
        <p>
          If you are a Patron, you may use your account only to find education
          services for yourself, your children, your grandchildren, individuals
          for whom you are otherwise the legal guardian, or children of another
          Patron with whom you are entering a shared education services
          arrangement. If you are a Provider, you may use your account only to
          find education services jobs for yourself. You are responsible for all
          activity on and use of your account, and you may not assign or
          otherwise transfer your account to any other person or entity.
        </p>
        <h4>3.3.Prohibited Uses</h4>
        <p>
          By using the Site or Services of the Company, you agree that you will
          not under any circumstances:
        </p>
        <span>
          use the Site, Services, or any information contained therein in any
          way that is abusive, threatening, obscene, defamatory, libelous, or
          racially, sexually, religiously, or otherwise objectionable or
          offensive;
        </span>
        <span>
          use the Site or Services for any fraudulent or unlawful purpose or for
          any other purpose not expressly intended by the Company;
        </span>
        <span>
          harass, abuse or harm another person or group, or attempt to do so
        </span>
        <span>use another user's account</span>
        <span>
          provide false or inaccurate information when registering an account,
          using the Services or communicating with other Registered Users;
        </span>
        <span>
          attempt to re-register with the Company if we have terminated your
          account for any or no reason or terminate your registration and
          re-register in order to prevent a review from being associated with
          your account
        </span>
        <span>
          interfere or attempt to interfere with the proper functioning of the
          Company's Services;
        </span>
        <span>
          make any automated use of the system, or take any action that we deem
          to impose or to potentially impose an unreasonable or
          disproportionately large load on our servers or network
          infrastructure;
        </span>
        <span>
          bypass any measures we take to restrict access to the Service or use
          any software, technology, or device to scrape, spider, or crawl the
          Site or harvest or manipulate data (whether manually or through
          automated means);
        </span>
        <span>
          use the communication systems provided by or contacts made on the
          Company for any commercial solicitation purposes other than those
          expressly permitted by the Company;
        </span>
        <span>
          publish or link to malicious content intended to damage or disrupt
          another user's browser or computer.
        </span>
        <p>
          Should the Company find that you violated the terms of this Section or
          any terms stated herein, the Company reserves the right, at its sole
          discretion, to immediately terminate your use of the Site and
          Services. By using the Site and/or Services, you agree that the
          Company may assess, and you will be obligated to pay, $5,000 per each
          day that you: (i) maintain Provider or Patron information (including
          but not limited to, names, addresses, phone numbers, or email
          addresses) or copyrighted material that you scraped (either directly
          or indirectly in a manual or automatic manner) from the Site, or (ii)
          otherwise mis-use or mis-appropriate Site Content, including but not
          limited to, use on a "mirrored", competitive, or third party site.
          This obligation shall be in addition to any other rights the Company
          may have under these Terms or applicable law. Further, in order to
          protect the integrity of the Site and the Services, the Company
          reserves the right at any time in its sole discretion to block users
          from certain IP addresses from accessing the Site.
        </p>
      </div>
      <h3>4. Background and Verification Checks</h3>
      <div>
        <h4>4.1.Background Check Offerings</h4>
        <p>
          Through certain pages of the Site, we may from time to time offer to
          individuals who may or may not be registered as Patrons or Providers
          the opportunity to purchase certain background checks from third party
          providers. Individuals ordering background checks are solely
          responsible for complying with all relevant rules and regulations,
          including, without limitation, obtaining appropriate authorization
          from the checked individual and complying with the Fair Credit
          Reporting Act.
        </p>
        <h3>
          4.2.The Company May Regularly Verify Your Identity and the Accuracy of
          Your Representations and Warranties
        </h3>
        <p>
          By registering as a Provider or Patron, and, if applicable to you as a
          Provider, subject to your additional authorization, you authorize the
          Company, and acknowledge that for purposes of promoting the safety and
          integrity of its Site and Service, the Company reserves the right, but
          not the obligation, to utilize third party service providers to verify
          on an ongoing basis that your registration data is accurate and that
          the representations and warranties in Section 2 above addressing legal
          matters such as complaints, arrests, sex offender status, etc. are
          also true ("Verification Checks"). These third parties may use data
          from a variety of sources, under a variety of circumstances, for these
          site safety purposes including, without limitation, information from
          national criminal databases, sex offender registries, certain media
          streams, terrorist watch lists, criminal and fugitive watch lists,
          fraud watch lists, law enforcement reports, and other data.
        </p>
        <p>
          You agree that the Company may take such action in response to
          Verification Checks as it deems appropriate in its sole discretion,
          including without limitation suspending and/or terminating your
          membership, should it determine that you have violated any
          representation or warranty or any other provision of these Terms or
          are otherwise unsuitable for the Company.
        </p>
        <p>
          You also hereby represent, understand and expressly agree that the
          Company does not have control over or assume any responsibility for
          the quality, accuracy, or reliability of the information included in a
          Verification Check. We do not typically communicate the results of a
          Verification Check to any third party, though we reserve the right to
          do so for law enforcement or other safety-related purposes in
          accordance with applicable laws.
        </p>
        <p>
          BY AGREEING TO THESE TERMS, AND, IF APPLICABLE TO YOU AS A PROVIDER
          SUBJECT TO YOUR ADDITIONAL AUTHORIZATION, YOU AGREE TO ALLOW THE
          COMPANY TO PERFORM THE VERIFICATION CHECKS DESCRIBED ABOVE. IF YOU DO
          NOT WANT THESE VERIFICATION CHECKS TO BE PERFORMED, YOU SHOULD NOT USE
          THE COMPANY
        </p>
      </div>
      <h3>5.Termination</h3>
      <div>
        <p>
          The Company reserves the right, in its sole discretion, to immediately
          terminate your access to all or part of the Company Site and/or
          Services, to remove your profile and/or any content posted by or about
          you from the Site, and/or to terminate your account with the Company,
          with or without notice for any reason or no reason in its sole
          discretion, including without limitation if the Company should
          determine that you are not eligible to use the Services, have violated
          any terms stated herein or in any of the Additional Terms, are not
          suitable for participation as a Registered User, have mis-used or
          mis-appropriated Site content, including but not limited to use on a
          "mirrored," competitive, or third-party site. Upon termination, the
          Company shall be under no obligation to provide you with a copy of any
          content posted by or about you on the Site. If we terminate your
          registration, we have no obligation to notify you of the reason, if
          any, for your termination.
        </p>
        <p>
          You understand and agree that, following any termination of any
          individual's use of the Site or the Services, the Company has the
          right, but not the obligation, to send a notice of such termination to
          other Registered Users with whom we believe the individual has
          corresponded.Our decision to terminate an individual's registration
          and/or to notify other Registered Users with whom we believe the
          individual has corresponded does not constitute, and should not be
          interpreted or used as information bearing on, the individual's
          character, general reputation, personal characteristics, or mode of
          living.
        </p>
        <h4>5.1.Privacy</h4>
        <p>
          The Company uses the information you provide on the Site or via the
          Services or in accordance with our Privacy Policy For more
          information, see our full Privacy Policy, the terms of which are
          incorporated herein.
        </p>
      </div>
      <h3>6. Links To External Sites</h3>
      <p>
        We make every effort to recommend websites that have content that is
        appropriate for general audiences. However, it is your responsibility to
        parents preview the sites for suitable content, and, where applicable,
        review the sites together with children. Links from the Site to external
        sites or inclusion of advertisements and other third-party content on
        the Site do not constitute an endorsement by the Company of such sites
        or the content, products, advertising and other materials presented on
        such sites or of the products and services that are the subject of such
        third-party content, but are for users' reference and convenience even
        though we may earn compensation from products or services purchased via
        such links. The Company does not control third-party sites or content,
        and is not responsible for them. Such sites and content are governed by
        their respective owners' terms of use and privacy policies, and not
        these Terms or the Company's Privacy Policy.
      </p>
      <p>
        The Company expressly disclaims any liability derived from the use
        and/or viewing of links that may appear on this Site. All users hereby
        agree to hold the Company harmless from any liability that may result
        from the use of links that may appear on the Site
      </p>
      <h3>7. Payment And Refund Policy</h3>
      <p>
        In order to utilize some the Company Services or product offerings, the
        user of such Services or product offerings must pay the Company either a
        recurring subscription, one-time, or other fees.In addition, the user is
        responsible for any state or local sales taxes associated with the
        Services or product offerings purchased.
      </p>
      <div>
        <h4>7.1.Billing and Payment</h4>
        <p>
          If you sign up for a paid membership subscription, or for a Service or
          product that includes a recurring fee, you agree to pay the Company
          all charges associated with the subscription, Service, or product you
          choose, as described on the Site at the time you submit your payment
          information. You also authorize the Company, or a third-party payment
          processor that works on our behalf, to charge your chosen payment
          method according to the terms of your chosen subscription, Service, or
          product. the Company reserves the right to correct any errors or
          mistakes that it makes even if it has already requested or received
          payment.
        </p>
        <p>
          To the extent you elect to purchase other Services or product
          offerings we may offer for a non-recurring fee, you authorize the
          Company to charge your chosen payment provider for the Services and/or
          products you purchase. You agree that if the Company already has your
          credit card on file as a result of prior purchases you have made, we
          may charge that credit card for the additional Services or products
          you purchase.
        </p>
        <h4>7.2.Automatic Subscription Renewal and Cancellation</h4>
        <p>
          PAID MEMBERSHIP SUBSCRIPTIONS MAY BE RECURRING FEE-BASED SERVICES OR
          PRODUCTS THAT CONTINUE INDEFINITELY UNTIL CANCELLED BY THE USER.
        </p>
        <p>
          RECURRING SUBSCRIPTIONS WILL AUTOMATICALLY RENEW AT THE END OF YOUR
          CHOSEN TERM PERIOD FOR AN ADDITIONAL EQUIVALENT PERIOD AT THE
          SUBSCRIPTION RATE AND FREQUENCY DISCLOSED TO YOU ON THE SITE WHEN YOU
          ORIGINALLY SUBSCRIBED, UNLESS OTHERWISE PROVIDED AT THE TIME YOU
          SUBSCRIBED. IF YOU CHOOSE A SUBSCRIPTION TERM IN EXCESS OF ONE MONTH
          THAT ALLOWS YOU TO BE CHARGED MONTHLY OVER THE SUBSCRIPTION PERIOD,
          AND YOU DECIDE TO CANCEL YOUR SUBSCRIPTION DURING THE SUBSCRIPTION
          PERIOD, YOU ACKNOWLEDGE AND AGREE THAT YOU WILL CONTINUE TO BE BILLED
          FOR THE SUBSCRIPTION ON A MONTHLY BASIS UNTIL THE END OF THE
          THEN-CURRENT SUBSCRIPTION TERM.
        </p>
        <p>
          FOR OTHER SERVICES OR PRODUCTS WITH RECURRING FEES, YOU WILL BE
          CHARGED THE FEE ASSOCIATED WITH SUCH PRODUCT OR SERVICE AT THE
          INTERVAL SPECIFIED AT THE TIME OF PURCHASE, UNTIL YOU EXPRESSLY CANCEL
          THE SERVICE OR PRODUCT
        </p>
        <p>
          You may cancel your paid membership subscription at any time by
          logging into your account and selecting “cancel my subscription” in
          your membership area. If you cancel a paid subscription, you typically
          will be permitted to use your subscription until the end of your
          then-current subscription term. Your paid subscription will not be
          renewed after your then-current term expires, but your payment method
          will be charged, and you will be required to pay, any cancellation or
          other fees associated with your early termination and disclosed to you
          at the time you signed up for the subscription plan.
        </p>
        <h4>7.3.Refund Policy</h4>
        <p>
          Except as set forth in these Terms or as described on the Site at the
          time you make a purchase,all payments for subscriptions, services or
          products made on or through the Site or Services are non-refundable,
          and there are no refunds or credits for unused or partially used
          subscriptions, services or products, even if you cancel your
          membership or a subscription, service, or product in the middle of a
          term, or the company removes you from the Site.
        </p>
      </div>
      <h3>8. Release of Liability for Conduct and Disputes</h3>
      <div>
        <p>
          By using this Site or our Services, you hereby represent, understand,
          and expressly agree to release and hold the Company (and the Company's
          officers, directors, shareholders, employees, parents, subsidiaries,
          other affiliates, successors, assignees, agents, representatives,
          advertisers, marketing partners, licensors, independent contractors,
          recruiters, corporate partners or resellers ("Affiliates")) harmless
          from any claim or controversy that may arise out of:
        </p>
        <ul>
          <li>
            the actions or inactions of, or the relationship between, you and
            any Patron, Provider or other user(s) of the Site; and
          </li>
          <li>
            any information, instruction, advice or services created by any
            Patron, Provider, or other third party that appeared on, or was
            communicated through, the Site
          </li>
        </ul>
        <p>
          The Company and its Affiliates expressly disclaims any liability
          whatsoever for any damage, suits, claims, and/or controversies that
          have arisen or may arise, whether known or unknown, from the above.
        </p>
      </div>
      <h3>9. Age Restrictions</h3>
      <p>
        The Site and Services are intended for people 18 or over. The Company
        will not knowingly collect any information from individuals under 13.
        Should we determine that you do not meet the age requirements for using
        our Site or Services, your registration will be terminated immediately.
      </p>
      <h3>10. Disclaimers</h3>
      <div>
        <h4>10.1.No Warranty</h4>
        <p>
          The information and materials contained on the Site, including text,
          graphics, information, links or other items are provided "as is," "as
          available." Further, opinions, advice, statements, offers, or other
          information or content made available through the Services, but not
          directly by the Company, are those of their respective authors, and
          should not necessarily be relied upon. Such authors are solely
          responsible for such content.THE COMPANY DOES NOT: (1) WARRANT THE
          ACCURACY, ADEQUACY OR COMPLETENESS OF THIS INFORMATION AND MATERIALS;
          (2) ADOPT, ENDORSE OR ACCEPT RESPONSIBILITY FOR THE ACCURACY OR
          RELIABILITY OF ANY OPINION, ADVICE, OR STATEMENT MADE BY ANY PARTY
          OTHER THAN THE COMPANY; (3) WARRANT THAT YOUR USE OF THE SITE OR
          SERVICES WILL BE SECURE, FREE FROM COMPUTER VIRUSES, UNINTERRUPTED,
          ALWAYS AVAILABLE, ERROR-FREE OR WILL MEET YOUR REQUIREMENTS, OR THAT
          ANY DEFECTS IN THE SERVICES WILL BE CORRECTED; OR (4) GIVE ANY
          WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT OF
          THIRD PARTY RIGHTS. TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE
          COMPANY EXPRESSLY EXCLUDES ALL CONDITIONS, WARRANTIES AND OTHER TERMS
          WHICH MIGHT OTHERWISE BE IMPLIED BY STATUTE, COMMON LAW OR THE LAW OF
          EQUITY AND DISCLAIMS LIABILITY FOR ERRORS OR OMISSIONS IN THIS
          INFORMATION AND MATERIALS.
        </p>
        <p>
          IN ADDITION, AND WITHOUT LIMITING THE FOREGOING, THE COMPANY MAKES NO
          REPRESENTATION OR WARRANTIES OF ANY KIND WHETHER EXPRESS OR IMPLIED
          REGARDING THE SUITABILITY OF ANY USER OF OUR SITE TO PROVIDE SERVICES
          AS A PROVIDER OR TO EMPLOY THE SERVICES OF A PROVIDER.
        </p>
        <p>
          THE INFORMATION AND MATERIAL CONTAINED ON THE SITE IS PROVIDED FOR
          YOUR INTERNAL USE ONLY AND MAY NOT BE COPIED OR REDISTRIBUTED FOR ANY
          REASON. THIS INFORMATION IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY
          KIND. IN NO EVENT WILL THE COMPANY BE LIABLE IN ANY WAY WITH REGARD TO
          SUCH INFORMATION. YOUR USE OF THE INFORMATION MUST IN ALL CASES COMPLY
          WITH ALL APPLICABLE LAWS AND REGULATIONS.
        </p>
        <h4>10.2.Assumption of Risk</h4>
        <p>
          Finding education services or education-related jobs via an online
          platform like ours comes with certain inherent risks. We do not
          provide any training, supervision, or monitoring of Patrons or
          Providers, and we cannot guarantee that all of your interactions with
          other Registered Users, site visitors, their families, or others
          associated with them, will always be safe and respectful. You agree to
          assume all risks when using the Site and the Services, including but
          not limited to injury, illness, death, and all other risks associated
          with any online or offline interactions with users of the Site or the
          Services. You further agree to take all necessary precautions when
          interacting with other site visitors, Registered Users, their
          families, and others associated with them.
        </p>
      </div>
      <h3>11. Limitation of Liability; Indemnification</h3>
      <div>
        <h4>11.1.Incidental Damages and Aggregate Liability.</h4>
        <span>
          In no event will the Company be liable for any indirect, special,
          incidental, or consequential damages, losses or expenses arising out
          of or relating to the use or inability to use the Site or Services,
          including without limitation damages arising from the Company’s
          negligence (where permitted by law) and damages related to any
          information received from the Site or Services, removal of content
          from the Site, including profile information, any email distributed to
          any user or any linked web site or use thereof or inability to use by
          any party, or in connection with any termination of your subscription
          or ability to access the Site or Services, failure of performance,
          error, omission, interruption, defect, delay in operation or
          transmission, computer virus or line or system failure, even if the
          Company, or representatives thereof, are advised of the possibility of
          such damages, losses or expenses. UNDER NO CIRCUMSTANCES WILL THE
          COMPANY'S AGGREGATE LIABILITY, IN ANY FORM OF ACTION WHATSOEVER IN
          CONNECTION WITH THIS AGREEMENT OR THE USE OF THE SITE OR SERVICES OR
          THE SITE, EXCEED THE PRICE PAID BY YOU FOR YOUR ACCOUNT, OR, IF YOU
          HAVE NOT PAID THE COMPANY FOR THE USE OF ANY SERVICES, THE AMOUNT OF
          $25.00. THIS LIMITATION OF LIABILITY APPLIES TO DAMAGES ARISING OUT OF
          THE COMPANY’S NEGLIGENCE WHERE PERMITTED BY LAW
        </span>
        <h3>No Liability for non-Company Actions.</h3>
        <span>
          IN NO EVENT WILL THE COMPANY BE LIABLE FOR ANY DAMAGES WHATSOEVER,
          WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, COMPENSATORY,
          CONSEQUENTIAL, OR OTHERWISE, ARISING OUT OF OR RELATING TO THE CONDUCT
          OF YOU OR ANYONE ELSE IN CONNECTION WITH THE USE OF THE SITE OR THE
          SERVICES OR ANY AGREEMENT OR RELATIONSHIP FORMED USING THE SITE OR
          SERVICE, INCLUDING WITHOUT LIMITATION, BODILY INJURY, EMOTIONAL
          DISTRESS, AND/OR ANY OTHER DAMAGES RESULTING FROM ANYONE'S RELIANCE ON
          INFORMATION OR OTHER CONTENT POSTED ON THE SITE, OR TRANSMITTED TO OR
          BY ANY USERS OR ANY OTHER INTERACTIONS WITH OTHER REGISTERED USERS OF
          THE SITE OR SERVICES, WHETHER ONLINE OR OFFLINE. THIS INCLUDES ANY
          CLAIMS, LOSSES OR DAMAGES ARISING FROM THE CONDUCT OF USERS WHO HAVE
          REGISTERED UNDER FALSE PRETENSES OR WHO ATTEMPT TO DEFRAUD OR HARM
          YOU. THIS LIMITATION OF LIABILITY APPLIES TO DAMAGES ARISING OUT OF
          THE COMPANY’S NEGLIGENCE WHERE PERMITTED BY LAW
        </span>
        <p>
          In addition to the preceding paragraphs of this section and other
          provisions of these Terms, any advice that may be posted on the Site
          is for informational purposes only and is not intended to replace or
          substitute for any professional financial, medical, legal, or other
          advice. the Company makes no representations or warranties and
          expressly disclaims any and all liability concerning any treatment,
          action by, or effect on any person following the information offered
          or provided within or through the Site. If you have specific concerns
          or a situation arises in which you require professional or medical
          advice, you should consult with an appropriately trained and qualified
          specialist.
        </p>
        <h4>11.3.Indemnification</h4>
        <p>
          By agreeing to these Terms, users of the Site and Services agree to
          indemnify, defend and hold harmless the Company and its Affiliates
          from and against any and all claims, losses, expenses or demands of
          liability, including reasonable attorneys' fees and costs incurred by
          the Company and its Affiliates in connection with any claim by a third
          party (including an intellectual property claim) arising out of (i)
          materials and content you submit, post or transmit through the Site,
          (ii) use of the Site or Services by you in violation of these Terms of
          Use or in violation of any applicable law, or (iii) any relationship
          or agreement formed with a Patron or Provider using the Site or
          Services. Users further agree that they will cooperate as reasonably
          required in the defense of such claims. The Company and its Affiliates
          reserve the right, at their own expense, to assume the exclusive
          defense and control of any matter otherwise subject to indemnification
          by users, and users shall not, in any event, settle any claim or
          matter without the written consent of the Company. Users further agree
          to hold harmless the Company and its Affiliates from any claim arising
          from a third party's use of information or materials of any kind that
          users post to the Site.
        </p>
      </div>
      <h3>12.Copyright Notices/Complaints</h3>
      <p>
        It is the Company's policy to respond to notices of alleged copyright
        infringement with the DigitalMillennium Copyright Act ("DMCA"). If you
        believe any materials accessible on or from our Site infringe your
        copyright, you may request removal of those materials (or access
        thereto) from the Site by contacting questions@anythingacademic.com
      </p>
      <h3>13. Agreement to Arbitrate</h3>
      <p>
        This Section 13 is referred to in these Terms as the "Arbitration
        Agreement". Unless you opt-out in accordance with the opt-out procedures
        set forth in Section 13.8 below, you agree that all claims relating to
        or arising out of these Terms or the breach thereof, whether sounding in
        contract, tort, or otherwise that have arisen or may arise between you
        and the Company or a Company Affiliate, whether relating to these Terms
        (including any alleged breach thereof), the Services, the Site, or
        otherwise, shall be resolved exclusively through final and binding
        arbitration, rather than a court,in accordance with the terms of this
        Arbitration Agreement, except you may assert individual claims in small
        claims court, if your claims qualify. Your rights will be determined by
        a neutral arbitrator, not a judge or jury. The Federal Arbitration Act
        governs the interpretation and enforcement of this Arbitration
        Agreement.
      </p>
      <div>
        <h4>
          13.1.Prohibition of Class and Representative Actions and
          Non-Individualized Relief
        </h4>
        <p>
          YOU AND THE COMPANY AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE
          OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS
          MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ARBITRATION, ACTION OR
          PROCEEDING. UNLESS BOTH YOU AND THE COMPANY EXPRESSLY AGREE OTHERWISE,
          THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON'S OR
          PARTY'S CLAIMS AGAINST THE COMPANY AND MAY NOT OTHERWISE PRESIDE OVER
          ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO,
          THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
          DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING
          RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED
          BY THAT PARTY'S INDIVIDUAL CLAIM(S). ANY RELIEF AWARDED CANNOT AFFECT
          OTHER THE COMPANY USERS.If any court or arbitrator determines that the
          class action waiver set forth in this paragraph is void or
          unenforceable for any reason or that an arbitration can proceed on a
          class basis, then the arbitration provision set forth above shall be
          deemed null and void in its entirety and the parties shall be deemed
          to have not agreed to arbitrate disputes.
        </p>
        <h4>13.2. Pre-Arbitration Dispute Resolution</h4>
        <p>
          The Company is interested in resolving disputes amicably and
          efficiently. Before you commence arbitration, we suggest that you
          contact us to explain your complaint, as we may be able to resolve it
          without the need for arbitration. You may contact us via email at
          legal@anythingacademic.com
        </p>
        <h4>13.3. Arbitration Procedures</h4>
        <p>
          If we cannot resolve a Claim informally, any Claim either of us
          asserts will be resolved only by binding arbitration and not in courts
          of general jurisdiction. Arbitration will be conducted bya neutral
          arbitrator in accordance with the rules of JAMS that are in effect at
          the time the arbitration is initiated (collectively referred to as the
          "JAMS Rules"), as modified by this Arbitration Agreement, and
          excluding the JAMS Class Action Procedures. For information on JAMS,
          please visit its website,https://www.jamsadr.com/. Information about
          JAMS's Rules and fees for consumer disputes can be found at the JAMS
          consumer arbitration
          page,https://www.jamsadr.com/rules-comprehensive-arbitration/. If
          there is any inconsistency between the JAMS Rules and this Arbitration
          Agreement, the terms of this Arbitration Agreement will control unless
          the arbitrator determines that the application of the inconsistent
          Arbitration Agreement terms would not result in a fundamentally fair
          arbitration. The arbitrator must also follow the provisions of these
          Terms as a court would, including without limitation, the limitation
          of liability provisions in Section 11. Although arbitration
          proceedings are usually simpler and more streamlined than trials and
          other judicial proceedings, the arbitrator can award the same damages
          and relief on an individual basis that a court can award to an
          individual under the Terms and applicable law. Decisions by the
          arbitrator are enforceable in court and may be overturned by a court
          only for very limited reasons.
        </p>
        <p>
          To commence an arbitration against the Company, you must write a
          demand for arbitration that includes a description of the dispute and
          the amount of damages sought to be recovered. You can find a copy of a
          Demand for Arbitration atwww.jamsadr.com. You should send three copies
          of the Demand, plus the appropriate filing fee, to [contact address],
          and send one copy to the Company at 204 37th Avenue NorthPOBOX 116
          Saint Petersburg Fl 33703. For more information, see the JAMS
          arbitration rules and forms,https://www.jamsadr.com/rules-download/.
          You may represent yourself in the arbitration or be represented by an
          attorney or another representative. Once we receive your arbitration
          claim, we may assert any counterclaims we may have against you.
        </p>
        <p>
          The arbitration shall be held in the county in which you reside or at
          another mutually agreed location. If the value of the relief sought is
          $10,000 or less you or the Company may elect to have the arbitration
          conducted by telephone or based solely on written submissions, which
          election shall be binding on you and the Company subject to the
          arbitrator's discretion to require an in-person hearing, if the
          circumstances warrant. Attendance at any in-person hearing may bemade
          by telephone by you and/or the Company, unless the arbitrator requires
          otherwise.
        </p>
        <p>
          The arbitrator, and not any federal, state or local court or agency,
          shall have exclusive authority to resolve all claims relating to or
          arising out of this contract, or the breach thereof, whether sounding
          in contract, tort, or otherwise and all disputes arising out of or
          relating to the interpretation, applicability, enforceability or
          formation of these Terms, including, but not limited to any claim that
          all or any part of these Terms are void or voidable, or whether a
          claim is subject to arbitration. The arbitrator shall be empowered to
          grant whatever relief would be available in a court under law or in
          equity. The arbitrator's award shall be written, and binding on the
          parties and may be entered as a judgment in any court of competent
          jurisdiction.
        </p>
        <p>
          The arbitrator will decide the substance of all claims in accordance
          with the laws of the State of Florida, including recognized principles
          of equity, and will honor all claims of privilege recognized by law.
          The arbitrator shall not be bound by rulings in prior arbitrations
          involving different Site users, but is bound by rulings in prior
          arbitrations involving the same user to the extent required by
          applicable law
        </p>
        <h4>13.4. Costs of Arbitration</h4>
        <p>
          Payment of all filing, administration, and arbitrator fees
          (collectively, the "Arbitration Fees") will be governed by the JAMS
          Rules, unless otherwise provided in this Agreement to Arbitrate. If
          you demonstrate to the arbitrator that you are economically unable to
          pay your portion of the Arbitration Fees or if the arbitrator
          otherwise determines for any reason that you should not be required to
          pay your portion of the Arbitration Fees, the Company will pay your
          portion of such fees. In addition, if you demonstrate to the
          arbitrator that the costs of arbitration will be prohibitive as
          compared to the costs of litigation, the Company will pay as much of
          the Arbitration Fees as the arbitrator deems necessary to prevent the
          arbitration from being cost-prohibitive. Each party will be
          responsible for all other fees it incurs in connection with the
          arbitration, including without limitation, all attorney fees. In the
          event the arbitrator determines the claim(s) you assert in the
          arbitration to be frivolous, you agree to reimburse the Company forall
          fees associated with the arbitration paid by the Company on your
          behalf that you otherwise would be obligated to pay under the JAMS
          rules.
        </p>
        <h4>13.5. Confidentiality</h4>
        <p>
          All aspects of the arbitration proceeding, and any ruling, decision or
          award by the arbitrator, will be strictly confidential for the benefit
          of all parties.
        </p>
        <h4>13.6. Opt-Out Procedure</h4>
        <p>
          You can choose to reject this Arbitration Agreement by mailing us a
          written opt-out notice ("Opt-Out Notice") in accordance with the terms
          of this Section. The Opt-Out Notice must be postmarked no later than
          30 Days after the date you use our Site or Services for the first
          time. You must mail the Opt-Out Notice to 204 37th Avenue North POBOX
          116, Saint Petersburg Florida 33703. The Opt-Out Notice must state
          that you do not agree to the Arbitration Agreement and must include
          your name, address, phone number, and the email address(es) used to
          log in to the Site. You must sign the Opt-Out Notice for it to be
          effective. This procedure is the only way you can opt out of the
          Arbitration Agreement. If you opt out of the Arbitration Agreement,
          the Company will likewise not be bound by these arbitration
          provisions. All other terms of these Terms will continue to apply.
          Opting out of the Arbitration Agreement has no effect on any previous,
          other, or future arbitration agreements that you may have with us.
          Upon receipt of a valid Opt-Out Notice, the Company will provide the
          opting out user with a copy of the arbitration agreement from the last
          version of the Terms that the user accepted, if any exists.
        </p>
        <h4>13.7. Future Changes to this Arbitration Agreement</h4>
        <p>
          Notwithstanding any provision in these Terms to the contrary, you and
          we agree that if we make any change to this Arbitration Agreement
          (other than a change to any notice address or website link provided
          herein) in the future, such change shall not be effective until at
          least 60 days from the date of posting, and shall not apply to any
          claim that was filed in a legal proceeding against the Company prior
          to the effective date of the change. Moreover, if we seek to terminate
          this Arbitration Agreement from these Terms, such termination shall
          not be effective until 30 days after the version of these Terms not
          containing the Arbitration Agreement is posted to the Site, and shall
          not be effective as to any claim that was filed in a legal proceeding
          against the Company prior to the effective date of removal.
        </p>
        <h4>13.8. Governing Law and Jurisdiction</h4>
        <p>
          These Terms, and all claims relating to or arising out of this
          contract, or the breach thereof, whether sounding in contract, tort,
          or otherwise, shall be governed by the laws of the State of Florida,
          including Florida’s statutes of limitations governing your claim,
          without giving effect to its principles of conflicts of law, provided
          that the Federal Arbitration Act shall govern the interpretation and
          enforcement of the Arbitration Agreement.
        </p>
        <p>
          Unless you and we agree otherwise, in the event that the Arbitration
          Agreement is found not to apply to you or to a particular claim or
          dispute (except for small-claims court actions), either as a result of
          your decision to opt-out of the Arbitration Agreement or as a result
          of a decision by the arbitrator or a court order, you agree that any
          claim or dispute that has arisen or may arise between you and the
          Company must be resolved exclusively by a state or federal court
          serving Pinellas County in the State of Florida.
        </p>
        <p>
          You and the Company agree to submit to the personal jurisdiction of
          the courts located within Pinellas County in the State of Florida for
          the purpose of litigating all such claims or disputes.
        </p>
        <h4>13.9. Consent to Electronic Communication</h4>
        <p>
          By using the Site or Services of the Company, you agree to allow the
          Company to communicate with you electronically, and you consent to
          electronic delivery of notices, documents, or products (including,
          without limitation, reports or copies of Background Checks and
          Preliminary Membership Screens) from the Company via the Company Site,
          mobile application, online messaging platform, or e-mail. You also
          agree to check your account, alerts, and messages, and the e-mail
          account reflected on your account on a reasonably regular basis to
          stay apprised of important notices and information about your account.
        </p>
      </div>
      <h3>14.Miscellaneous</h3>
      <p>
        Nothing in this Agreement shall be construed as making either party the
        partner, joint venturer, agent, legal representative, employer,
        contractor or employee of the other. Neither party shall have, or hold
        itself out to any third party as having any authority to make any
        statements, representations or commitments of any kind, or to take any
        action, that shall be binding on the other, except as provided for
        herein or authorized in writing by the party to be bound. The
        invalidity, illegality or unenforceability of any term or provision of
        these Terms shall in no way effect the validity, legality or
        enforceability of any other term or provision of these Terms. Each
        Affiliate (as defined in Section 1.2) is expressly made a third party
        beneficiary of this Agreement and may enforce this Agreement directly
        against you. This Agreement will be binding on and will inure to the
        benefit of the legal representatives, successors and assigns of the
        parties hereto.
      </p>
      <h3>15.Severability</h3>
      <p>
        If a court decides that any term or provision of these Terms other than
        Section 13.2 is invalid or unenforceable, the parties agree to replace
        such term or provision with a term or provision that is valid and
        enforceable and that comes closest to expressing the intention of the
        invalid or unenforceable term or provision, and these Terms shall be
        enforceable as so modified. If a court decides that any of the
        provisions of Section 13.2 are invalid or unenforceable, then the
        entirety of Section 13 shall be null and void. The remainder of the
        Terms will continue to apply.
      </p>
      <h3>16.Contact Information</h3>
      <p>
        If you have any questions or need further information as to the Site or
        Services provided by the Company, or need to notify the Company as to
        any matters relating to the Site or Services please contact the Company
        at
      </p>
      <p>204 37th Avenue North POBOX 116, Saint Petersburg Florida 33703</p>
    </div>
  );
};

export default TermsOfUsePage;
