import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import clsx from "clsx";

import SimpleFilter from "../../../components/FilterSimple/SimpleFilterComponent";
import {
  getHighlightsSearch,
  getSearchProfileById,
} from "../../../../redux/actions/searchProfiles";

//images
import logoPict from "../../../../assets/images/everything-you-need-v1.png";
import ground3 from "../../../../assets/images/girly.png";
import arrowDown from "../../../../assets/images/big-arrow-down.png";
import imgDoneWork from "../../../../assets/images/homePage/home-doneWork.jpg";
import imgCustomMatching from "../../../../assets/images/homePage/home-customMatching.jpg";
import imgBoysLooking from "../../../../assets/images/homePage/boys_looking.png";
import imgGirlLightbulb from "../../../../assets/images/girl-pulling-lightbulb.jpeg";

import highlight_camps from "../../../../assets/images/homePage/highlight_camps.jpg";
import highlight_fieldtrips from "../../../../assets/images/homePage/highlight_fieldtrips.jpg";
import highlight_facebook from "../../../../assets/images/homePage/highlight_facebook.jpg";
import highlight_homeschool from "../../../../assets/images/homePage/highlight_homeschool.jpg";
import highlight_online from "../../../../assets/images/homePage/highlight_online.jpg";
import highlight_boxes from "../../../../assets/images/homePage/highlight_boxes.jpg";

import how_ready from "../../../../assets/images/homePage/how_ready.png";
import how_set from "../../../../assets/images/homePage/how_set.png";
import how_squiggle from "../../../../assets/images/homePage/how_squiggle.png";
import how_explore from "../../../../assets/images/homePage/how_explore.png";

import yellow_check from "../../../../assets/images/homePage/yellow_check.png";
import title_everything from "../../../../assets/images/homePage/title_everything_you_need.png";

import Button from "../../../common/Button/Button";

import { getResourceTypes } from "../../../../redux/actions/resources";
import {
  choices,
  firstBlock,
  homePageBlocks,
} from "../../../../helpers/homePageValues";
import { isMobileWidth } from "../../../../helpers/values";
import { StateType } from "../../../../redux/store";

import styles from "./styles.module.scss";
import ImageBlok from "../../../components/ImageBlok/ImageBlok";

import CardSearchTeacter from "../../../components/Cards/CardSearchTeacter/CardSearchTeacter";
import CardCurriculum from "../../../components/Cards/CardCurriculum/CardCurriculum";

import ContentLoader, { Facebook } from "react-content-loader";
import HomeSignUpForm from "../../../components/HomeSignUpForm/HomeSignUpForm";

const homePageIntro = require("../../../../assets/videos/aa_homepage_intro_jan2022_720.mp4");
const homePageVideo = require("../../../../assets/videos/aa-resources-full-v1-ff.mp4");
const homePageMobileVideo = require("../../../../assets/videos/aa-resources-mobile-ff.mp4");
const homePageMessagesVideo = require("../../../../assets/videos/aa-messaging-demo-v1-ff.mp4");

type ImageBlokType = {
  title: string;
  texts: Array<{ text: string; link?: string; url?: string; label?: string }>;
  image?: string;
  video?: string;
  info_images?: Array<string>;
  button?: any;
  firstImage?: boolean;
};

const InfoBlok: React.FC<ImageBlokType> = ({
  title,
  texts,
  button,
  info_images,
}) => {
  return (
    <div className={styles.contentBlock_info}>
      <h2 className={styles.content_title}>{title}</h2>

      {texts.map(
        (
          item: { text: string; link?: string; url?: string; label?: string },
          index: number
        ) => (
          <div className={styles.content_text} key={index}>
            <div>
              <span>{item.label}</span> {item.text}
            </div>
            {item.link && (
              <a href={item.url} target="blank">
                {item.link}
              </a>
            )}
          </div>
        )
      )}

      {info_images && (
        <div className={styles.wrap_content_images}>
          {info_images?.map((item: string, index: number) => (
            <div className={styles.content_images} key={index}>
              <img src={item} alt={title} />
            </div>
          ))}
        </div>
      )}

      {button}
    </div>
  );
};

const HomePage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((state: StateType) => state.user);
  const { resourcesTypes } = useSelector((state: StateType) => state.resources);
  const [isOpenAuthModal, setIsOpenAuthModal] = useState(false);
  const [targetUrl, setTargetUrl] = useState("");

  const scrollToSignUp = (resourceType: string, resourceId: string) => {
    console.log("scrollToSignUp: " + resourceType + "-" + resourceId);
    
    if (resourceType === "LR"){
      localStorage.setItem("redirectTo", `/details/curriculums/${resourceId}/0/global`);
    } else {
      localStorage.setItem("redirectTo", `details/teachers/${resourceId}`);
    }
    
    const section = document.querySelector( '#signupform' );
    section!.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  useEffect(() => {
    if (!resourcesTypes?.length && profile) {
      dispatch(getResourceTypes());
    }
  }, []);

  const renderChoices = () => {
    return (
      <div className={styles.choiceList}>
        {choices.map((choice: any, index: number) => (
          <div key={index}>
            <img src={choice.icon} alt={choice.title} />
            <p className={styles.choiceTitle}>{choice.title}</p>
            <p className={styles.choiceText}>{choice.text}</p>
          </div>
        ))}
      </div>
    );
  };

  const onClickHandler = () => {
    history.push("/search-profile-form");
    window.scrollTo(0, 0);
  };

  const onCreateProfessionalProfile = () => {
    history.push("/auth/sign-up?type=teacher");
    window.scrollTo(0, 0);
  };

  const { highlightsMatchProfiles, isLoading } = useSelector(
    (state: StateType) => state.searchProfiles
  );

  const searchLimit = 6;

  const haveSearchResults = highlightsMatchProfiles?.is_search;

  const zeroSearchResults =
    highlightsMatchProfiles?.is_search &&
    highlightsMatchProfiles?.resourcesMatches.length == 0 &&
    highlightsMatchProfiles?.usersMatches.length == 0;


  const SearchResultsHeader = () => {
    return (
      <React.Fragment>
        {haveSearchResults &&
          (highlightsMatchProfiles?.usersMatches.length > 0 ||
            highlightsMatchProfiles?.resourcesMatches.length > 0) ? (
          <div className={styles.resultsSubTitle}>

            {Array.isArray(highlightsMatchProfiles?.resourcesMatches) &&
              highlightsMatchProfiles?.resourcesMatches.length > 0 ? (
              <div className={styles.result}>
                Showing the top{" "}
                {highlightsMatchProfiles?.resourcesMatches.length < searchLimit
                  ? highlightsMatchProfiles?.resourcesMatches.length
                  : searchLimit}{" "}
                out of {highlightsMatchProfiles?.resourcesMatches.length}{" "}
                matching resources
              </div>
            ) : null}
            {Array.isArray(highlightsMatchProfiles?.usersMatches) &&
              highlightsMatchProfiles?.usersMatches.length > 0 ? (
              <span className={styles.result}>
                Showing the top{" "}
                {highlightsMatchProfiles?.usersMatches.length < searchLimit
                  ? highlightsMatchProfiles?.usersMatches.length
                  : searchLimit}{" "}
                out of {highlightsMatchProfiles?.usersMatches.length} matching
                professional profiles
              </span>
            ) : null}
            {highlightsMatchProfiles?.usersMatches.length == 0 &&
              highlightsMatchProfiles?.resourcesMatches.length == 0 ? (
              <div className={styles.noResult}>
                We couldn't find any matches, but not to worry! Try using fewer
                keywords in your search.
              </div>
            ) : null}
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  const ResultsLoading = () => {
    return (
      <React.Fragment>
        <Facebook
          width="auto"
          height={264}
          foregroundColor={"#70b7db"}
        ></Facebook>
        <Facebook
          width="auto"
          height={264}
          foregroundColor={"#70b7db"}
        ></Facebook>
        <Facebook
          width="auto"
          height={264}
          foregroundColor={"#70b7db"}
        ></Facebook>
        <Facebook
          width="auto"
          height={264}
          foregroundColor={"#70b7db"}
        ></Facebook>
      </React.Fragment>
    );
  };

  const CardGrid = () => {
    return (
      <React.Fragment>
        <div
          className={styles.profileList}
        >
          {isLoading ? <ResultsLoading></ResultsLoading> : null}

          {!isLoading &&
            Array.isArray(highlightsMatchProfiles?.resourcesMatches) &&
            highlightsMatchProfiles?.resourcesMatches.length > 0
            ? (highlightsMatchProfiles?.resourcesMatches)
              .slice(0, searchLimit)
              .map((resource, index) => (
                <div className={styles.profileCard} onClick={() => scrollToSignUp('LR', resource.id)} >
                  <CardCurriculum
                    isFromGlobal={true}
                    setIsOpenAuthModal={setIsOpenAuthModal}
                    setTargetUrl={setTargetUrl}
                    key={index}
                    showSignUpMenu={!profile}
                    data={
                      resource.search_profile
                        ? {
                          ...resource,
                          ...resource.search_profile,
                          searchable: {
                            ...resource.search_profile,
                            ...resource,
                          },
                        }
                        : {
                          ...resource,
                          searchable: {
                            ...resource,
                          },
                        }
                    }
                  />
                </div>
              ))
            : null}

          {!isLoading &&
            Array.isArray(highlightsMatchProfiles?.usersMatches) &&
            highlightsMatchProfiles?.usersMatches.length > 0
            ? (highlightsMatchProfiles?.usersMatches)
              .slice(0, searchLimit)
              .map((user, index) => (
                <div className={styles.profileCard} onClick={() => scrollToSignUp('PRO', user.searchable_id)} >
                  <CardSearchTeacter
                    isFromGlobal={true}
                    fromProfile={!profile}
                    setIsOpenAuthModal={setIsOpenAuthModal}
                    setTargetUrl={setTargetUrl}
                    key={index}
                    data={
                      user.user
                        ? {
                          ...user.user,
                          ...user.search_profile,
                          searchable: { ...user, ...user.search_profile },
                          search_title: user?.search_title,
                        }
                        : {
                          ...user,
                          searchable: {
                            ...user,
                            user: {
                              first_name: user.first_name,
                              last_name: user.last_name,
                              plan: user.plan,
                            },
                          },
                        }
                    }
                  />
                </div>
              ))
            : null}
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Anything Academic - Your Source for Anything Academic</title>
      </Helmet>
      <div className={styles.topBlock}>
        <div className={styles.startSearchingButton}>
              <Button
                customColor="yellow"
                customSize="small"
                onClick={onClickHandler}
              >
                START SEARCHING NOW
              </Button>
            </div>

        <div className={styles.topBlockImages}>
        </div>
      </div>

      <div className={styles.maxWidthWrapper}>
      <div className={styles.startSearchingButtonMobile}>
              <Button
                customColor="yellow"
                customSize="small"
                onClick={onClickHandler}
              >
                START SEARCHING NOW
              </Button>
            </div>

        <div className={styles.wrap_contentBlock}>
          <div className={clsx(styles.contentBlock, styles.bigPadding)}>
            <div className={styles.contentBlock_views}>
              <video width="100%" height="100%" playsInline controls className={styles.rounded_video} poster={imgGirlLightbulb}>
                <source
                  src={homePageIntro}
                  type="video/mp4"
                />
              </video>
            </div>

            <div className={styles.contentBlock_info}>
              <div className={styles.content_title}>
                Life is about to get easier for your family.
              </div>
              <div className={styles.content_text}>
                <div>
                  You want to give your child the world, knowing that you’re setting them up to achieve academic success, but who has time to add anything to their plate? That’s where we come in.
                </div>
                <div className={styles.flex_row}>
                  <img src={imgDoneWork} />
                  <div>We’ve done all the work of finding every academic tool, field trip, resource, and adventure under the sun.</div>
                  <img src={imgCustomMatching} />
                  <div>Custom matching suggestions based on your child’s unique interests and dreams.</div>
                </div>
                <div>
                  Spend less time researching and more time doing all the things you love.  Bring on the snuggle sessions, movie nights, and nature walks because learning happens everywhere! Let us help you find it!
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.highlight_title}>
          Here is a little peek at what is waiting for you to discover:
        </div>

        <div className={styles.highlight_grid}>
          <div><img src={highlight_camps} /></div>
          <div><img src={highlight_fieldtrips} /></div>
          <div><img src={highlight_facebook} /></div>
          <div><img src={highlight_homeschool} /></div>
          <div><img src={highlight_online} /></div>
          <div><img src={highlight_boxes} /></div>
        </div>

        <div className={styles.highlight_title}>
          How does it all work?
        </div>

        <div className={styles.how_block}>
          <div className={styles.how_video}>
            <video width="100%" height="100%" autoPlay loop muted playsInline>
              <source
                src={isMobileWidth ? homePageMobileVideo : homePageVideo}
                type="video/mp4"
              />
            </video>
          </div>
          <div className={styles.ready_set}>
            <div className={styles.ready}>
              <img src={how_ready} />
            </div>
            <div>
              <p>Take just a few minutes to <span className={styles.text_highlight}>personalize a learning adventure for one or multiple students</span> by creating profiles for each of them and let the magic begin.
                Our powerful search tool has a little something for everyone; every age, subject and learning style.</p>
              <p><img src={yellow_check} />Traditional schoolers?</p>
              <p><img src={yellow_check} />Homeschoolers?</p>
              <p><img src={yellow_check} />Somewhere outside the lines?</p>
              <p>Yep, we’ve thought of you too. Wherever your family is on your learning journey is a great time to start exploring.</p>
            </div>
            <div className={styles.set}>
              <img src={how_squiggle} className={styles.squiggle} />
              <div>
                <img src={how_set} />
                <p>Academic success takes a village. After you’ve taken our search for a spin, connect with our huge community of others who are navigating this learning journey at the same time.
                  Members have access to customized recommendations of online communities and our easy-to-use, secure live chat that connects you with top-rated teachers, tutors and counselors
                  based on the profiles of your student(s). You have an entire community rooting for you.</p>
              </div>
            </div>
            <div className={styles.explore}>
              <img src={how_explore} />
              <p>Your child’s next academic adventure, inspiring curriculum, unexpectedly awesome field trip and so much more; it’s all waiting for you to discover.
              <span className={styles.text_highlight}> All you have to do is start searching.</span>&nbsp;
                Let the fun begin with your first search and then be sure to visit often; we are regularly adding the latest and greatest finds to make that happen.  </p>
            </div>
          </div>
        </div>

        <div className={styles.highlight_title}>
          <img src={title_everything} />
        </div>

        <div className={styles.searchBlock}>
          <div className={styles.searchForm}>
            <div className={styles.searchTitle}>TAKE A PEAK AND START SEARCHING NOW!</div>
            <SimpleFilter />

            <div className={styles.signUpButton}>
              <Button
                customColor="yellow"
                customSize="small"
                onClick={onClickHandler}
              >
                Sign Up for a Free account to access everything!
              </Button>
            </div>
          </div>
          <SearchResultsHeader></SearchResultsHeader>
          {!zeroSearchResults ? <CardGrid></CardGrid> : null}
        </div>

        <div className={styles.wrap_contentBlock}>
          <div className={clsx(styles.contentBlock, styles.bigPadding)}>
            <div className={styles.contentBlock_views}>
              <img src={imgBoysLooking} />
            </div>

            <div className={styles.contentBlock_info}>
              <div className={styles.content_title}>
                The Anything Academic Philosophy
              </div>
              <div className={styles.content_text}>
                <div>
                  We believe opportunities to open minds and build bright futures transcend textbooks, classrooms and school schedules. Learning opens up a world of possibility for your children and every moment can have a little bit of learning magic in it for anyone, anytime and anywhere. All you need is to find the next greatest thing that will spark your students’ curiosity and then keep it illuminated for life. Let us open up limitless possibilities by connecting you with every company, tool and resource you didn’t even know you were missing in your family’s life.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.bottomBlock} id="signupform">
          <HomeSignUpForm></HomeSignUpForm>
        </div>

      </div>
    </div>
  );
};

export default HomePage;
