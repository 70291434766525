import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Form,
  reduxForm,
  InjectedFormProps,
  reset,
  Field,
  FormSection,
  getFormValues,
} from "redux-form";

import Input from "../../common/Input/Input";
import Checkbox from "../../common/Checkbox/Checkbox";
import Selecter from "../../common/Selecter/Selecter";
import Button from "../../common/Button/Button";
import RadioButton from "../../common/Radiobutton/Radiobutton";

import { customRequired, required } from "../../../helpers/validate";
import styles from "./styles.module.scss";
import { StateType } from "../../../redux/store";
import {
  getHighlights,
  getHighlightsSearch,
} from "../../../redux/actions/searchProfiles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";


interface StateProps {
  formValues: any;
}

interface Props extends StateProps {

}

const SimpleFilter: React.FC<Props & InjectedFormProps<{}, Props, string>> = ({
  handleSubmit,
  formValues,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { searchProfile } = useSelector(
    (state: StateType) => state.searchProfiles
  );
  const [typeError, setTypeError] = useState('')


  const handleSubmitHandler = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!formValues?.search_type) {
      setTypeError('Please, select checkbox')
    }
    handleSubmit(() => {
      //@ts-ignore
      dispatch(getHighlightsSearch({ type: formValues.search_type, search: formValues.search }))
    })();
  };

  return (
    <Form onSubmit={handleSubmitHandler}>
      <div className={styles.wrapper_filterBlock}>
        <div className={styles.searchRow}>
          <div className={styles.blockTitle}>
            Search
          </div>

          <div className={styles.searchBox}>
         
          <div className={styles.searchText}>
            <Field
              classes={{ root: styles.search_box }}
              variant="filled"
              component={Input}
              name="search"
              placeholder="Enter keywords"
              onEnterPress={handleSubmitHandler}
              validate={[customRequired]}
              bigIndex={true}
              noMargin={true}
            />
          </div>
          <Button type="submit" customColor="blue" noMargin>
            <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
          </Button>
          </div>

          <div className={styles.searchFilter}>
            <Field
              component={Selecter}
              name="search_type"
              options={[
                { value: "all", text: "Resources & Pros" },
                { value: "resource", text: "Only Resources" },
                { value: "teacher", text: "Only Pros" }
              ]}
              defaultValue="all"
              nullIsAny
              noMargin
            />
            <p className={styles.typeError}>{typeError}</p>
          </div>
        </div>
      </div>
    </Form>
  );
};

const mapStateToProps = (state: StateType): StateProps => ({
  formValues: getFormValues("globalSearchFilterForm")(state),
});

export default connect(mapStateToProps)(
  reduxForm<{}, Props>({
    form: "globalSearchFilterForm",
    destroyOnUnmount: false,
  })(SimpleFilter)
);
