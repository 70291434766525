import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReviewType, RequestReviewType } from "../../types/reviews";
import api from "../../api";

export const getReviews = createAsyncThunk("reviews/get-list", async () => {
  try {
    const {
      data,
    }: { data: Array<ReviewType> } = await api.reviews.getReviews();
    return data;
  } catch (err) {
    return err.response.data;
  }
});

export const getReviewById = createAsyncThunk(
  "reviews/get-by-id",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: ReviewType } = await api.reviews.getReviewById(
        id
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getReviewsByTypeId = createAsyncThunk(
  "reviews/get-by-type-id",
  async ({ id, type }: { id: number; type: string }, thunkAPI) => {
    try {
      const {
        data,
      }: { data: ReviewType } = await api.reviews.getReviewByTypeId({
        id,
        type,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createReview = createAsyncThunk(
  "reviews/create",
  async (
    {
      reviewable_id,
      type,
      values,
    }: { reviewable_id: number; type: string; values: RequestReviewType },
    thunkAPI
  ) => {
    try {
      const { data }: { data: ReviewType } = await api.reviews.createReview({
        reviewable_id,
        type,
        values,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateReview = createAsyncThunk(
  "reviews/update",
  async (
    { id, values }: { id: number; values: RequestReviewType },
    thunkAPI
  ) => {
    try {
      const { data }: { data: ReviewType } = await api.reviews.updateReview({
        id,
        values,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteReview = createAsyncThunk(
  "reviews/delete",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: string } = await api.reviews.deleteReview(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
