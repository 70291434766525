import { createSlice } from "@reduxjs/toolkit";
import { InitianlReviewsType } from "../../types/reviews";
import {
  getReviews,
  getReviewById,
  getReviewsByTypeId,
  createReview,
  updateReview,
  deleteReview,
} from "../actions/reviews";
import { message } from "../../helpers/notifications";

const initialState: InitianlReviewsType = {
  review: null,
  reviews: null,
  isLoading: false,
  errors: null,
};

const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {},
  extraReducers: {
    [getReviews.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getReviews.fulfilled.toString()]: (state, { payload }) => {
      state.reviews = payload;
      state.isLoading = false;
    },
    [getReviews.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [getReviewById.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getReviewById.fulfilled.toString()]: (state, { payload }) => {
      state.review = payload;
      state.isLoading = false;
    },
    [getReviewById.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [getReviewsByTypeId.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getReviewsByTypeId.fulfilled.toString()]: (state, { payload }) => {
      state.reviews = payload;
      state.isLoading = false;
    },
    [getReviewsByTypeId.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [createReview.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [createReview.fulfilled.toString()]: (state, { payload }) => {
      state.review = payload;
      state.isLoading = false;
    },
    [createReview.rejected.toString()]: (state, { payload }) => {
      if (payload?.message === "User already reviewed this item.") {
        message.error(payload?.message);
      }
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [updateReview.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [updateReview.fulfilled.toString()]: (state, { payload }) => {
      state.review = payload;
      state.isLoading = false;
    },
    [updateReview.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [deleteReview.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [deleteReview.fulfilled.toString()]: (state, { payload }) => {
      state.review = null;
      state.isLoading = false;
    },
    [deleteReview.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
  },
});

export default reviewsSlice.reducer;
