import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  filtersList: any;
  checkFilters: boolean;
  currentPage: number;
} = {
  filtersList: [],
  checkFilters: false,
  currentPage: 1,
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setFilters(state, { payload }) {
      return {
        ...state,
        checkFilters: true,
        filtersList: [...payload.data],
      };
    },
    setCurrentPage(state, { payload }) {
      return {
        ...state,
        currentPage: payload.data,
      };
    },

    resetFilters(state) {
      return {
        ...state,
        filtersList: [null],
      };
    },
  },
});

export const {
  setFilters,
  resetFilters,
  setCurrentPage,
} = filtersSlice.actions;

export default filtersSlice.reducer;
