import { createSlice } from "@reduxjs/toolkit";
import { InitialSubjectType } from "../../types/subjects";
import { getSubjects, getSubjectById } from "../actions/subjects";

const initialState: InitialSubjectType = {
  subject: null,
  subjects: null,
  isLoading: false,
  errors: null,
};

const subjectsSlice = createSlice({
  name: "subjects",
  initialState,
  reducers: {
    setStateSubject(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
  },
  extraReducers: {
    [getSubjects.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getSubjects.fulfilled.toString()]: (state, { payload }) => {
      state.subjects = payload;
      state.isLoading = false;
    },
    [getSubjects.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },

    [getSubjectById.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getSubjectById.fulfilled.toString()]: (state, { payload }) => {
      state.subject = payload;
      state.isLoading = false;
    },
    [getSubjectById.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
  },
});

export default subjectsSlice.reducer;
