import { createSlice } from "@reduxjs/toolkit";
import { InitialSubscriptionsType } from "../../types/subscriptions";
import {
  GetApiDetails,
  CreateSubscription,
  CreatePaymentMethod,
  getTokenForWordpress,
  CancelSubscription,
} from "../actions/subscriptions";
import { Cookies } from "react-cookie-consent";
import { message } from "../../helpers/notifications";

const initialState: InitialSubscriptionsType = {
  change_payment_success: false,
  has_payment_method: false,
  api_details: null,
  payment_method: null,
  isLoading: false,
  errors: null,
  redirectLink: null,
};

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setRedirectUrl(state, { payload }) {
      return {
        ...state,
        redirectLink: payload,
      };
    },
    resetHasPaymentMethod(state) {
      return {
        ...state,
        has_payment_method: false,
      };
    },
    resetChangePayment(state) {
      return {
        ...state,
        change_payment_success: false,
      };
    },
    resetSubscriptionsStore() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    [GetApiDetails.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [GetApiDetails.fulfilled.toString()]: (state, { payload }) => {
      state.api_details = payload;
      state.isLoading = false;
    },
    [GetApiDetails.rejected.toString()]: (state, { payload }) => {
      state.errors = payload && payload.errors;
      state.isLoading = false;
    },

    [CreateSubscription.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [CreateSubscription.fulfilled.toString()]: (state) => {
      state.has_payment_method = true;
      state.isLoading = false;
    },
    [CreateSubscription.rejected.toString()]: (state, { payload }) => {
      state.errors = payload.errors;
      state.isLoading = false;
    },
    [CreatePaymentMethod.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [CreatePaymentMethod.fulfilled.toString()]: (state, { payload }) => {
      state.change_payment_success = true;
      state.payment_method = payload;
      state.isLoading = false;
    },
    [CreatePaymentMethod.rejected.toString()]: (state, { payload }) => {
      state.change_payment_success = false;
      message.error(payload.message || "Something went wrong");
      state.errors = payload.errors;
      state.isLoading = false;
    },

    [getTokenForWordpress.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getTokenForWordpress.fulfilled.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
      const cookies = JSON.parse(payload);

      Cookies.set("access_token", cookies.access_token, {
        domain: `${process.env.REACT_APP_WP_DOMAIN}`,
      });
      if (state?.redirectLink) {
        window.location.href = state.redirectLink;
      }
    },
    [getTokenForWordpress.rejected.toString()]: (state, { payload }) => {
      state.errors = payload.errors;
      state.isLoading = false;
    },
  },
});

export const {
  setRedirectUrl,
  resetHasPaymentMethod,
  resetSubscriptionsStore,
  resetChangePayment,
} = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
