import moment from "moment";
import { HOURLY_RATE_MAX } from "./constants";

export const required = (value: string) =>
  (value && typeof value === "string" && !value.match(/^\s+$/)) ||
  (value && typeof value !== "string")
    ? undefined
    : "Required";

export const customRequired = (value: string) =>
  (value && typeof value === "string" && !value.match(/^\s+$/)) ||
  (value && typeof value !== "string")
    ? undefined
    : "Please enter a search keyword";

export const email = (value: string) =>
  value === "" ||
  value === null ||
  value === undefined ||
  (value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
    ? undefined
    : "Enter a valid e-mail address";

export const password = (value: string) =>
  value === "" ||
  value === null ||
  value === undefined ||
  (value && value.length >= 8)
    ? undefined
    : "The password must be at least 8 characters.";

export const confirm_password = (value: string, form: any) =>
  value === form.password ? undefined : "Passwords must match.";

export const zip = (value: string, form: any) =>
  value === "" ||
  value === null ||
  value === undefined ||
  (value.length === 5 && !/\D/.test(value))
    ? undefined
    : "Enter a valid zip";

export const valid_zip = (value: string, form: any) =>
  form?.valid_zip ? undefined : "Zip Code Required for USA";

export const maxLength = (max: number) => (value: string) =>
  value === "" ||
  value === null ||
  value === undefined ||
  (value && value.length <= max)
    ? undefined
    : `Must be no more than ${max} characters`;

export const maxLength255 = maxLength(255);

export const maxLength1200 = maxLength(1200);

export const maxLength30 = maxLength(30);

export const maxLength50 = maxLength(50);

export const minDateNow = (value: string) =>
  !value || value >= moment(Date.now()).format("YYYY-MM-DD")
    ? undefined
    : `Date must be today or later`;

export const website = (value: string) =>
  value === "" ||
  value === null ||
  value === undefined ||
  (value &&
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
      value
    ))
    ? undefined
    : "Enter a valid website";

export const maxBudget = (value: string) =>
  value === "" ||
  value === null ||
  value === undefined ||
  (value && +value <= HOURLY_RATE_MAX)
    ? undefined
    : `The budget must be less than ${HOURLY_RATE_MAX}`;

export const max21 = (value: string) =>
  value === "" ||
  value === null ||
  value === undefined ||
  (value && +value <= 21)
    ? undefined
    : `The value must be less 21`;
