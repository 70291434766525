import React from "react";
import { Link } from "react-router-dom";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import clsx from "clsx";

import Tooltip from "../../components/Toopltip/Toopltip";

import styles from "./styles.module.scss";

type PropsType = {
  url: string;
  img?: string;
  name: string;
  tooltip?: string;
  active?: boolean;
  target?: string;
  onClick?: any;
};

const mobile = window.innerWidth <= 1024;

const SidebarMainButton: React.FC<PropsType> = ({
  url,
  name,
  img,
  target,
  tooltip,
  active,
  onClick,
}) => (
  <>
    {!!tooltip ? (
      <>
        {mobile ? (
          <div
            className={clsx(styles.wrapper_link, {
              [styles.active]: active,
            })}
          >
            <Link
              className={
                active ? styles.activePremiumButton : styles.premiumButton
              }
              to={url.includes("https://") ? { pathname: url } : url}
              target={target}
            >
              <div className={styles.premiumButtonWrapper} onClick={onClick}>
                <div className={styles.premiumIconWrapper}>
                  {img ? (
                    <img src={img} className={styles.premiumIcon} alt="" />
                  ) : null}
                </div>
                <p className={styles.premiumButtonText}>{name}</p>
              </div>
            </Link>

            {/* <Tooltip interactive title={tooltip}>
              <InfoOutlinedIcon />
            </Tooltip> */}
          </div>
        ) : (
          <Tooltip interactive title={tooltip} isLeftSidebar>
            <Link
              className={
                active ? styles.activePremiumButton : styles.premiumButton
              }
              to={url.includes("https://") ? { pathname: url } : url}
              target={target}
              onClick={onClick}
            >
              <div className={styles.premiumButtonWrapper}>
                <div className={styles.premiumIconWrapper}>
                  {img ? (
                    <img src={img} className={styles.premiumIcon} alt="" />
                  ) : null}
                </div>
                <p className={styles.premiumButtonText}>{name}</p>
              </div>
            </Link>
          </Tooltip>
        )}
      </>
    ) : (
      <Link
        className={active ? styles.activePremiumButton : styles.premiumButton}
        to={url.includes("https://") ? { pathname: url } : url}
        target={target}
      >
        <div className={styles.premiumButtonWrapper} onClick={onClick}>
          <div className={styles.premiumIconWrapper}>
            {img ? (
              <img src={img} className={styles.premiumIcon} alt="" />
            ) : null}
          </div>
          <p className={styles.premiumButtonText}>{name}</p>
        </div>
      </Link>
    )}
  </>
);

export default SidebarMainButton;
