import React from "react";
import {Helmet} from "react-helmet";

const Soon: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Coming Soon on Anything Academic</title>
      </Helmet>
      <h1>Coming soon</h1>
    </div>
  );
};

export default Soon;
