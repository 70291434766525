import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { StateType } from "../redux/store";

const DefaultRoute: React.FC = () => {
  const { profile } = useSelector((state: StateType) => state.user);
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const url = params.get("redirect_url");
  let redirectLinkAfterRegister = localStorage.getItem("redirectTo");
  let pathNameToRedirectAfterLogin = localStorage.getItem(
    "pathnameToRedirectAfterLogin"
  );

  if (url) {
    localStorage.setItem("redirect_url", url);
  }

  if (profile && redirectLinkAfterRegister) {
    return <Redirect to={`${redirectLinkAfterRegister}`} />;
  } else if (!profile && pathNameToRedirectAfterLogin) {
    return <Redirect to={"/get-started"} />;
  } else if (profile) {
    return <Redirect to={"/user/my-search-profiles"} />;
  } else {
    return <Redirect to={"/home"} />;
  }
};

export default DefaultRoute;
