import { createSlice } from "@reduxjs/toolkit";
import { InitialSchoolType } from "../../types/schools";
import { getSchools, getSchoolById } from "../actions/schools";

const initialState: InitialSchoolType = {
  school: null,
  schools: null,
  isLoading: false,
  errors: null,
};

const schoolsSlice = createSlice({
  name: "schools",
  initialState,
  reducers: {},
  extraReducers: {
    [getSchools.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getSchools.fulfilled.toString()]: (state, { payload }) => {
      state.schools = payload;
      state.isLoading = false;
    },
    [getSchools.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },

    [getSchoolById.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getSchoolById.fulfilled.toString()]: (state, { payload }) => {
      state.school = payload;
      state.isLoading = false;
    },
    [getSchoolById.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
  },
});

export default schoolsSlice.reducer;
