import { createSlice } from "@reduxjs/toolkit";

import { getUsers, getUserById } from "../actions/users";
import { InitialUsersType } from "../../types/users";

const initialState: InitialUsersType = {
  users: null,
  user: null,
  isLoading: false,
  errors: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setStateValue(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
    ressetUsers(state) {
      return {
        ...state,
        users: null,
        user: null,
      };
    },
  },
  extraReducers: {
    [getUsers.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getUsers.fulfilled.toString()]: (state, { payload }) => {
      state.users = payload;
      state.isLoading = false;
    },
    [getUsers.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [getUserById.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getUserById.fulfilled.toString()]: (state, { payload }) => {
      state.user = payload;
      state.isLoading = false;
    },
    [getUserById.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
  },
});

export const { setStateValue, ressetUsers } = usersSlice.actions;

export default usersSlice.reducer;
