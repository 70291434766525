import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import {
  SignUpType,
  SignInType,
  ForgotType,
  ResetType,
} from "../../types/auth";

export const signUp = createAsyncThunk(
  "user/sign-up",
  async (
    {
      first_name,
      last_name,
      password,
      email,
      what_profile_choose,
    }: SignUpType | any,
    thunkAPI
  ) => {
    try {
      const { data } = await api.auth.signUp({
        first_name,
        last_name,
        password,
        email,
      });
      return { data, redirectTo: what_profile_choose };
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const signIn = createAsyncThunk(
  "users/sign-in",
  async ({ password, email, what_profile_choose }: SignInType, thunkAPI) => {
    try {
      const { data } = await api.auth.signIn({ password, email });
      return { data, redirectTo: what_profile_choose };
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const forgotPass = createAsyncThunk(
  "users/forgot-password",
  async (values: ForgotType | {}, thunkAPI) => {
    try {
      const { data } = await api.auth.forgotPass(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const resetPass = createAsyncThunk(
  "users/reset-password",
  async (values: ResetType | {}, thunkAPI) => {
    try {
      const { data } = await api.auth.resetPass(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
