import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { UserType } from "../../types/users";

export const getUsers = createAsyncThunk("users/getUsers", async () => {
  try {
    const { data }: { data: Array<UserType> } = await api.users.getUsers();
    return data;
  } catch (err) {
    return err.response.data;
  }
});

export const getUserById = createAsyncThunk(
  "users/getUserById",
  async (id: number) => {
    try {
      const { data }: { data: UserType } = await api.users.getUserById(id);
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);
