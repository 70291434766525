import React from "react";
import { Button as MainButton, ButtonProps } from "@material-ui/core";
import clsx from "clsx";

import styles from "./styles.module.scss";

type PropsType = {
  active?: boolean;
  noMargin?: boolean;
  customSize?: "big" | "medium" | "small" | "tiny";
  customColor?:
    | "blue"
    | "darkBlue"
    | "navi"
    | "red"
    | "yellow"
    | "outlinedRed"
    | "outlinedBlack";
  type?: "button" | "submit" | "reset";
};

const Button: React.FC<ButtonProps & PropsType> = ({
  variant,
  color,
  children,
  disabled,
  onClick,
  active,
  noMargin,
  customSize,
  customColor,
  type = "button",
}) => (
  <div className={styles.wrapper}>
    <MainButton
      variant={variant}
      color={color}
      className={clsx({
        [styles.active]: active,
        [styles.noMargin]: noMargin,
        [styles.big]: customSize === "big",
        [styles.medium]: customSize === "medium",
        [styles.small]: customSize === "small",
        [styles.tiny]: customSize === "tiny",
        [styles.blue]: customColor === "blue",
        [styles.darkBlue]: customColor === "darkBlue",
        [styles.navi]: customColor === "navi",
        [styles.red]: customColor === "red",
        [styles.yellow]: customColor === "yellow",
        [styles.outlinedRed]: customColor === "outlinedRed",
        [styles.outlinedBlack]: customColor === "outlinedBlack",
      })}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </MainButton>
  </div>
);

export default Button;
