import React from "react";
import styles from "./styles.module.scss";

const PageNotAvailable: React.FC = () => {
  localStorage.removeItem("redirectTo");
  return (
    <div className={styles.wrapper}>
      <h2>This page is not accessible to your account</h2>
    </div>
  );
};

export default PageNotAvailable;
