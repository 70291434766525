import React, { MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import favoriteActiveIcon from "../../../../../assets/images/cardIcons/favoriteActive.png";
import favoriteIcon from "../../../../../assets/images/cardIcons/favorite.png";
import Popup from "../../../../components/Popup/Popup";

import {
  addFavorite,
  removeFavorite,
} from "../../../../../redux/actions/favorites";
import { PLAN_SUBSCRIPTION } from "../../../../../helpers/constants";
import store, { StateType } from "../../../../../redux/store";

import styles from "./styles.module.scss";

type StatePropsType = {
  profileId: number;
  isFavorite: boolean;
  aligned?: "top" | "bottom";
  disabled?: boolean;
  setIsOpenAuthModal?: (e:boolean) => void;
};

const FavoriteButton: React.FC<StatePropsType> = ({
  profileId,
  isFavorite,
  aligned,
  disabled,
  setIsOpenAuthModal,
}) => {
  type AppDispatch = typeof store.dispatch;
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();

  const { profile } = useSelector((state: StateType) => state.user);

  const [isFavoriteState, setIsFavoriteState] = useState(isFavorite);

  useEffect(() => {
    setIsFavoriteState(isFavorite);
    return () => {
      setIsFavoriteState(false);
    };
  }, [isFavorite]);

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();

    if(disabled){
       setIsOpenAuthModal && setIsOpenAuthModal(true)
      return
    }

    if (isFavoriteState) {
      dispatch(removeFavorite(profileId)).then(
        (res: any) => !res.error && setIsFavoriteState(false)
      );
    } else {
      dispatch(addFavorite(profileId)).then(
        (res: any) => !res.error && setIsFavoriteState(true)
      );
    }
  };

  const upgradeClickHandler = (e: MouseEvent) => {
    e.stopPropagation();
    history.push("/user/subscription");
  };

  return (
    <div
      className={clsx(styles.wrap, {
        [styles.topAligned]: aligned === "top",
      })}
    >
      {profile?.plan === PLAN_SUBSCRIPTION.FREE ? (
        <Popup
          isStopPropagation={true}
          button={<img src={favoriteIcon} alt="favourite" />}
        >
          <div className={styles.popup}>
            <p> Favorites is a Premium Feature.</p>
            <p> Would you like to upgrade?</p>
            <span className="link" onClick={upgradeClickHandler}>
              Click here to upgrade.
            </span>
          </div>
        </Popup>
      ) : (
        <div
          title="Favorite"
          className={clsx(styles.favBtn, {
            [styles.topAlignedBt]: aligned === "top",
          })}
          onClick={handleClick}
        >
          {isFavoriteState ? (
            <img src={favoriteActiveIcon} alt="" />
          ) : (
            <img src={favoriteIcon} alt="" />
          )}
        </div>
      )}
    </div>
  );
};
export default FavoriteButton;
