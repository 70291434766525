import { createAsyncThunk } from "@reduxjs/toolkit";
import { PlanType } from "../../types/plans";
import api from "../../api";

export const getPlans = createAsyncThunk("plans/get-list", async () => {
  try {
    const { data }: { data: Array<PlanType> } = await api.plans.getPlans();
    return data;
  } catch (err) {
    return err.response.data;
  }
});
