import discover from "../assets/images/discover.jpg";
import know from "../assets/images/know.jpg";
import possible from "../assets/images/possible.jpg";
import community from "../assets/images/homePage/aa-landing-community.jpg";
import fieldtrip from "../assets/images/homePage/aa-landing-fieldtrip.png";
import library from "../assets/images/homePage/aa-landing-library.jpg";
import news from "../assets/images/homePage/aa-landing-news.png";
import professional from "../assets/images/homePage/aa-landing-professional.png";
import review from "../assets/images/homePage/aa-landing-reviews-v3.jpg";
import message from "../assets/images/homePage/aa-landing-message.jpg";

export const choices = [
  {
    icon: discover,
    title: "Discover What You Need",
    text:
      "Learning opportunities are as numerous as the stars in the sky. We personalize your search to fit your specific needs. Why? Because your journey should be as original as you are.",
  },
  {
    icon: possible,
    title: "See What’s Possible",
    text:
      "Brush up on your acting skills, go on a virtual safari, find an inspiring teacher or explore an incredible class. Even if you don’t know exactly what you are looking for, we will help you find it.",
  },
  {
    icon: know,
    title: "Know More",
    text:
      "You have questions. We have answers. We provide articles spanning countless subjects. Read up on how to create your own business, what to look for in a teacher, and so much more.",
  },
];

export const firstBlock = {
  flexColumn: false,
  title: "The number of learning options out there is overwhelming.",
  texts: [
    {
      text: "Your time is valuable.",
    },
    {
      text:
        "Our personalized search will find in minutes what would take hours searching countless sites on your own.",
    },
    {
      label: "10,000+ Summer Camps",
      text: "for Sports, Adventure, Academics, and so much more.",
    },
    {
      label: "11,000+ Field Trips",
      text: "nationwide, lets's find one near you.",
    },
    {
      label: "1,450+ Homeschool Curriculums",
      text: "spanning every subject.",
    },
    {
      label: "180+ Monthly Enrichment Boxes",
      text: "packed with fun and education.",
    },
    {
      label: "750+ Fully Accredited Online Schools",
      text: "bringing the best of virtual education to your door.",
    },
    {
      label: "2,150+ Co-ops & Facebook Groups",
      text:
        "searchable by area and focus so you can find the perfect community support.",
    },
    {
      label: "1,500+ Learning Resources",
      text: "including e-Learning options, books, games, planners, and more.",
    },
  ],
};

export const secondBlock = {
  flexColumn: false,
  title: "Are you a professional looking to connect?",
  texts: [
    {
      text:
        "Create your Professional profile for free so our members can be matched with you!",
    },
    {
      text:
        "Plus secure, private chat for teachers, tutors, counselors, parents, and students.",
    },
  ],
  image: message,
};

export const thirdBlock = {
  flexColumn: true,
  title: "A community you can count on",
  texts: [
    {
      text:
        "We all know it takes a village and we want you to be a part of building this community.",
    },
    {
      text:
        "Our members love to learn, share, communicate, and support each other.",
    },
  ],
  image: community,
};

export const fourthBlock = {
  flexColumn: true,
  title: "Reviews",
  texts: [
    { text: "Our members' knowledge is our most valuable resource." },
    { text: "Find reviews on learning resources, teachers, camps, field trips and more!" },
  ],
  image: review,
};

export const fifthBlock = {
  flexColumn: false,
  title: "A living library",
  texts: [
    {
      text: "Education is never static, and neither are we.",
      link: "Explore our Library",
      url: "https://library.anythingacademic.com",
    },
    { text: "Our members share the best ideas!" },
    {
      text: "Let us know about an app, curriculum, or field trip you love.",
      link: "Share a Resource with Us",
      url: "https://library.anythingacademic.com/submit-a-resource/",
    },
  ],
  image: library,
};

export const homePageBlocks = [
  secondBlock,
  thirdBlock,
  fourthBlock,
  fifthBlock,
];
