export const clientIdGoogleLogin = `${process.env.REACT_APP_CLIENT_ID_GOOGLE_LOGIN}`;
export const appIdFacebookLogin = `${process.env.REACT_APP_CLIENT_ID_FACEBOOK_LOGIN}`;
export const recaptchaSitekey = `${process.env.REACT_APP_RECAPTCHA_SITEKEY}`;
export const hostName = window.location.origin;
export const ROLES = {
  TEACHER: "teacher",
  PARENT: "parent",
  VENDOR: "vendor",
};

export const PLAN_SUBSCRIPTION = {
  FREE: "free",
  FAMILY: "family",
  PREMIUM: "premium",
  BUSINESS: "diamond",
};

export const PLAN_SUBSCRIPTION_NAME = {
  MONTHLY: "monthly",
  QUATERLY: "quarterly",
  ANNUAL: "annual",
};

export const EVENTS_SOCKETS = {
  CHAT_NEW_MESSAGE: "App\\Events\\ChatNewMessage",
  USER_BLOKED_CHAT: "App\\Events\\UserBlockedChat",
  CHAT_TAB_UPDATE: "App\\Events\\ChatTabUpdate",
  BADGE_UPDATE: "App\\Events\\UpdateMessagesCenterBadge",
};

// export const TEACHING_TYPES = ["In Person", "Virtual/Online", "hybrid"];
export const TEACHING_TYPES = ["in person/in home", "virtual/online", "hybrid"];

export const TEACHER_AGES = ["18-21", "22-25", "26 + over"];

export const TEACHER_EDUCATION = [
  "High School",
  "College",
  "Masters",
  "PHD",
  "MD",
];

export const HidedYouTubeLinks = [
  "co-op",
  "facebook",
  "best recommendation site",
  "umbrella school",
  "youtube learning",
  "credential verification",
  "research study",
];

export const TEACHER_EXPERIENCE = [
  { value: "None", text: "None" },
  { value: "<5", text: "<5 years" },
  { value: "5+", text: "5+ years" },
];
export const TEACHER_GENDER = ["Male", "Female"];
export const PROGRAM_DURATION = ["5 Weeks", "6 Weeks", "Semester"];
export const POD_TYPE = ["Social", "Study"];
export const COVID_STUFF = ["1", "2", "3", "4", "5"];
export const QUANTITY_KIDS = ["1", "2-3", "4-6", "+6"];
export const TEACHER_DISTANCE = [
  "5 miles",
  "10 miles",
  "20 miles",
  "+30 miles",
];
export const CLASS_OPTIONS = [
  "All Classes",
  "Afterschool Activities",
  "Sports Teams",
  "Homeschool Educational",
  "Co-op",
];
export const RESOURCE_TYPE = [
  "All-in-One (first at top)",
  "Computer / Online",
  "Book/Literature",
  "Workbooks",
  "Textbook",
  "Documentary/Video",
  "Apps",
  "Printables",
  "LapBooks",
  "Activities",
  "Webinar/webcast",
  "Lesson Plans",
  "Bundles",
  "Educational Toys",
  "Educational Games",
  "Science Kits",
  "Monthly Subscription Learning Boxes",
  "Movies",
  "TV",
  "Music",
  "Assessments/Evaluations",
  "Organizational tools",
  "Planners",
  "Collaborative tools",
  "Teaching tools",
  "Teachers Manuals",
  "Teachers Resources",
];

export const PAGINATION = {
  COUNT: 5,
  VIEW_ITEMS: 50,
};

export const COSTPERSESSIONPANGE = [
  null,
  "Free",
  "0-$25",
  "$26-$50",
  "$51-$100",
  "$101-$250",
  "$250+",
];

export const CAMPER_GENDER = ["Male Only", "Female Only", "No Restriction"];

export const HOURLY_RATE_MAX = 100;
