import { createSlice } from "@reduxjs/toolkit";
import { InitialCurriculumType } from "../../types/curriculums";
import { getCurriculums, getCurriculumById } from "../actions/curriculums";

const initialState: InitialCurriculumType = {
  curriculum: null,
  curriculums: null,
  isLoading: false,
  errors: null,
};

const curriculumsSlice = createSlice({
  name: "curriculums",
  initialState,
  reducers: {},
  extraReducers: {
    [getCurriculums.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getCurriculums.fulfilled.toString()]: (state, { payload }) => {
      state.curriculums = payload;
      state.isLoading = false;
    },
    [getCurriculums.rejected.toString()]: (state, { payload }) => {
      state.errors = payload.errors;
      state.isLoading = false;
    },

    [getCurriculumById.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getCurriculumById.fulfilled.toString()]: (state, { payload }) => {
      state.curriculum = payload;
      state.isLoading = false;
    },
    [getCurriculumById.rejected.toString()]: (state, { payload }) => {
      state.errors = payload.errors;
      state.isLoading = false;
    },
  },
});

export default curriculumsSlice.reducer;
