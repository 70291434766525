import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import BusinessMembershipPage from "./BusinessMembershipPage";
import BusinessMembershipGuestPage from "./BusinessMembershipGuestPage";
import {
  GetApiDetails,
  CreatePaymentMethod,
  CreateSubscription,
} from "../../../../redux/actions/subscriptions";
import { getProfile } from "../../../../redux/actions/user";
import { getPlans } from "../../../../redux/actions/plans";
import { setStatePlanId } from "../../../../redux/reducers/plans";
import { StateType } from "../../../../redux/store";

import styles from "./styles.module.scss";

type ParamTypes = {};

const BusinessMembership: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { profile } = useSelector((state: StateType) => state.user);
  const { plan_id } = useSelector((state: StateType) => state.plans);
  const { api_details } = useSelector(
    (state: StateType) => state.subscriptions
  );

  const stripePromise = api_details && loadStripe(api_details.stripe_key);

  const onSubmitStripe = async (stripeId: string) => {
    stripeId &&
      (await dispatch(CreatePaymentMethod({ payment_method: stripeId })));
    await dispatch(getProfile());
  };

  const onChoosSubscription = async (id?: number) => {
    if ((id && Number.isInteger(id)) || id === 0) {
      await dispatch(CreateSubscription({ plan_id: id }));
    } else if (plan_id) {
      await dispatch(CreateSubscription({ plan_id: plan_id }));
    }
    await dispatch(getProfile());
  };

  useEffect(() => {
    if (profile) {
      dispatch(GetApiDetails());
      profile.plan_id && dispatch(setStatePlanId(profile?.plan_id));
    }
    dispatch(getPlans());
  }, []);

  return (
    <div className={styles.subscriptions}>
      {/* {profile ? ( */}
      <BusinessMembershipPage />
      {/* ) : (
        <Elements stripe={stripePromise}>
          <BusinessMembershipGuestPage />
        </Elements>
      )} */}
    </div>
  );
};

export default BusinessMembership;
