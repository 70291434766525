import { createSlice } from "@reduxjs/toolkit";
import { FavoriteType } from "../../types/favorites";
import {
  getResourceFavorites,
  getTeacherFavorites,
  getStudentsFavorites,
  addFavorite,
  removeFavorite,
} from "../actions/favorites";

const initialState = {
  resourceFavorites: [] as Array<FavoriteType>,
  teacherFavorites: [] as Array<FavoriteType>,
  studentFavorites: [] as Array<FavoriteType>,
  isLoading: true,
  resourceFavoritesLoading: true,
  teacherFavoritesLoading: true,
  studentFavoritesLoading: true,
  errors: null,
};

const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {},
  extraReducers: {
    [getResourceFavorites.pending.toString()]: (state) => {
      state.resourceFavoritesLoading = true;
      state.errors = null;
    },
    [getResourceFavorites.fulfilled.toString()]: (state, { payload }) => {
      state.resourceFavorites = payload;
      state.resourceFavoritesLoading = false;
    },
    [getResourceFavorites.rejected.toString()]: (state, { payload }) => {
      state.resourceFavoritesLoading = false;
      state.errors = payload.errors;
    },
    [getTeacherFavorites.pending.toString()]: (state) => {
      state.teacherFavoritesLoading = true;
      state.errors = null;
    },
    [getTeacherFavorites.fulfilled.toString()]: (state, { payload }) => {
      state.teacherFavorites = payload;
      state.teacherFavoritesLoading = false;
    },
    [getTeacherFavorites.rejected.toString()]: (state, { payload }) => {
      state.teacherFavoritesLoading = false;
      state.errors = payload.errors;
    },
    [getStudentsFavorites.pending.toString()]: (state) => {
      state.studentFavoritesLoading = true;
      state.errors = null;
    },
    [getStudentsFavorites.fulfilled.toString()]: (state, { payload }) => {
      state.studentFavorites = payload;
      state.studentFavoritesLoading = false;
    },
    [getStudentsFavorites.rejected.toString()]: (state, { payload }) => {
      state.studentFavoritesLoading = false;
      state.errors = payload.errors;
    },
    [addFavorite.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [addFavorite.fulfilled.toString()]: (state, { payload }) => {
      switch (payload[0].searchable_type) {
        case "App\\Models\\LearningResource":
          state.resourceFavorites.push(payload[0]);
          break;
        case "App\\Models\\Teacher":
          state.teacherFavorites.push(payload[0]);
          break;
        case "App\\Models\\User":
          state.studentFavorites.push(payload[0]);
          break;
      }
      state.isLoading = false;
    },
    [addFavorite.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [removeFavorite.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [removeFavorite.fulfilled.toString()]: (state, { payload }) => {
      switch (payload[0].searchable_type) {
        case "App\\Models\\LearningResource":
          state.resourceFavorites = state.resourceFavorites.filter(
            (el) => el.id !== payload[0].id
          );
          break;
        case "App\\Models\\Teacher":
          state.teacherFavorites = state.teacherFavorites.filter(
            (el) => el.id !== payload[0].id
          );
          break;
        case "App\\Models\\User":
          state.studentFavorites = state.studentFavorites.filter(
            (el) => el.id !== payload[0].id
          );
          break;
      }
      state.isLoading = false;
    },
    [removeFavorite.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
  },
});

export default favoritesSlice.reducer;
