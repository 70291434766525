import { createSlice } from "@reduxjs/toolkit";
import {
  getTeachers,
  getTeacherById,
  createTeacher,
  updateTeacher,
  deleteTeacher,
} from "../actions/teachers";

import { InitialTeacherType } from "../../types/teachers";

const initialState: InitialTeacherType = {
  teacher: null,
  teachers: null,
  isLoading: false,
  errors: null,
};

const teachersSlice = createSlice({
  name: "teachers",
  initialState,
  reducers: {
    ressetTeachers() {
      return initialState;
    },
  },
  extraReducers: {
    [getTeachers.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getTeachers.fulfilled.toString()]: (state, { payload }) => {
      state.teachers = payload;
      state.isLoading = false;
    },
    [getTeachers.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [getTeacherById.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getTeacherById.fulfilled.toString()]: (state, { payload }) => {
      state.teacher = payload;
      state.isLoading = false;
    },
    [getTeacherById.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [createTeacher.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [createTeacher.fulfilled.toString()]: (state, { payload }) => {
      state.teacher = payload;
      state.isLoading = false;
    },
    [createTeacher.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [updateTeacher.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [updateTeacher.fulfilled.toString()]: (state, { payload }) => {
      state.teacher = payload;
      state.isLoading = false;
    },
    [updateTeacher.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [deleteTeacher.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [deleteTeacher.fulfilled.toString()]: (state, { payload }) => {
      state.teacher = null;
      state.isLoading = false;
    },
    [deleteTeacher.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
  },
});

export const { ressetTeachers } = teachersSlice.actions;

export default teachersSlice.reducer;
