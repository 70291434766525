import React from "react";
import {Helmet} from "react-helmet";

const CarrersPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Careers at Anything Academic</title>
      </Helmet>
      <h1>Carrers</h1>
    </>
  );
};

export default CarrersPage;
