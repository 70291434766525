import { createOptions } from "./functions";
//sidebar icons
import Icon1 from "../assets/images/buttons/Curriculums.png";
import Icon2 from "../assets/images/buttons/Classes.png";
import Icon3 from "../assets/images/buttons/umbrella.png";
import Icon4 from "../assets/images/buttons/LearningResources.png";
import Icon5 from "../assets/images/buttons/AllInOne.png";
import Icon6 from "../assets/images/buttons/online.png";
import Icon7 from "../assets/images/buttons/elearning.png";
import Icon8 from "../assets/images/buttons/LessonPlans.png";
import Icon9 from "../assets/images/buttons/monthly-subscription.png";
import Icon10 from "../assets/images/buttons/FacebookGroups.png";
import Icon11 from "../assets/images/buttons/FieldsTrips.png";
import Icon12 from "../assets/images/buttons/BestOfSites.png";
import Icon13 from "../assets/images/buttons/Facebook.png";
import Icon14 from "../assets/images/buttons/co-ops.png";
import Icon15 from "../assets/images/buttons/stateNationalEducation.png";
import Icon17 from "../assets/images/buttons/Blogs.png";
import Icon18 from "../assets/images/buttons/teaching-tools.png";
import Icon19 from "../assets/images/buttons/educational_news_sites.png";
import Icon20 from "../assets/images/buttons/umbrella.png";
import Icon21 from "../assets/images/buttons/checklist.svg";
import Icon22 from "../assets/images/buttons/TestIcon_g.png";
import Icon23 from "../assets/images/buttons/youtube_icon.png";
import Icon32 from "../assets/images/buttons/online_classes_72.png";

//premiumIcons
import PremiumIcon1 from "../assets/images/buttons/premiumWhiteButtons/CurriculumsPremium.png";
import PremiumIcon2 from "../assets/images/buttons/premiumWhiteButtons/ClassesPremium.png";
import PremiumIcon3 from "../assets/images/buttons/premiumWhiteButtons/UmbrellaSchoolPremium.png";
import PremiumIcon4 from "../assets/images/buttons/premiumWhiteButtons/LearningResourcesPremium.png";
import PremiumIcon5 from "../assets/images/buttons/premiumWhiteButtons/AllInOnePremium.png";
import PremiumIcon6 from "../assets/images/buttons/premiumWhiteButtons/OnlineSchoolPremium.png";
import PremiumIcon7 from "../assets/images/buttons/premiumWhiteButtons/ELearning.png";
import PremiumIcon8 from "../assets/images/buttons/premiumWhiteButtons/LessonsPlanPremium.png";
import PremiumIcon9 from "../assets/images/buttons/premiumWhiteButtons/monthly-subscriptionPremium.png";
import PremiumIcon10 from "../assets/images/buttons/premiumWhiteButtons/EducationAssociationPremium.png";
import PremiumIcon11 from "../assets/images/buttons/premiumWhiteButtons/FieldsTripsPremium.png";
import PremiumIcon12 from "../assets/images/buttons/premiumWhiteButtons/BestOfPremium.png";
import PremiumIcon13 from "../assets/images/buttons/premiumWhiteButtons/FacebookGroupsPremium.png";
import PremiumIcon14 from "../assets/images/buttons/premiumWhiteButtons/co-opsPremium.png";
import PremiumIcon15 from "../assets/images/buttons/premiumWhiteButtons/stateNationalEducation.png";
import PremiumIcon16 from "../assets/images/buttons/premiumWhiteButtons/EducationNewsSitePremium.png";
import PremiumIcon17 from "../assets/images/buttons/premiumWhiteButtons/BlogsPremium.png";
import PremiumIcon18 from "../assets/images/buttons/premiumWhiteButtons/teaching-toolsPremium.png";
import PremiumIcon19 from "../assets/images/buttons/premiumWhiteButtons/educational_news_sites.png";
import PremiumIcon20 from "../assets/images/buttons/premiumWhiteButtons/umbrellaPremium.png";
import PremiumIcon21 from "../assets/images/buttons/checklist.svg";
import PremiumIcon22 from "../assets/images/buttons/TestIcon.png";
import PremiumIcon23 from "../assets/images/buttons/premiumWhiteButtons/youtube_icon.png";
import PremiumIcon26 from "../assets/images/buttons/premiumWhiteButtons/camps.png";
import PremiumIcon32 from "../assets/images/buttons/premiumWhiteButtons/online_classes.png";

//blueIcons
import BlueIcon1 from "../assets/images/buttons/blueButtons/CurriculumsPremium.png";
import BlueIcon2 from "../assets/images/buttons/blueButtons/ClassesPremium.png";
import BlueIcon3 from "../assets/images/buttons/blueButtons/sports.png";
import BlueIcon4 from "../assets/images/buttons/blueButtons/LearningResourcesPremium.png";
import BlueIcon5 from "../assets/images/buttons/blueButtons/AllInOnePremium.png";
import BlueIcon6 from "../assets/images/buttons/blueButtons/OnlineSchoolPremium.png";
import BlueIcon7 from "../assets/images/buttons/blueButtons/ELearning.png";
import BlueIcon8 from "../assets/images/buttons/blueButtons/LessonsPlanPremium.png";
import BlueIcon9 from "../assets/images/buttons/blueButtons/monthly-subscriptionPremium.png";
import BlueIcon10 from "../assets/images/buttons/blueButtons/EducationAssociationPremium.png";
import BlueIcon11 from "../assets/images/buttons/blueButtons/FieldsTripsPremium.png";
import BlueIcon12 from "../assets/images/buttons/blueButtons/BestOfPremium.png";
import BlueIcon13 from "../assets/images/buttons/blueButtons/FacebookGroupsPremium.png";
import BlueIcon14 from "../assets/images/buttons/blueButtons/co-opsPremium.png";
import BlueIcon15 from "../assets/images/buttons/blueButtons/stateNationalEducation.png";
import BlueIcon17 from "../assets/images/buttons/blueButtons/BlogsPremium.png";
import BlueIcon18 from "../assets/images/buttons/blueButtons/teaching-toolsPremium.png";
import BlueIcon19 from "../assets/images/buttons/blueButtons/educational_news_sites.png";
import BlueIcon20 from "../assets/images/buttons/blueButtons/umbrellaPremium.png";
import BlueIcon21 from "../assets/images/buttons/blueButtons/checklist.png";
import BlueIcon22 from "../assets/images/buttons/blueButtons/TestIcon.png";
import BlueIcon23 from "../assets/images/buttons/blueButtons/youtube_icon.png";
import BlueIcon25 from "../assets/images/buttons/blueButtons/gameschooling.png";
import BlueIcon32 from "../assets/images/buttons/blueButtons/online_classes.png";
import BlueIcon49 from "../assets/images/buttons/blueButtons/travel.png";
import BlueIcon51 from "../assets/images/buttons/blueButtons/dual_enrollment.png";
import BlueIcon52 from "../assets/images/buttons/blueButtons/research.png";
import BlueIcon53 from "../assets/images/buttons/blueButtons/clubs.png";
import TeachersBlue from "../assets/images/buttons/blueButtons/TeacherPremium.png";

//sidebar icons
import Online from "../assets/images/buttons/online.png";
import Pods from "../assets/images/buttons/PODs.png";
import Teachers from "../assets/images/buttons/Teachers.png";
import AllInOne from "../assets/images/buttons/AllInOne.png";
import BestOfSites from "../assets/images/buttons/BestOfSites.png";
import Blogs from "../assets/images/buttons/Blogs.png";
import Classes from "../assets/images/buttons/Classes.png";
import Facebook from "../assets/images/buttons/Facebook.png";
import Curriculums from "../assets/images/buttons/Curriculums.png";
import EducationAssociation from "../assets/images/buttons/FacebookGroups.png";
import FieldsTrips from "../assets/images/buttons/FieldsTrips.png";
import LearningResources from "../assets/images/buttons/LearningResources.png";
import LessonPlans from "../assets/images/buttons/LessonPlans.png";
import TeachingTools from "../assets/images/buttons/teaching-tools.png";
import Elearning from "../assets/images/buttons/elearning.png";
import MonthlySubscription from "../assets/images/buttons/monthly-subscription.png";
import News from "../assets/images/buttons/news.png";
import CoOps from "../assets/images/buttons/co-ops.png";
import Umbrella from "../assets/images/buttons/umbrella.png";
import messageCenterNotActive from "../assets/images/subheader/messagesNotActive.png";
import FavoritesNotActive from "../assets/images/subheader/FavoritesNotActive.png";
import Favorites from "../assets/images/buttons/MyFavorites.png";
import MessageCenter from "../assets/images/buttons/MessageCenter.png";
import StarNotActive from "../assets/images/subheader/StarNotActive.png";
import ProfileNotActive from "../assets/images/subheader/ProfileNotActive.png";
import profileActive from "../assets/images/subheader/profileActive.png";
import profiles from "../assets/images/subheader/profiles.png";
import account_white from "../assets/images/subheader/account_white.png";
import reviews_white from "../assets/images/subheader/reviews_white.png";
import CheckHelp from "../assets/images/buttons/CheckHelp.png";
import StateVerifications from "../assets/images/buttons/StateVerification.png";
import teachingBusinessTools from "../assets/images/buttons/blueButtons/briefcase_icon.png";
import KnowLaws from "../assets/images/buttons/KnowLaws.png";
import Evaluations from "../assets/images/buttons/Evaluations.png";

//premiumIcons
import OnlineSchoolPremium from "../assets/images/buttons/premiumWhiteButtons/OnlineSchoolPremium.png";
import LearningResourcesPremium from "../assets/images/buttons/premiumWhiteButtons/LearningResourcesPremium.png";
import AllInOnePremium from "../assets/images/buttons/premiumWhiteButtons/AllInOnePremium.png";
import VerificationsPremium from "../assets/images/buttons/premiumWhiteButtons/VerificationsPremium.png";
import EducationAssociationPremium from "../assets/images/buttons/premiumWhiteButtons/EducationAssociationPremium.png";
import FieldsTripsPremium from "../assets/images/buttons/premiumWhiteButtons/FieldsTripsPremium.png";
import BestOfPremium from "../assets/images/buttons/premiumWhiteButtons/BestOfPremium.png";
import FacebookGroupsPremium from "../assets/images/buttons/premiumWhiteButtons/FacebookGroupsPremium.png";
import TeacherPremium from "../assets/images/buttons/premiumWhiteButtons/TeacherPremium.png";
import TeacherPremiumBlue from "../assets/images/buttons/blueButtons/TeacherPremium.png";
import ClassesPremium from "../assets/images/buttons/premiumWhiteButtons/ClassesPremium.png";
import CurriculumsPremium from "../assets/images/buttons/premiumWhiteButtons/CurriculumsPremium.png";
import LessonsPlanPremium from "../assets/images/buttons/premiumWhiteButtons/LessonsPlanPremium.png";
import TeacherBusinessToolPremium from "../assets/images/buttons/blueButtons/briefcase_icon.png";
import UmbrellaSchoolPremium from "../assets/images/buttons/premiumWhiteButtons/UmbrellaSchoolPremium.png";
import EducationNewsSitePremium from "../assets/images/buttons/premiumWhiteButtons/EducationNewsSitePremium.png";
import coopsPremium from "../assets/images/buttons/premiumWhiteButtons/co-opsPremium.png";
import PODsPremium from "../assets/images/buttons/premiumWhiteButtons/PODsPremium.png";
import monthlySubscriptionPremium from "../assets/images/buttons/premiumWhiteButtons/monthly-subscriptionPremium.png";
import teachingToolsPremium from "../assets/images/buttons/premiumWhiteButtons/teaching-toolsPremium.png";
import CheckHelpPremiumIcon from "../assets/images/buttons/blueButtons/CheckHelpPremium.png";
import ELearningPremium from "../assets/images/buttons/premiumWhiteButtons/ELearning.png";
import BlogsPremium from "../assets/images/buttons/premiumWhiteButtons/BlogsPremium.png";
import KnowLawsActive from "../assets/images/buttons/blueButtons/laws_icon.png";
import EvaluationsPremium from "../assets/images/buttons/Evaluations.png";

// dashboard right column
import Diamond from "../assets/images/dashboard/diamond.jpeg";
import Know from "../assets/images/dashboard/know.jpeg";
import Library from "../assets/images/dashboard/library.jpeg";

import {
  TEACHER_GENDER,
  TEACHER_DISTANCE,
  QUANTITY_KIDS,
  RESOURCE_TYPE,
  TEACHING_TYPES,
  TEACHER_AGES,
  TEACHER_EXPERIENCE,
  TEACHER_EDUCATION,
  CLASS_OPTIONS,
  PROGRAM_DURATION,
  COSTPERSESSIONPANGE,
  CAMPER_GENDER,
} from "./constants";

// cards icons
import visa from "../assets/images/cards/visa.png";
import mastercard from "../assets/images/cards/mastercard.png";
import americanexpress from "../assets/images/cards/americanexpress.png";
import discover from "../assets/images/cards/discover.png";
import dinersclub from "../assets/images/cards/dinersclub.png";
import jcb from "../assets/images/cards/jcb.png";

export const optionsGender = createOptions(TEACHER_GENDER);
export const optionsDistance = createOptions(TEACHER_DISTANCE);
export const costPerSessionRange = createOptions(COSTPERSESSIONPANGE);
export const optionsQuantityKids = createOptions(QUANTITY_KIDS);
export const optionsResourceType = createOptions(RESOURCE_TYPE);
export const optionsTeacherType = createOptions(TEACHING_TYPES);
export const optionsTeacherAges = createOptions(TEACHER_AGES);
export const optionsTeacherExperience = TEACHER_EXPERIENCE;
export const optionsTeacherEducationLevel = createOptions(TEACHER_EDUCATION);
export const optionsClassOptions = createOptions(CLASS_OPTIONS);
export const optionsProgramDuration = createOptions(PROGRAM_DURATION);
export const optionsCamperGender = createOptions(CAMPER_GENDER);

export const subjectList = [
  "Math",
  "English",
  "Science",
  "Foreign language",
  "Computers",
  "Art",
  "Sport & fitness",
  "Business",
  "Musical & drama",
  "Spaecial education",
];

export const navMenu = [
  { name: "Home", link: "/user/my-search-profiles" },
  { name: "Messages", link: "/user/messages" },
  { name: "Our Library", link: `${process.env.REACT_APP_WP_HOST}` },
  {
    name: "Add A Resource",
    link: `${process.env.REACT_APP_WP_HOST}/submit-a-resource/`,
  },
];

export const navMenuMember = [
  { name: "Home", link: "/user/my-search-profiles" },
  { name: "Messages", link: "/user/messages" },
  {
    name: "Our Library",
    link: `${process.env.REACT_APP_WP_HOST}`,
  },
  {
    name: "Add A Resource",
    link: `${process.env.REACT_APP_WP_HOST}/submit-a-resource/`,
  },
];

export const navMenuNotLogged = [
  { name: "Get Started", link: "/search-profile-form" },
  { name: "Our Library", link: `${process.env.REACT_APP_WP_HOST}` },
  {
    name: "Become A Partner",
    link: `${process.env.REACT_APP_WP_HOST}/become-a-partner/`,
  },
  {
    name: "Add A Resource",
    link: `${process.env.REACT_APP_WP_HOST}/submit-a-resource/`,
  },
];

export const latestArticles = [
  {
    title: `Have questions? 
    We have answers.`,
    text:
      "Check out our library to find articles on the best apps, the latest teaching styles and more!",
    button: "Explore Library",
    url: "https://library.anythingacademic.com",
    image: Library,
  },
  {
    title: "Know something we don't?",
    text:
      "If you know about a great academic resource we don't have, please share!",
    button: "Tell us about it",
    url: "https://library.anythingacademic.com/submit-a-resource/",
    image: Know,
  },
  {
    title: "There's more",
    text:
      "Need professional features or more student profiles? Upgrade your plan now!",
    button: "Learn more",
    url: "https://www.anythingacademic.com/business-memberships",
    image: Diamond,
  },
];

export const LookMatchesDistance = [{ name: "10 Miles" }];

export const teacherMenu = [
  { name: "Home", link: "home" },
  { name: "My profile", link: "profile-step3" },
  { name: "Messages", link: "messages" },
  { name: "Favorites", link: "favorites" },
  { name: "Resources", link: "resources" },
];

export const navMenuSubHeader = [
  {
    name: "My Profiles",
    url: "/user/my-search-profiles",
    activeIcon: profileActive,
    icon: profiles,
    subUrl: "/user/teacher-profile", // need for set active tab
  },
  {
    name: "Messages",
    url: "/user/messages",
    icon: messageCenterNotActive,
    activeIcon: MessageCenter,
  },
  // {
  //   name: "Teacher Profile",
  //   url: "/user/teacher-profile",
  //   icon: TeacherNotActive,
  //   activeIcon: Teachers,
  // },

  {
    name: "Favorites",
    url: "/user/favorites",
    icon: FavoritesNotActive,
    activeIcon: Favorites,
  },
  {
    name: "Reviews",
    url: "/user/my-reviews",
    icon: StarNotActive,
    activeIcon: reviews_white,
  },
  {
    name: "Account",
    url: "/user/profile-step3",
    icon: ProfileNotActive,
    activeIcon: account_white,
  },
];

export const navMenuSubHeaderTeacher = [
  {
    name: "Teacher jobs",
    url: "teachers-jobs",
  },
  {
    name: "Curriculums",
    url: "curriculums",
  },
];

export const NavMenuList = [
  { name: "My Profiles", url: "/user/my-search-profiles" },
  { name: "Messages", url: "/user/messages" },
  { name: "Favorites", url: "/user/favorites" },
  { name: "Reviews", url: "/user/my-reviews" },
];

export const subscriptionsList = [
  {
    name: "12 month",
    description: "(annual)",
    cost: "$12/month",
    value: 12,
  },
  {
    name: "3 month",
    description: "(quarterly)",
    cost: "$25/month",
    value: 25,
  },
  {
    name: "2 month",
    description: "(monthly)",
    cost: "$38/month",
    value: 38,
  },
];

export const footerMenuList = [
  {
    title: "Learn More",
    menu: [
      {
        name: "Teacher Business Resources",
        url: `${process.env.REACT_APP_WP_HOST_MEMBER}/teacher-business-resources/`,
        urlNotPaid: "/user/subscription",
      },
      {
        name: "Homeschooling 101",
        url: `${process.env.REACT_APP_WP_HOST}/resources/homeschooling-101/`,
      },
      {
        name: "Explore Our Library",
        url: `${process.env.REACT_APP_WP_HOST}`,
      },
      // {
      //   name: "State Laws",
      //   url: `${process.env.REACT_APP_WP_HOST}/state-laws-category/`,
      // },
    ],
  },

  {
    title: "Get Help ",
    menu: [
      {
        name: "Background Check Safety",
        url: `${process.env.REACT_APP_WP_HOST}/resources/background-check-safety/`,
      },
      { name: "Submit a Ticket", url: "#" },
      {
        name: "Contact Us",
        url: "https://support.anythingacademic.com/hc/en-us/requests/new",
      },
    ],
  },

  {
    title: "Join In",
    menu: [
      { name: "For Businesses", url: "/business-memberships" },
      {
        name: "Submit a Resource",
        url: `${process.env.REACT_APP_WP_HOST}/submit-a-resource/`,
      },
      {
        name: "Become a Partner",
        url: `${process.env.REACT_APP_WP_HOST}/become-a-partner/`,
      },
    ],
  },
  {
    title: "AnythingAcademic.com",
    menu: [
      // { name: "Account Control Panel", url: "/user/my-search-profiles" },
      { name: "Terms of Use", url: "/terms-of-use" },
      { name: "Privacy Policy", url: "/privacy-policy" },
    ],
    menuNotLogged: [
      { name: "Become a Member", url: "/auth/sign-up" },
      { name: "Terms of Use", url: "/terms-of-use" },
      { name: "Privacy Policy", url: "/privacy-policy" },
    ],
  },
];

export const footerMenuListMember = [
  {
    title: "Learn More",
    menu: [
      {
        name: "Teacher Business Resources",
        url: `${process.env.REACT_APP_WP_HOST_MEMBER}/teacher-business-resources/`,
        urlNotPaid: "/user/subscription",
      },
      {
        name: "Homeschooling 101",
        url: `${process.env.REACT_APP_WP_HOST}/resources/homeschooling-101/`,
      },
      {
        name: "Explore Our Library",
        url: `${process.env.REACT_APP_WP_HOST}`,
      },
      // {
      //   name: "State Laws",
      //   url: `${process.env.REACT_APP_WP_HOST}/state-laws-category/`,
      // },
    ],
  },
  {
    title: "Get Help",
    menu: [
      {
        name: "Background Check Safety",
        url: `${process.env.REACT_APP_WP_HOST}/resources/background-check-safety/`,
      },
      { name: "Submit a Ticket", url: "#" },
      {
        name: "Contact Us",
        url: "https://support.anythingacademic.com/hc/en-us/requests/new",
      },
    ],
  },
  {
    title: "Join In",
    menu: [
      { name: "For Businesses", url: "/business-memberships" },
      {
        name: "Submit a Resource",
        url: `${process.env.REACT_APP_WP_HOST}/submit-a-resource/`,
      },
      {
        name: "Become a Partner",
        url: `${process.env.REACT_APP_WP_HOST}/become-a-partner/`,
      },
    ],
  },
  {
    title: "AnythingAcademic.com",
    menu: [
      // { name: "Account Control Panel", url: "/user/my-search-profiles" },
      { name: "Terms of Use", url: "/terms-of-use" },
      { name: "Privacy Policy", url: "/privacy-policy" },
    ],
    menuNotLogged: [
      { name: "Become a Member", url: "/auth/sign-up" },
      { name: "Terms of Use", url: "/terms-of-use" },
      { name: "Privacy Policy", url: "/privacy-policy" },
    ],
  },
];

export const multiselectTypesList = [
  "languages",
  "independence_levels",
  "curriculum_models",
  "curriculum_types",
  "educational_levels",
  "learning_services",
  "other_services",
  "learning_styles",
  "additional_requirements",
  "credential_states",
  "religions",
  "class_times",
  "week_days",
  "employment_types",
  "subjects",
  "teaching_types",
  "schooling_types",
  "school_forms",
  "camp_categories",
  "camp_types",
  "gender_restrictions",
];

export const fullWidthStudentProfileSteps = [0, 1, 4];

export const cardsList = [
  { id: "0", name: "visa", icon: visa },
  { id: "1", name: "mastercard", icon: mastercard },
  { id: "2", name: "americanexpress", icon: americanexpress },
  { id: "3", name: "discover", icon: discover },
  { id: "4", name: "dinersclub", icon: dinersclub },
  { id: "5", name: "jcb", icon: jcb },
];

export const navMenuInSidebarList = [
  {
    name: "Find a Job",
    url: "/user/teacher-requests",
    img: TeachersBlue,
    premiumIcon: TeacherPremiumBlue,
  },
  {
    name: "Find a Professional",
    url: "/user/teachers",
    img: TeachersBlue,
    premiumIcon: TeacherPremiumBlue,
  },
  // { name: "PODs", url: "/soon/pods", img: Pods, premium: PODsPremium },
];

export const unlockListInSidebarList = [
  {
    name: "Curriculums",
    url: "/user/curriculum",
    img: Curriculums,
    premiumIcon: CurriculumsPremium,
  },
  {
    name: "Classes",
    url: "/soon/classes",
    img: Classes,
    premiumIcon: ClassesPremium,
  },
  {
    name: "Learning Resources",
    url: "/user/resource",
    img: LearningResources,
    premiumIcon: LearningResourcesPremium,
  },
  {
    name: "All In One Curriculums",
    url: "/user/all-curriculums",
    img: AllInOne,
    premiumIcon: AllInOnePremium,
  },
  {
    name: "Online Schools",
    url: "/user/online",
    img: Online,
    premiumIcon: OnlineSchoolPremium,
  },
  {
    name: "E-Learning",
    url: "/user/e-learning",
    img: Elearning,
    premiumIcon: ELearningPremium,
  },
  {
    name: "Lessons Plans",
    url: "/user/lessons-plans",
    img: LessonPlans,
    premiumIcon: LessonsPlanPremium,
  },
  {
    name: "Monthly Enrichment Boxes",
    url: "/user/monthly-enrichment-boxes",
    img: MonthlySubscription,
    premiumIcon: monthlySubscriptionPremium,
  },
  {
    name: "Educations News Sites",
    url: "/user/educations-news-sites",
    img: News,
    premiumIcon: EducationNewsSitePremium,
  },
  {
    name: "Fields Trips",
    url: "/user/fields-trips",
    img: FieldsTrips,
    premiumIcon: FieldsTripsPremium,
  },
  {
    name: '"Best Of" Sites',
    url: "/soon/user/my-reviews",
    img: BestOfSites,
    premiumIcon: BestOfPremium,
  },
  {
    name: "Facebook Groups",
    url: "/user/facebook",
    img: Facebook,
    premiumIcon: FacebookGroupsPremium,
  },
  {
    name: "Co-ops",
    url: "/user/Coops",
    img: CoOps,
    premiumIcon: coopsPremium,
  },
  {
    name: "State Educational Association",
    url: "/user/education-association",
    img: EducationAssociation,
    premiumIcon: EducationAssociationPremium,
  },
  {
    name: "Umbrella School",
    url: "/user/umbrella",
    img: Umbrella,
    premiumIcon: UmbrellaSchoolPremium,
  },
  {
    name: "Blogs",
    url: "/user/blogs",
    img: Blogs,
    premiumIcon: BlogsPremium,
  },
  {
    name: "Teaching Tools",
    url: "/soon/teaching-tools",
    img: TeachingTools,
    premiumIcon: teachingToolsPremium,
  },
  {
    name: "Tests/Evaluations",
    url: "/user/tests-evaluations",
    img: Evaluations,
    premiumIcon: EvaluationsPremium,
  },
];

export const librariesSidebarList = [
  // {
  //   name: "Your State Laws",
  //   url: "/state-laws/{state}-state-education-laws",
  //   premiumIcon: KnowLawsActive,
  //   img: KnowLawsActive,
  // },
  {
    name: "Background Check Help",
    url: "/resources/background-check-safety",
    img: CheckHelpPremiumIcon,
    premiumIcon: CheckHelpPremiumIcon,
  },
  {
    name: "Your State Laws and Teacher Credential Verifications",
    url: "/user/resource/19/Credential Verifications",
    premiumIcon: KnowLawsActive,
    img: KnowLawsActive,
  },
  {
    name: "Teacher Business Tools",
    url: "/category/teacher-business-resources/",
    img: teachingBusinessTools,
    premiumIcon: TeacherBusinessToolPremium,
  },
];

export const freePlan = {
  id: 0,
  plan_name: "free",
  subscription_name: "free",
  plan_id_test: "",
  plan_id_live: "",
  price: "0",
  created_at: null,
  updated_at: null,
  deleted_at: null,
};

export const bulletsListHelper: any = {
  premium: [
    {
      title: "Best for small families, teachers or tutors",
      subtitle: "",
    },
    {
      title: "5 Student Profiles",
      subtitle:
        "Create and save up to 5 search profiles to ensure you find exactly what you are looking for.",
    },
    {
      title: "5 Pro Job Listings",
      subtitle:
        "Create multiple job listings so you can find the match tutor for your son and the piano teacher for your daughter.",
    },
    {
      title: "1 Professional Profile",
      subtitle:
        "Go Premium to be able to message with clients matched to your profile.",
    },
    {
      title: "16+ Premium Categories",
      subtitle:
        "Personalized search of thousands of learning resources with reviews.",
    },
    {
      title: "Messaging",
      subtitle:
        "Message professional teachers, tutors and more to connect and find the help you need.",
    },
    {
      title: "Reviews",
      subtitle:
        "Leave reviews and read them to find out more about curriculums and thousands of other resources.",
    },
    {
      title: "Favorites",
      subtitle:
        "Save your favorites to keep track of the best of what you find.",
    },
  ],
  family: [
    {
      title:
        "Best for large families. Get all the features of Premium super sized",
      subtitle: "",
    },
    {
      title: "10 Student Profiles",
      subtitle: "Enough saved search profiles for the largest of households!",
    },
    {
      title: "10 Pro Job Listings",
      subtitle:
        "When you need different teachers/tutors for each student and their individual needs.",
    },
    {
      title: "1 Professional Profile",
      subtitle:
        "Go Premium to be able to message with clients matched to your profile.",
    },
    {
      title: "16+ Premium Categories",
      subtitle:
        "Personalized search of thousands of learning resources with reviews.",
    },
    {
      title: "Messaging",
      subtitle:
        "Message professional teachers, tutors and more to connect and find the help you need.",
    },
    {
      title: "Reviews",
      subtitle:
        "Leave reviews and read them to find out more about curriculums and thousands of other resources.",
    },
    {
      title: "Favorites",
      subtitle:
        "Save your favorites to keep track of the best of what you find.",
    },
  ],
  diamond: [
    {
      title:
        "Best if you are running a small business, tutor company or students who want to make their listings stand out from the crowd",
      subtitle: "",
    },
    {
      title: "Everything Included in Premium Family Plus",
      subtitle:
        "All the benefits of premium family plus even more amazing features.",
    },
    {
      title: "Listing Banner Image",
      subtitle:
        "Custom image banner for your listing that doubles the size in search results and makes you sent out.",
    },
    {
      title: "Direct Web Link",
      subtitle:
        "Clickable link directly from your listing to your own business website, facebook page, instagram, your choice.",
    },
    {
      title: "Branded Listing Name",
      subtitle:
        "Your brand/company name shown on your listing.  Non-business accounts only show first name & last initial.",
    },
  ],
  basic: [
    {
      title: "",
      subtitle: "1 Student Profile",
    },
    {
      title: "",
      subtitle: "1 Job Request",
    },
    {
      title: "",
      subtitle: "1 Professional Profile",
    },
    {
      title: "16+ Premium Categories",
      subtitle:
        "Personalized search of thousands of learning resources with reviews.",
    },
    {
      title: "Reviews",
      subtitle:
        "Leave reviews and read them to find out more about curriculums and thousands of other resources.",
    },
  ],
};

export const searchProfileSignUpList = [
  {
    name: "Teachers",
    img: TeacherPremium,
  },
  {
    name: "PODd (Coming soon)",
    img: PODsPremium,
  },
  {
    name: "Curriculums",
    img: CurriculumsPremium,
  },
  {
    name: "Curriculums All In One",
    img: AllInOnePremium,
  },
  {
    name: "Online Schools",
    img: OnlineSchoolPremium,
  },
  {
    name: "E-Learning",
    img: ELearningPremium,
  },
  {
    name: "Learning Resources",
    img: LearningResourcesPremium,
  },
  {
    name: "Umbrella School",
    img: UmbrellaSchoolPremium,
  },
  {
    name: "Co-ops",
    img: coopsPremium,
  },
  {
    name: "Lessons Plans",
    img: LessonsPlanPremium,
  },
  {
    name: "Monthly Enrichment Boxes",
    img: monthlySubscriptionPremium,
  },
  {
    name: "Educations News Sites",
    img: EducationNewsSitePremium,
  },
  {
    name: "Fields Trips",
    img: FieldsTripsPremium,
  },
  {
    name: '"Best Of" Sites',
    img: BestOfPremium,
  },
  {
    name: "Facebook Groups",
    img: FacebookGroupsPremium,
  },
  {
    name: "Teaching Tools",
    img: teachingToolsPremium,
  },
  {
    name: "Blogs",
    img: BlogsPremium,
  },
  {
    name: "State Educational Association",
    img: EducationAssociationPremium,
  },
  {
    name: "Tests/Evaluations",
    img: EvaluationsPremium,
  },
];

export const iconsPremiumMatches = [
  {},
  { img: Icon1, premiumIcon: PremiumIcon1, blueIcon: BlueIcon1 },
  { img: Icon2, premiumIcon: PremiumIcon2, blueIcon: BlueIcon2 },
  { img: Icon3, premiumIcon: PremiumIcon3, blueIcon: BlueIcon3 },
  { img: Icon4, premiumIcon: PremiumIcon4, blueIcon: BlueIcon4 },
  { img: Icon5, premiumIcon: PremiumIcon5, blueIcon: BlueIcon5 },
  { img: Icon6, premiumIcon: PremiumIcon6, blueIcon: BlueIcon6 },
  { img: Icon7, premiumIcon: PremiumIcon7, blueIcon: BlueIcon7 },
  { img: Icon8, premiumIcon: PremiumIcon8, blueIcon: BlueIcon8 },
  { img: Icon9, premiumIcon: PremiumIcon9, blueIcon: BlueIcon9 },
  { img: Icon10, premiumIcon: PremiumIcon10, blueIcon: BlueIcon10 },
  { img: Icon11, premiumIcon: PremiumIcon11, blueIcon: BlueIcon11 },
  { img: Icon12, premiumIcon: PremiumIcon12, blueIcon: BlueIcon12 },
  { img: Icon13, premiumIcon: PremiumIcon13, blueIcon: BlueIcon13 },
  { img: Icon14, premiumIcon: PremiumIcon14, blueIcon: BlueIcon14 },
  { img: Icon15, premiumIcon: PremiumIcon15, blueIcon: BlueIcon15 },
  { img: Icon20, premiumIcon: PremiumIcon20, blueIcon: BlueIcon20 },
  { img: Icon17, premiumIcon: PremiumIcon17, blueIcon: BlueIcon17 },
  { img: Icon18, premiumIcon: PremiumIcon18, blueIcon: BlueIcon18 },
  { img: Icon19, premiumIcon: PremiumIcon19, blueIcon: BlueIcon19 },
  { img: Icon21, premiumIcon: PremiumIcon21, blueIcon: BlueIcon21 },
  { img: Icon17, premiumIcon: PremiumIcon17, blueIcon: BlueIcon17 },
  { img: Icon19, premiumIcon: PremiumIcon19, blueIcon: BlueIcon19 },
  { img: Icon1, premiumIcon: PremiumIcon1, blueIcon: BlueIcon1 },
  { img: Icon2, premiumIcon: PremiumIcon2, blueIcon: BlueIcon2 },
  { img: Icon3, premiumIcon: PremiumIcon3, blueIcon: BlueIcon25 },
  { img: PremiumIcon26, premiumIcon: PremiumIcon26, blueIcon: BlueIcon4 },
  { img: Icon22, premiumIcon: PremiumIcon22, blueIcon: BlueIcon22 },
  { img: Icon21, premiumIcon: PremiumIcon21, blueIcon: BlueIcon21 },
  { img: Icon23, premiumIcon: PremiumIcon23, blueIcon: BlueIcon23 },
  { img: Icon18, premiumIcon: PremiumIcon18, blueIcon: BlueIcon18 },
  { img: Icon5, premiumIcon: PremiumIcon5, blueIcon: BlueIcon5 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon32 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon32 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon32 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon32 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon32 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon32 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon32 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon32 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon32 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon32 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon32 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon32 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon32 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon32 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon32 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon32 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon32 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon49 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon32 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon51 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon52 },
  { img: Icon32, premiumIcon: PremiumIcon32, blueIcon: BlueIcon53 },
];

export const hiddenResourceTypes = [
  "Events",
  "Classes",
  "Afterschool Activities",
  "Credential Verifications",
  "Blogs",
  "Lesson Plans",
  "Sales / Exchange",
  "Clubs and Organizations",
  "HIDDEN",
  "HIDE ME - Teaching Tools",
  "HIDE ME - Planning",
];

export const resourcesToShowInHome = [1, 6, 7, 4, 5];

export const iconsResourcesMatches = [
  { title: "Curriculums", premiumIcon: PremiumIcon1 },
  { title: "Teaching Tools", premiumIcon: PremiumIcon2 },
  { title: "Learning Resources", premiumIcon: PremiumIcon3 },
  { title: "Curriculum All in One", premiumIcon: PremiumIcon4 },
  { title: "'Best Of' Sites", premiumIcon: PremiumIcon5 },
  { title: "Facefook Groups", premiumIcon: PremiumIcon6 },
  { title: "Online Schools", premiumIcon: PremiumIcon7 },
  { title: "E-Learning", premiumIcon: PremiumIcon8 },
  { title: "Lesson Plans", premiumIcon: PremiumIcon9 },
  { title: "Monthly Enrichment Boxes", premiumIcon: PremiumIcon10 },
  { title: "Educational News Site", premiumIcon: PremiumIcon11 },
  { title: "Fields Trips", premiumIcon: PremiumIcon12 },
  { title: "Co-ops", premiumIcon: PremiumIcon15 },
  { title: "State Eductions Associations", premiumIcon: PremiumIcon14 },
  { title: "Umbrella Schools", premiumIcon: PremiumIcon15 },
  { title: "Blogs", premiumIcon: PremiumIcon16 },
];

export let isMobileWidth = document.body.clientWidth <= 580;
