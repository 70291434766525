import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { Badge, Avatar } from "@material-ui/core";
import clsx from "clsx";

import NavBar from "../components/NavBar/NavBar";
import LogoGoldImage from "../../assets/images/goldLogo.png";
import avatarPlaceholder from "../../assets/images/avatarPlaceholder.png";
import { countUnreadMessages } from "../../helpers/functions";
import { navMenu, navMenuMember, navMenuNotLogged } from "../../helpers/values";
import { setStateValue } from "../../redux/reducers/messages";
import { PLAN_SUBSCRIPTION } from "../../helpers/constants";
import { StateType } from "../../redux/store";

import styles from "./styles.module.scss";

const Header: React.FC = () => {
  const location = useLocation<string>();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const { profile } = useSelector((state: StateType) => state.user);

  const { 
    unreadMessagesCount,  
    chatsRequest,
    chatsTeacher 
  } = useSelector(
    (state: StateType) => state.messages
  );

  const navContent = profile
    ? profile.plan === PLAN_SUBSCRIPTION.FREE
      ? navMenu
      : navMenuMember
    : navMenuNotLogged;

  useEffect(() => {
    if (profile && token) {
      dispatch(
        setStateValue({
          type: "unreadMessagesCount",
          data: profile?.unread_messages_count,
        })
      );
    }
  }, [profile]);

  const isHomepage = location.pathname === "/";

  const unreadMessagesBadge = countUnreadMessages(chatsRequest) + countUnreadMessages(chatsTeacher)
  return (
    <header className={styles.header}>
      <div className={styles.homepageContent}>
      <div className={styles.headerInner}>
          <Link
            to={profile?.default_search_id ? "/global-search" : "/home" }
            className={styles.logoLink}
          >
            <img src={LogoGoldImage} className={styles.logo} alt="" />
          </Link>

          <div className={styles.navMenu}>

            {profile?.default_search_id ? (
            <Link to={`/global-search`}
                  className={styles.navLink} >
              <span>Search</span>
            </Link>
            ) : null}

            {navContent?.map((item, index) => (
              <Link
                to={{ pathname: item.link }}
                target={item.link.includes("https://") ? "_blank" : "_self"}
                key={index}
                className={
                  location.pathname.includes(item.link)
                    ? styles.activeLink
                    : styles.navLink
                }
              >
                {item.name === "Messages" ? (
                  <span className={styles.messageCenterTab}>
                    <Badge badgeContent={unreadMessagesBadge || unreadMessagesCount} color="primary">
                      {item.name}
                    </Badge>
                  </span>
                ) : (
                  <span>{item.name}</span>
                )}
              </Link>
            ))}
          </div>

          {profile ? (
            <div className="row ai-center">
              <Link to="/user/profile-step3" className={styles.avatarLink}>
                <Avatar src={profile.full_image || avatarPlaceholder}>
                  {`${profile.first_name[0]}${profile.last_name[0]}`.toUpperCase()}
                </Avatar>
                <span>{profile.first_name}</span>
              </Link>

              <NavBar navContent={navContent} />
            </div>
          ) : (
            <div className={styles.unloged}>
              <div
                className={clsx(styles.link, {
                  [styles.active]: location.pathname.includes("sign-in"),
                })}
              >
                <Link to="/auth/login">
                  <span>Login</span>
                </Link>
              </div>

              <div
                className={clsx(styles.link, {
                  [styles.active]: location.pathname.includes("sign-up"),
                })}
              >
                <Link to="/auth/sign-up">
                  <span>Signup</span>
                </Link>
              </div>

              <div className={styles.wrap_navbar}>
                <NavBar navContent={navContent} />
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};
export default Header;
