import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  typography: {
    fontFamily: "Inter, Helvetica, Arial, sans-serif",
  },
  palette: {
    primary: {
      light: "#50afe0",
      main: "#259cd9",
      dark: "#196d97",
      contrastText: "#fff",
    },
    secondary: {
      light: "#4d667d",
      main: "#122140",
      dark: "#172c41",
      contrastText: "#fff",
    },
    error: {
      light: "#eb9085",
      main: "#e67567",
      dark: "#a15148",
      contrastText: "#fff",
    },
    warning: {
      light: "#fdcd4e",
      main: "#fdc122",
      dark: "#b18717",
      contrastText: "#fff",
    },
    success: {
      light: "#fdc122",
      main: "#fdc122",
      dark: "#b1a176",
      contrastText: "#fff",
    },
    info: {
      light: "#fffaec",
      main: "#fff9e8",
      dark: "#b2aea2",
      contrastText: "#000",
    },
  },
  // overrides: {
  //   MuiButton: {
  //     contained: {
  //       color: "#fff !important",
  //     },
  //     outlined: {
  //       backgroundColor: "#fff",
  //     },
  //   },
  // },
});
