import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import MainLoader from "../../view/common/MainLoader/MainLoader";
import { StateType } from "../../redux/store";
import { Cookies } from "react-cookie-consent";

type GuestRouteProps = {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
};

const GetStartedRoute: React.FC<GuestRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { profile } = useSelector((state: StateType) => state.user);
  const redirectLinkAfterRegister = localStorage.getItem("redirectTo");
  let redirectAfterLogin = Cookies.get("redirectAfterLogin");
  Cookies.remove("redirectAfterLogin");

  return (
    <Route
      {...rest}
      render={(props) => {
        if (profile) {
          return (
            <Redirect
              to={`${
                redirectLinkAfterRegister ||
                redirectAfterLogin ||
                "/user/my-search-profiles"
              }`}
            />
          );
        }
        return (
          <Suspense fallback={<MainLoader loading={true} />}>
            <Component {...props} />
          </Suspense>
        );
      }}
    />
  );
};

export default GetStartedRoute;
