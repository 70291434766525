import { createSlice } from "@reduxjs/toolkit";
import { InitianlPlansType } from "../../types/plans";
import { getPlans } from "../actions/plans";

const initialState: InitianlPlansType = {
  plans: null,
  plan_id: 0,
  isLoading: false,
  errors: null,
};

const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    setStatePlanId(state, { payload }) {
      return {
        ...state,
        plan_id: payload,
      };
    },
  },

  extraReducers: {
    [getPlans.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getPlans.fulfilled.toString()]: (state, { payload }) => {
      state.plans = payload;
      state.isLoading = false;
      state.errors = null;
    },
    [getPlans.rejected.toString()]: (state, { payload }) => {
      state.errors = payload.errors;
      state.isLoading = false;
    },
  },
});

export const { setStatePlanId } = plansSlice.actions;

export default plansSlice.reducer;
