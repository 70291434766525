import { instance, SERVER_HOST } from "./index";
import { SignUpType, SignInType, ForgotType, ResetType } from "../types/auth";

export default {
  signUp: async (value: SignUpType | {}) => {
    const { data } = await instance.post(`${SERVER_HOST}/users`, value);
    return data;
  },

  signIn: async (value: SignInType | {}) => {
    const { data } = await instance.post(`${SERVER_HOST}/users/login`, value);
    return data;
  },

  forgotPass: async (value: ForgotType | {}) => {
    const { data } = await instance.post(`${SERVER_HOST}/users/forgot`, value);
    return data;
  },

  resetPass: async (value: ResetType | {}) => {
    const { data } = await instance.post(`${SERVER_HOST}/users/reset`, value);
    return data;
  },
};
